import { createGroup, deleteGroup, editGroup, getAllGroups } from "../../apis/actions/groupsAction";
import { QUERY_KEY_GROUPS } from "../../const/queryKey";
import { useMutation, useQuery } from "react-query";
import { useSelector } from "react-redux";
import { ReduxStoreState } from "redux/store";
import { queryOptions } from "./queryOptions";
import { useQueryCache } from "./useQueryCache";
import { IGroup } from "../../interfaces/IGroup/IGroup";
import { IQueryCacheData } from "../../interfaces/ICache/IQueryCache";
import { handleExpiredToken, useTrack } from "front";
import { IQueryName } from "../../interfaces/IQueryName/IQueryName";

export const useGroupsCache = () => {
  const appId = useSelector((s: ReduxStoreState) => s.appConfiguration.appId);
  const { mutate } = useQueryCache();
  const { trackItAsync } = useTrack();

  const queryGroups = useQuery<IGroup[]>([QUERY_KEY_GROUPS, appId], trackItAsync(IQueryName.QUERY_GROUPS, getAllGroups), {
    ...queryOptions,
    enabled: !!appId,
    staleTime: Infinity,
    ...handleExpiredToken,
  });

  const mutateCreateGroup = useMutation(trackItAsync(IQueryName.MUTATION_CREATE_GROUP, createGroup), {
    ...handleExpiredToken,
    onSuccess: (res: IGroup, _: any) => {
      const dataCache: IQueryCacheData = {
        data: [...(queryGroups.data ?? []), res],
        queryKey: [QUERY_KEY_GROUPS, appId],
      };
      mutate(dataCache);
    },
  });

  const mutateEditGroup = useMutation(trackItAsync(IQueryName.MUTATION_EDIT_GROUP, editGroup), {
    ...handleExpiredToken,
    onSuccess: (_: undefined, variable: IGroup) => {
      const copyQuery = queryGroups.data ? [...queryGroups.data] : [];
      const modifiedGroupIndex = copyQuery.findIndex((item) => item.id === variable.id);
      if (modifiedGroupIndex === -1) return;
      copyQuery.splice(modifiedGroupIndex, 1, variable);
      const dataCache: IQueryCacheData = {
        data: copyQuery,
        queryKey: [QUERY_KEY_GROUPS, appId],
      };
      mutate(dataCache);
    },
  });

  const mutateDeleteGroup = useMutation(trackItAsync(IQueryName.MUTATION_DELETE_GROUP, deleteGroup), {
    ...handleExpiredToken,
    onSuccess: (_: undefined, variable: { tenantId: string; contactListId: string; id: string }) => {
      const filteredData = queryGroups.data?.filter((item) => item.id !== variable.id);
      const dataCache: IQueryCacheData = {
        data: filteredData,
        queryKey: [QUERY_KEY_GROUPS, appId],
      };
      mutate(dataCache);
    },
  });

  return {
    mutateCreateGroup,
    mutateEditGroup,
    mutateDeleteGroup,
    queryGroups: { ...queryGroups, data: queryGroups.data ?? [] },
  };
};
