export const translations = {
  locale: "en",
  get: (id: string, parameters?: Record<string, string | number>): string => {
    if (!id) return "";
    const object = (translations as any)[translations.locale] ?? (translations as any).en;
    let translation = object[id] ?? (translations as any).en[id] ?? "";
    if (!translation) {
      console.error("Missing translation for '" + id + "'");
      translation = id;
    }
    if (parameters) {
      for (const param in parameters) translation = translation.replaceAll("{" + param + "}", parameters[param] + "");
    }
    return translation;
  },
  en: {
    DisplayMode: "Display Mode",
    Card: "Card",
    List: "List",
    Group: "Group",
    Contact: "Contact",
    ExportContact: "Export Contacts",
    ImportContact: "Import Contacts",
    About: "About",
    SuggestFeature: "Suggest a Feature",
    HelpCenter: "Help Center",
    SearchContact: "Please search using Name, Job, Company, Department, Notes, Groups, or Address to find what you need.",
    New: "New",
    NewGroup: "New Group",
    Name: "Name",
    Close: "Close",
    Create: "Create",
    jobTitle: "Job Title",
    company: "Company",
    department: "Department",
    Email: "Email",
    website: "Website",
    linkedin: "Linkedin",
    facebook: "Facebook",
    twitter: "X",
    businessPhone: "Business Phone",
    Cellphone: "Cell Phone",
    Phone: "Phone",
    businessFax: "Business Fax",
    Addresses: "Addresses",
    Notes: "Notes",
    General: "General",
    Internet: "Internet",
    Phones: "Phones",
    ChooseGroup: "Choose a Group",
    AddContact: "Add Contact",
    Edit: "Edit",
    Delete: "Delete",
    name: "Full Name",
    DeleteContactMessage: "Are you sure you want to delete this contact?",
    DeleteContactMessageBold: "It will be permanently deleted",
    DeleteContact: "Delete Contact",
    ClickHere: "Click Here",
    EditGroup: "Edit Group",
    NoContact: "You do not have any contacts yet. You can add them using the button below.",
    DefaultError: "An error occurred. Please reload the application. Thank you.",
    Add: "Add",
    DeleteGroup: "Delete Group",
    GroupError: "An error occurred while editing or adding your group. Please try again.",
    DeleteUserError: "Unable to delete this contact. Please try again.",
    EditUserError: "Unable to update this contact. Please try again.",
    CreateUserError: "Unable to create a contact. Please try again.",
    CreateGroupError: "Unable to create a group. Please make sure you have entered a name with at least 1 character.",
    EditGroupError: "Unable to update this group. Please make sure you have entered a name with at least 1 character.",
    DeleteGroupError: "Unable to delete this group. Please try again later.",
    NoName: "No name has been entered.",
    TheInput: "The field",
    wrongURL: "does not match a valid URL (https://example.com).",
    DownloadModelRule1: "1. Download the Excel file template and fill it with your contacts.",
    DownloadModel: "Download Template",
    DownloadModelRule2: "2. Then, click 'Import Contacts' and select the template containing your contacts.",
    StartImport: "Start Import",
    StartExport: "Start Export",
    CannotExportData: "Unable to export your data. Please try again.",
    CannotImportContact: "Unable to import your contacts. Please try again.",
    OnlyExcelFormat: "Only Excel files are allowed.",
    NoChatWithOtherTenant: "The chat option does not work with contacts outside your organization. To explore this feature, please select a contact within your organization.",
    Information: "Information",
    startChat: "Start a Chat",
    SendMail: "Send an Email",
    PlanAMeeting: "Plan a Meeting",
    Call: "Phone call",
    FileExceedSize: "The profile image you have selected is too large. {file}MB > 5MB",
    OnlyImageFormat: "Only .jpeg/.png images are allowed",
    LoadAnImage: "Load an image",
    Save: "Save",
    None: "None",
    EditContact: "Edit Contact",
    NewContact: "New Contact",
    nameError: "This field is required",
    emailError: "Please enter a valid email address, e.g., example@domain.com",
    websiteError: "Please enter a valid website URL, e.g., https://www.domain.com or domain.com",
    linkedinError: "Please enter a valid LinkedIn profile URL, e.g., https://www.linkedin.com/in/yourprofile or yourprofile",
    facebookError: "Please enter a valid Facebook profile URL, e.g., https://www.facebook.com/yourprofile or yourprofile",
    xError: "Please enter a valid URL, e.g., https://www.domain.com or domain.com",
    businessPhoneError: "The phone number must consist of digits with (/, +, .)",
    cellphoneError: "The phone number must consist of digits with (/, +, .)",
    phoneError: "The phone number must consist of digits with (/, +, .)",
    businessFaxError: "The fax number must consist of digits with (/, +, .)",
    Address: "Address",
    NotLandscape: "Your image must be in landscape format",
    ImportMessage: "The import of your contacts is in progress. An email will be sent to you to confirm the completion of the import. Thank you",
    RequestInProgress: "Your request is in progress...",
    MessageSendMailImport: "Once the export is completed, you will receive an email at {UpnContext} ",
    RemoveTitle: "Read before deleting the Contact Pro: ",
    RemoveItem1: "1. When deleting the Contact Pro, you also delete all the data stored. Make sure you retrieve all necessary information before deleting it.",
    RemoveItem2: "2. You cannot recover a Contact Pro after deleting it. Once you delete it, we will not be able to restore it.",
    GroupAlreadyExist: "The group name already exists. Please select a different one",
    Copied: "Copied!",
    InfoEventCreatedBy: "Created by",
    ConfigurationHelp: "We've shifted all the settings to the main Contacts Pro window for easier access.",
    GroupsAdmin: "Group Administration",
    permissions: "Permissions",
    connector: "Connector",
    See: "View",
    Groups: "Groups",
    email: "Email Address",
    mobile: "Mobile",
    Cancel: "Cancel",
    "internet.emailError": "Please enter a valid email, e.g., example@domain.com",
    "internet.websiteError": "Please enter a valid website URL, e.g., https://www.domain.com or domain.com",
    "internet.linkedinError": "Please enter a valid website URL, e.g., https://www.domain.com or domain.com",
    "internet.facebookError": "Please enter a valid website URL, e.g., https://www.domain.com or domain.com",
    "internet.twitterError": "Please enter a valid website URL, e.g., https://www.domain.com or domain.com",
    Confirm: "Confirm",
    AddToGroup: "Add {name} to a group",
    NoResult: "No result found",
    Stay: "Stay",
    GroupAdministration: "Group Administration",
    CreateGroup: "Create a group",
    searchGroup: "Search for a group",
    NoEntries: "No entries",
    AddGroupMember: "Add a member to the group",
    GroupMember: "Group member(s)",
    GroupName: "Group name",
    RemoveFromGroup: "Remove from group",
    RemoveContactFromGroupMessage: "Are you sure you want to remove this contact from the group",
    RemoveContactFromGroup: "Remove contact from group",
    StartTypingAName: "Start typing the contact's name",
    Settings: "Display Settings",
    Copy: "Duplicate",
    CopyGroup: "Copy a group",
    CopyGroupMessage: "To copy the group, please provide a unique name",
    NoContactInGroup: "There are no contacts in this group",
    NoContactFound: "No contacts found",
    searchContact: "Search for a contact",
    DeleteGroupMessage: "Are you sure you want to delete",
    chatTooltip: "To start a Teams chat, add a valid email OR This option is only available for emails within your organization.",
    callTooltip: "Add a valid phone number to activate the 'Start an audio call' option.",
    mailTooltip: "Please add a valid email address to activate the 'Send an email' option.",
    calendarTooltip: "Please add a valid email address to activate the 'Create a Teams meeting' option.",
    webTooltip: "Please add a valid URL to activate the 'Open website in browser' option.",
    linkedinTooltip: "Please add a valid LinkedIn URL to activate the 'Open LinkedIn in browser' option.",
    facebookTooltip: "Please add a valid Facebook URL to activate the 'Open Facebook in browser' option.",
    xTooltip: "Please add a valid X URL to activate the 'Open X in browser' option.",
    ContinueEditing: "Continue editing",
    Reset: "Discard",
    ConfirmCancelForm: "Do you want to discard edits or continue editing?",
    CreateGroupBefore: "You have no groups. Create a new group to add your contacts.",
    MaxLengthError: "This field cannot exceed 50 characters.",
    PremiumMessage1: "You are currently using the free version of Contacts Pro.",
    PremiumMessage2: "to upgrade to the Premium version,",
    SureToExportContact: "Are you sure you want to export all of your contacts?",
    MessageSendMailExport: "Once the export is completed, you will receive an email at {UpnContext}.",
    AddContactToGroup: "Add the contact to a group",
    "phones.mobileError": "The phone number must consist of digits with (), /, + and .",
    "phones.businessPhoneError": "The phone number must consist of digits with (), /, + and .",
    "phones.businessFaxError": "The phone number must consist of digits with (), /, + and .",
    NoGroup: "You do not have any groups yet. You can add them using the button below.",
    DeletePermanently: "Delete permanently",
    NoMatch: "No match found. Please try another",
    Company: "Company",
    Success: "Your changes have been successfully saved.",
    NoEditPerm: "You do not have the rights to edit contacts or groups.",
    NoDeletePerm: "You do not have the rights to delete contacts or groups.",
    NoAddPerm: "You do not have the rights to add contacts or groups",
    PremiumMessageCard: "You must be {license} to access this user",
    PremiumButtonCard: "Upgrade {license}",
    ContactLimitReached: "Contact Limit Reached",
    ContactLimitReachedTitle1: "You've reached your contact limit of 1000 contacts.",
    ContactLimitReachedText: "To add more contacts and access advanced features, consider upgrading to our Platinum tier. ",
    ContactLimitReachedTitle2: "Benefits of Upgrading to Platinum:",
    UnlimitedContacts: "Unlimited contacts",
    AdvancedPermissions: "Advanced permissions",
    PowerAutomateConnector: "Power Automate Connector",
    APIAccess: "API access for developers",
    ViewPlans: "View Plans",
    UpgradeToPaidPlan: "Upgrade",
  },
  fr: {
    DisplayMode: "Mode d'affichage",
    Card: "Carte",
    List: "Liste",
    Group: "Groupe",
    Contact: "Contact",
    ExportContact: "Exporter les contacts",
    ImportContact: "Importer des contacts",
    About: "À propos",
    SuggestFeature: "Suggérer une fonctionnalité",
    HelpCenter: "Centre d'aide",
    SearchContact: "Veuillez chercher par Nom, Poste, Entreprise, Département, Notes, Groupes, ou Adresse pour trouver ce dont vous avez besoin.",
    New: "Nouveau",
    NewGroup: "Nouveau groupe",
    Name: "Nom",
    Close: "Fermer",
    Create: "Créer",
    jobTitle: "Titre du poste",
    company: "Société",
    department: "Service",
    Email: "Email",
    website: "Site internet",
    linkedin: "Linkedin",
    facebook: "Facebook",
    twitter: "X",
    businessPhone: "Téléphone professionnel",
    Cellphone: "Téléphone portable",
    Phone: "Téléphone fixe",
    businessFax: "Fax professionnel",
    Address: "Adresse",
    Addresses: "Adresses",
    Notes: "Notes",
    General: "Général",
    Internet: "Internet",
    Phones: "Téléphones",
    ChooseGroup: "Choisir un groupe",
    AddContact: "Ajouter un contact",
    Edit: "Modifier",
    Delete: "Supprimer",
    name: "Nom et prénom",
    DeleteContactMessage: "Êtes-vous sûr de vouloir supprimer ce contact ?",
    DeleteContactMessageBold: "Il sera définitivement supprimé",
    DeleteContact: "Supprimer un contact",
    ClickHere: "Cliquez ici",
    EditGroup: "Modifier le groupe",
    NoContact: "Vous n'avez pas encore de contact. Vous pouvez en ajouter grâce au bouton ci-dessous.",
    DefaultError: "Une erreur est survenue. Veuillez recharger l'application. Merci.",
    Add: "Ajouter",
    DeleteGroup: "Supprimer le groupe",
    GroupError: "Une erreur est survenue lors de la modification ou de l'ajout de votre groupe. Veuillez réessayer.",
    DeleteUserError: "Impossible de supprimer ce contact. Veuillez réessayer.",
    EditUserError: "Impossible de mettre à jour ce contact. Veuillez réessayer.",
    CreateUserError: "Impossible de créer un contact. Veuillez réessayer.",
    CreateGroupError: "Impossible de créer un groupe. Assurez-vous d'avoir renseigné un nom comportant au moins 1 caractère.",
    EditGroupError: "Impossible de mettre à jour ce groupe. Assurez-vous d'avoir renseigné un nom comportant au moins 1 caractère.",
    DeleteGroupError: "Impossible de supprimer ce groupe. Veuillez réessayer plus tard.",
    NoName: "Aucun nom n'a été renseigné.",
    TheInput: "Le champ",
    wrongURL: "ne correspond pas à une URL valide (https://exemple.com).",
    DownloadModelRule1: "1. Téléchargez le modèle de fichier Excel et remplissez-le avec vos contacts.",
    DownloadModel: "Télécharger le modèle",
    DownloadModelRule2: "2. Ensuite, cliquez sur 'Importer des contacts' et sélectionnez le modèle contenant vos contacts.",
    StartImport: "Démarrer l'importation",
    StartExport: "Démarrer l'exportation",
    CannotExportData: "Impossible d'exporter vos données. Veuillez réessayer.",
    CannotImportContact: "Impossible d'importer vos contacts. Veuillez réessayer.",
    OnlyExcelFormat: "Seuls les fichiers Excel sont autorisés.",
    NoChatWithOtherTenant:
      "L'option de chat ne fonctionne pas avec les contacts extérieurs à votre organisation. Pour découvrir cette fonctionnalité, veuillez sélectionner un contact au sein de votre organisation.",
    Information: "Information",
    startChat: "Démarrer une discussion",
    SendMail: "Envoyer un email",
    PlanAMeeting: "Planifier une réunion",
    Call: "Appel téléphonique",
    FileExceedSize: "L'image de Profile que vous avez séléctionné est trop grande. {file}Mo > 5Mo",
    OnlyImageFormat: "Seuls les images au format .jpeg/.png sont autorisés",
    LoadAnImage: "Charger une image",
    Save: "Sauvegarder",
    None: "Aucun",
    EditContact: "Modifier le contact",
    NewContact: "Nouveau contact",
    nameError: "Ce champ est obligatoire",
    emailError: "Veuillez saisir un e-mail valide, ex: exemple@domaine.com",
    websiteError: "Veuillez saisir une url de site web valide, ex: https://www.domaine.com or domaine.com",
    linkedinError: "Veuillez saisir une url de site web valide, ex: https://www.domaine.com or domaine.com",
    facebookError: "Veuillez saisir une url de site web valide, ex: https://www.domaine.com or domaine.com",
    xError: "Veuillez saisir une url de site web valide, ex: https://www.domaine.com or domaine.com",
    businessPhoneError: "Le téléphone doit être composé de chiffres avec (/,+,.)",
    cellphoneError: "Le téléphone doit être composé de chiffres avec (/,+,.)",
    phoneError: "Le téléphone doit être composé de chiffres avec (/,+,.)",
    businessFaxError: "Le téléphone doit être composé de chiffres avec (/,+,.)",
    NotLandscape: "Votre image doit être au format paysage.",
    ImportMessage: "L'importation de vos contacts est en cours. Un mail vous sera envoyé pour confirmer la finalisation de l'importation. Merci",
    RequestInProgress: "Votre demande est en cours...",
    MessageSendMailImport: "Une fois l'importation terminée, vous recevrez un e-mail à {UpnContext}.",
    RemoveTitle: "A lire avant de supprimer Contact Pro: ",
    RemoveItem1: "1. Lorsque vous supprimez Contact Pro, vous supprimez également toutes les données stockées. Assurez-vous de récupérer toutes les informations nécessaires avant de le supprimer.",
    RemoveItem2: "2. Vous ne pouvez pas récupérer Contact Pro après l'avoir supprimée. Une fois que vous l'avez supprimée, nous ne pourrons pas la restaurer.",
    GroupAlreadyExist: "Le nom du groupe existe déjà. Veuillez en choisir un autre",
    Copied: "Copié !",
    InfoEventCreatedBy: "Créé par",
    ConfigurationHelp: "Nous avons déplacé tous les paramètres vers la fenêtre principale de Contacts Pro pour un accès plus facile.",
    GroupsAdmin: "Administration de groupe",
    permissions: "Permissions",
    connector: "Connecteur",
    See: "Voir",
    Groups: "Groupes",
    email: "Adresse mail",
    mobile: "Mobile",
    Cancel: "Annuler",
    "internet.emailError": "Veuillez saisir un e-mail valide, ex: exemple@domaine.com",
    "internet.websiteError": "Veuillez saisir une url de site web valide, ex: https://www.domaine.com or domaine.com",
    "internet.linkedinError": "Veuillez saisir une url de site web valide, ex: https://www.domaine.com or domaine.com",
    "internet.facebookError": "Veuillez saisir une url de site web valide, ex: https://www.domaine.com or domaine.com",
    "internet.twitterError": "Veuillez saisir une url de site web valide, ex: https://www.domaine.com or domaine.com",
    Confirm: "Confirmer",
    AddToGroup: "Ajouter {name} à un groupe",
    NoResult: "Aucun résultat trouvé",
    Stay: "Rester",
    GroupAdministration: "Administration de groupe",
    CreateGroup: "Créer un groupe",
    searchGroup: "Chercher un groupe",
    NoEntries: "Pas d'entrées",
    AddGroupMember: "Ajouter un membre au groupe",
    GroupMember: "Membre(s) du groupe",
    GroupName: "Nom du groupe",
    RemoveFromGroup: "Supprimer du groupe",
    RemoveContactFromGroupMessage: "Êtes-vous sûr de vouloir supprimer ce contact du groupe",
    RemoveContactFromGroup: "Supprimer le contact du groupe",
    StartTypingAName: "Commencez à taper le nom d'un contact",
    Settings: "Paramètres d'affichage",
    Copy: "Dupliquer",
    CopyGroup: "Copier un groupe",
    CopyGroupMessage: "Pour copier le groupe, veuillez fournir un nom unique",
    NoContactInGroup: "Il n'y a aucun contact dans ce groupe",
    NoContactFound: "Aucun contact trouvé",
    searchContact: "Rechercher un contact",
    DeleteGroupMessage: "Êtes-vous sûr de vouloir supprimer",
    chatTooltip: "Pour démarrer un chat Teams, ajoutez une adresse e-mail valide OU Cette option n'est disponible que pour les e-mails de votre organisation.",
    callTooltip: "Ajoutez un numéro de téléphone valide pour activer l'option 'Démarrer un appel audio'.",
    mailTooltip: "Veuillez ajouter une adresse e-mail valide pour activer l'option 'Envoyer un e-mail'.",
    calendarTooltip: "Veuillez ajouter une adresse e-mail valide pour activer l'option 'Créer une réunion Teams'.",
    webTooltip: "Veuillez ajouter une URL valide pour activer l'option 'Ouvrir le site web dans le navigateur'.",
    linkedinTooltip: "Veuillez ajouter une URL LinkedIn valide pour activer l'option 'Ouvrir LinkedIn dans le navigateur'.",
    facebookTooltip: "Veuillez ajouter une URL Facebook valide pour activer l'option 'Ouvrir Facebook dans le navigateur'.",
    xTooltip: "Veuillez ajouter une URL X valide pour activer l'option 'Ouvrir X dans le navigateur'.",
    ContinueEditing: "Continuer l'édition",
    Reset: "Annuler",
    ConfirmCancelForm: "Voulez-vous annuler les modifications ou continuer l'édition ?",
    CreateGroupBefore: "Vous n'avez aucun groupe. Créez un nouveau groupe pour ajouter vos contacts.",
    MaxLengthError: "Ce champ ne peut pas dépasser 50 caractères.",
    PremiumMessage1: "Vous utilisez actuellement la version gratuite de Contacts Pro.",
    PremiumMessage2: "pour passer à la version Premium,",
    SureToExportContact: "Êtes-vous sûr de vouloir exporter tous vos contacts ?",
    MessageSendMailExport: "Une fois l'exportation terminée, vous recevrez un email à {UpnContext}.",
    AddContactToGroup: "Ajouter le contact à un groupe",
    "phones.mobileError": "Le numéro de téléphone doit être composé de chiffres avec (), /, + et .",
    "phones.businessPhoneError": "Le numéro de téléphone doit être composé de chiffres avec (), /, + et .",
    "phones.businessFaxError": "Le numéro de téléphone doit être composé de chiffres avec (), /, + et .",
    NoGroup: "Vous n'avez encore aucun groupe. Vous pouvez en ajouter en utilisant le bouton ci-dessous.",
    DeletePermanently: "Supprimer définitivement",
    NoMatch: "Aucun résultat trouvé",
    Company: "Société",
    Success: "Vos modifications ont été enregistrées avec succès.",
    NoEditPerm: "Vous n'avez pas les droits pour modifier les contacts ou les groupes.",
    NoDeletePerm: "Vous n'avez pas les droits pour supprimer les contacts ou les groupes.",
    NoAddPerm: "Vous n'avez pas les droits pour ajouter des contacts ou des groupes.",
    PremiumMessageCard: "Vous devez être {license} pour accéder a cette utilisateur",
    PremiumButtonCard: "Passer {license}",
    ContactLimitReached: "Limite de contacts atteinte",
    ContactLimitReachedTitle1: "Vous avez atteint votre limite de 1000 contacts.",
    ContactLimitReachedText: "Pour ajouter plus de contacts et accéder à des fonctionnalités avancées, envisagez de passer à notre niveau Platinum.",
    ContactLimitReachedTitle2: "Avantages du passage à Platinum :",
    UnlimitedContacts: "Contacts illimités",
    AdvancedPermissions: "Permissions avancées",
    PowerAutomateConnector: "Connecteur Power Automate",
    APIAccess: "Accès API pour les développeurs",
    ViewPlans: "Voir les plans",
    UpgradeToPaidPlan: "Mettre à niveau",
  },
  de: {
    DisplayMode: "Anzeigemodus",
    Card: "Karte",
    List: "Liste",
    Group: "Gruppe",
    Contact: "Kontakt",
    ExportContact: "Kontakte exportieren",
    ImportContact: "Kontakte importieren",
    About: "Über",
    SuggestFeature: "Funktion vorschlagen",
    HelpCenter: "Hilfezentrum",
    SearchContact: "Bitte suchen Sie anhand von Name, Beruf, Unternehmen, Abteilung, Notizen, Gruppen oder Adresse, um zu finden, was Sie benötigen.",
    New: "Neu",
    NewGroup: "Neue Gruppe",
    Name: "Name",
    Close: "Schließen",
    Create: "Erstellen",
    jobTitle: "Berufsbezeichnung",
    company: "Unternehmen",
    department: "Abteilung",
    Email: "E-Mail",
    website: "Website",
    linkedin: "Linkedin",
    facebook: "Facebook",
    twitter: "X",
    businessPhone: "Geschäftstelefon",
    Cellphone: "Handy",
    Phone: "Festnetz",
    businessFax: "Geschäftsfax",
    Addresses: "Adressen",
    Notes: "Notizen",
    General: "Allgemein",
    Internet: "Internet",
    Phones: "Telefonnummern",
    ChooseGroup: "Gruppe auswählen",
    AddContact: "Kontakt hinzufügen",
    Edit: "Bearbeiten",
    Delete: "Löschen",
    name: "Vollständiger Name",
    DeleteContactMessage: "Sind Sie sicher, dass Sie diesen Kontakt löschen möchten?",
    DeleteContactMessageBold: "Er wird dauerhaft gelöscht",
    DeleteContact: "Kontakt löschen",
    ClickHere: "Klicken Sie hier",
    EditGroup: "Gruppe bearbeiten",
    NoContact: "Sie haben noch keine Kontakte. Sie können sie über die unten stehende Schaltfläche hinzufügen.",
    DefaultError: "Ein Fehler ist aufgetreten. Bitte laden Sie die Anwendung neu. Danke.",
    Add: "Hinzufügen",
    DeleteGroup: "Gruppe löschen",
    GroupError: "Beim Bearbeiten oder Hinzufügen Ihrer Gruppe ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
    DeleteUserError: "Diesen Kontakt können Sie nicht löschen. Bitte versuchen Sie es erneut.",
    EditUserError: "Diesen Kontakt können Sie nicht aktualisieren. Bitte versuchen Sie es erneut.",
    CreateUserError: "Einen Kontakt zu erstellen, ist nicht möglich. Bitte versuchen Sie es erneut.",
    CreateGroupError: "Eine Gruppe zu erstellen, ist nicht möglich. Bitte stellen Sie sicher, dass Sie einen Namen mit mindestens 1 Zeichen eingegeben haben.",
    EditGroupError: "Diese Gruppe zu aktualisieren, ist nicht möglich. Bitte stellen Sie sicher, dass Sie einen Namen mit mindestens 1 Zeichen eingegeben haben.",
    DeleteGroupError: "Diese Gruppe können Sie nicht löschen. Bitte versuchen Sie es später erneut.",
    NoName: "Es wurde kein Name eingegeben.",
    TheInput: "Das Feld",
    wrongURL: "entspricht keiner gültigen URL (https://example.com).",
    DownloadModelRule1: "1. Laden Sie die Excel-Dateivorlage herunter und füllen Sie sie mit Ihren Kontakten aus.",
    DownloadModel: "Vorlage herunterladen",
    DownloadModelRule2: "2. Klicken Sie dann auf 'Kontakte importieren' und wählen Sie die Vorlage aus, die Ihre Kontakte enthält.",
    StartImport: "Import starten",
    StartExport: "Export starten",
    CannotExportData: "Ihre Daten können nicht exportiert werden. Bitte versuchen Sie es erneut.",
    CannotImportContact: "Ihre Kontakte können nicht importiert werden. Bitte versuchen Sie es erneut.",
    OnlyExcelFormat: "Nur Excel-Dateien sind erlaubt.",
    NoChatWithOtherTenant:
      "Die Chat-Option funktioniert nicht mit Kontakten außerhalb Ihrer Organisation. Um diese Funktion zu nutzen, wählen Sie bitte einen Kontakt innerhalb Ihrer Organisation aus.",
    Information: "Information",
    startChat: "Chat starten",
    SendMail: "E-Mail senden",
    PlanAMeeting: "Meeting planen",
    Call: "Telefonanruf",
    FileExceedSize: "Das ausgewählte Profilbild ist zu groß. {file}MB > 5MB",
    OnlyImageFormat: "Nur .jpeg/.png-Bilder sind erlaubt",
    LoadAnImage: "Ein Bild laden",
    Save: "Speichern",
    None: "Kein",
    EditContact: "Kontakt bearbeiten",
    NewContact: "Neuer Kontakt",
    nameError: "Dieses Feld ist erforderlich",
    emailError: "Bitte geben Sie eine gültige E-Mail-Adresse ein, z.B.: beispiel@domain.com",
    websiteError: "Bitte geben Sie eine gültige Website-URL ein, z.B.: https://www.domain.com oder domain.com",
    linkedinError: "Bitte geben Sie eine gültige LinkedIn-Profil-URL ein, z.B.: https://www.linkedin.com/in/ihrprofil oder ihrprofil",
    facebookError: "Bitte geben Sie eine gültige Facebook-Profil-URL ein, z.B.: https://www.facebook.com/ihrprofil oder ihrprofil",
    xError: "Bitte geben Sie eine gültige URL ein, z.B.: https://www.domain.com oder domain.com",
    businessPhoneError: "Die Telefonnummer darf nur Ziffern mit (/, +, .) enthalten",
    cellphoneError: "Die Telefonnummer darf nur Ziffern mit (/, +, .) enthalten",
    phoneError: "Die Telefonnummer darf nur Ziffern mit (/, +, .) enthalten",
    businessFaxError: "Die Faxnummer darf nur Ziffern mit (/, +, .) enthalten",
    Address: "Adresse",
    NotLandscape: "Ihr Bild muss im Querformat sein",
    ImportMessage: "Der Import Ihrer Kontakte läuft. Eine E-Mail wird Ihnen gesendet, um den Abschluss des Imports zu bestätigen. Danke",
    RequestInProgress: "Ihre Anfrage ist in Bearbeitung...",
    MessageSendMailImport: "Wenn der Import abgeschlossen ist, erhalten Sie eine E-Mail an {UpnContext}.",
    RemoveTitle: "Lesen Sie vor dem Löschen der Kontakte Pro: ",
    RemoveItem1: "1. Wenn Sie Karte Pro löschen, löschen Sie auch alle gespeicherten Daten. Stellen Sie sicher, dass Sie alle notwendigen Informationen abrufen, bevor Sie sie löschen.",
    RemoveItem2: "2. Sie können eine gelöschte Kontakte Pro nicht wiederherstellen. Sobald Sie sie gelöscht haben, können wir sie nicht wiederherstellen.",
    GroupAlreadyExist: "Der Gruppenname existiert bereits. Bitte wählen Sie einen anderen",
    Copied: "Kopiert !",
    InfoEventCreatedBy: "Erstellt von",
    ConfigurationHelp: "Wir haben alle Einstellungen in das Hauptfenster von Contacts Pro verschoben, um den Zugriff zu erleichtern.",
    GroupsAdmin: "Gruppenverwaltung",
    permissions: "Berechtigungen",
    connector: "Verbinder",
    See: "Sehen",
    Groups: "Gruppen",
    email: "E-Mail-Adresse",
    mobile: "Handy",
    Cancel: "Abbrechen",
    "internet.emailError": "Bitte geben Sie eine gültige E-Mail-Adresse ein, z.B. beispiel@domain.com",
    "internet.websiteError": "Bitte geben Sie eine gültige Website-URL ein, z.B. https://www.domain.com oder domain.com",
    "internet.linkedinError": "Bitte geben Sie eine gültige Website-URL ein, z.B. https://www.domain.com oder domain.com",
    "internet.facebookError": "Bitte geben Sie eine gültige Website-URL ein, z.B. https://www.domain.com oder domain.com",
    "internet.twitterError": "Bitte geben Sie eine gültige Website-URL ein, z.B. https://www.domain.com oder domain.com",
    Confirm: "Bestätigen",
    AddToGroup: "Füge {name} zu einer Gruppe hinzu",
    NoResult: "Kein Ergebnis gefunden",
    Stay: "Bleiben",
    GroupAdministration: "Gruppenverwaltung",
    CreateGroup: "Gruppe erstellen",
    searchGroup: "Gruppe suchen",
    NoEntries: "Keine Einträge",
    AddGroupMember: "Mitglied zur Gruppe hinzufügen",
    GroupMember: "Gruppenmitglied(er)",
    GroupName: "Gruppenname",
    RemoveFromGroup: "Aus Gruppe entfernen",
    RemoveContactFromGroupMessage: "Sind Sie sicher, dass Sie diesen Kontakt aus der Gruppe entfernen möchten",
    RemoveContactFromGroup: "Kontakt aus der Gruppe entfernen",
    StartTypingAName: "Geben Sie den Namen des Kontakts ein",
    Settings: "Anzeigeeinstellungen",
    Copy: "Duplizieren",
    CopyGroup: "Gruppe kopieren",
    CopyGroupMessage: "Um die Gruppe zu kopieren, geben Sie bitte einen eindeutigen Namen an",
    NoContactInGroup: "In dieser Gruppe befinden sich keine Kontakte",
    NoContactFound: "Keine Kontakte gefunden",
    searchContact: "Suche nach einem kontakt",
    DeleteGroupMessage: "Möchten Sie wirklich löschen",
    chatTooltip: "Um einen Teams-Chat zu starten, fügen Sie eine gültige E-Mail-Adresse hinzu ODER Diese Option ist nur für E-Mails Ihrer Organisation verfügbar.",
    callTooltip: "Fügen Sie eine gültige Telefonnummer hinzu, um die Option 'Anruf starten' zu aktivieren.",
    mailTooltip: "Bitte fügen Sie eine gültige E-Mail-Adresse hinzu, um die Option 'E-Mail senden' zu aktivieren.",
    calendarTooltip: "Bitte fügen Sie eine gültige E-Mail-Adresse hinzu, um die Option 'Eine Teams-Besprechung erstellen' zu aktivieren.",
    webTooltip: "Bitte fügen Sie eine gültige URL hinzu, um die Option 'Website im Browser öffnen' zu aktivieren.",
    linkedinTooltip: "Bitte fügen Sie eine gültige LinkedIn-URL hinzu, um die Option 'LinkedIn im Browser öffnen' zu aktivieren.",
    facebookTooltip: "Bitte fügen Sie eine gültige Facebook-URL hinzu, um die Option 'Facebook im Browser öffnen' zu aktivieren.",
    xTooltip: "Bitte fügen Sie eine gültige X-URL hinzu, um die Option 'X im Browser öffnen' zu aktivieren.",
    ContinueEditing: "Bearbeiten fortsetzen",
    Reset: "Verwerfen",
    ConfirmCancelForm: "Möchten Sie Änderungen verwerfen oder mit der Bearbeitung fortfahren?",
    CreateGroupBefore: "Sie haben keine Gruppen. Erstellen Sie eine neue Gruppe, um Ihre Kontakte hinzuzufügen.",
    MaxLengthError: "Dieses Feld darf maximal 50 Zeichen enthalten.",
    PremiumMessage1: "Sie verwenden derzeit die kostenlose Version von Kontakte Pro.",
    PremiumMessage2: "um zur Premium-Version zu wechseln,",
    SureToExportContact: "Möchten Sie wirklich alle Ihre Kontakte exportieren?",
    MessageSendMailExport: "Nach Abschluss des Exports erhalten Sie eine E-Mail an {UpnContext}.",
    AddContactToGroup: "Den Kontakt zu einer Gruppe hinzufügen",
    "phones.mobileError": "Die Telefonnummer muss aus Ziffern mit (), /, + und . bestehen.",
    "phones.businessPhoneError": "Die Telefonnummer muss aus Ziffern mit (), /, + und . bestehen.",
    "phones.businessFaxError": "Die Telefonnummer muss aus Ziffern mit (), /, + und . bestehen.",
    NoGroup: "Sie haben noch keine Gruppen. Sie können welche hinzufügen, indem Sie den unten stehenden Button verwenden.",
    DeletePermanently: "Dauerhaft löschen",
    NoMatch: "Kein Treffer gefunden",
    Company: "Unternehmen",
    Success: "Ihre Änderungen wurden erfolgreich gespeichert.",
    NoEditPerm: "Sie haben keine Berechtigung, Kontakte oder Gruppen zu bearbeiten.",
    NoDeletePerm: "Sie haben keine Berechtigung, Kontakte oder Gruppen zu löschen.",
    NoAddPerm: "Sie haben keine Berechtigung, Kontakte oder Gruppen hinzuzufügen.",
    PremiumMessageCard: "Sie müssen {license} sein, um auf diesen Benutzer zuzugreifen",
    PremiumButtonCard: "Upgrade {license}",
    ContactLimitReached: "Kontaktlimit erreicht",
    ContactLimitReachedTitle1: "Sie haben Ihr Kontaktlimit von 1000 Kontakten erreicht.",
    ContactLimitReachedText: "Um weitere Kontakte hinzuzufügen und auf erweiterte Funktionen zuzugreifen, erwägen Sie ein Upgrade auf unsere Platinum-Stufe.",
    ContactLimitReachedTitle2: "Vorteile eines Upgrades auf Platinum:",
    UnlimitedContacts: "Unbegrenzte Kontakte",
    AdvancedPermissions: "Erweiterte Berechtigungen",
    PowerAutomateConnector: "Power Automate Connector",
    APIAccess: "API-Zugriff für Entwickler",
    ViewPlans: "Pläne anzeigen",
    UpgradeToPaidPlan: "Aktualisieren",
  },
  it: {
    DisplayMode: "Modalità di visualizzazione",
    Card: "Carta",
    List: "Elenco",
    Group: "Gruppo",
    Contact: "Contatto",
    ExportContact: "Esporta contatti",
    ImportContact: "Importa contatti",
    About: "A proposito",
    SuggestFeature: "Suggerisci una funzione",
    HelpCenter: "Aide",
    SearchContact: "Si prega di cercare utilizzando Nome, Lavoro, Azienda, Dipartimento, Note, Gruppi o Indirizzo per trovare ciò di cui si ha bisogno.",
    New: "Nuovo",
    NewGroup: "Nuovo gruppo",
    Name: "Nome",
    Close: "Chiudi",
    Create: "Crea",
    jobTitle: "Titolo di lavoro",
    company: "Azienda",
    department: "Reparto",
    Email: "Email",
    website: "Sito web",
    linkedin: "Linkedin",
    facebook: "Facebook",
    twitter: "X",
    businessPhone: "Telefono aziendale",
    Cellphone: "Cellulare",
    Phone: "Telefono fisso",
    businessFax: "Fax aziendale",
    Addresses: "Indirizzi",
    Notes: "Note",
    General: "Generale",
    Internet: "Internet",
    Phones: "Telefoni",
    ChooseGroup: "Scegli un gruppo",
    AddContact: "Aggiungi contatto",
    Edit: "Modifica",
    Delete: "Elimina",
    name: "Nome completo",
    DeleteContactMessage: "Sei sicuro di voler eliminare questo contatto?",
    DeleteContactMessageBold: "Sarà eliminato definitivamente",
    DeleteContact: "Elimina contatto",
    ClickHere: "Clicca qui",
    EditGroup: "Modifica gruppo",
    NoContact: "Non hai ancora contatti. Puoi aggiungerli utilizzando il pulsante qui sotto.",
    DefaultError: "Si è verificato un errore. Si prega di ricaricare l'applicazione. Grazie.",
    Add: "Aggiungi",
    DeleteGroup: "Elimina gruppo",
    GroupError: "Si è verificato un errore durante la modifica o l'aggiunta del tuo gruppo. Per favore, riprova.",
    DeleteUserError: "Impossibile eliminare questo contatto. Per favore, riprova.",
    EditUserError: "Impossibile aggiornare questo contatto. Per favore, riprova.",
    CreateUserError: "Impossibile creare un contatto. Per favore, riprova.",
    CreateGroupError: "Impossibile creare un gruppo. Assicurati di aver inserito un nome con almeno 1 carattere.",
    EditGroupError: "Impossibile aggiornare questo gruppo. Assicurati di aver inserito un nome con almeno 1 carattere.",
    DeleteGroupError: "Impossibile eliminare questo gruppo. Per favore, riprova più tardi.",
    NoName: "Nessun nome è stato inserito.",
    TheInput: "Il campo",
    wrongURL: "non corrisponde a un URL valido (https://example.com).",
    DownloadModelRule1: "1. Scarica il modello del file Excel e compilalo con i tuoi contatti.",
    DownloadModel: "Scarica il modello",
    DownloadModelRule2: "2. Successivamente, clicca su 'Importa contatti' e seleziona il modello che contiene i tuoi contatti.",
    StartImport: "Inizia l'importazione",
    StartExport: "Inizia l'esportazione",
    CannotExportData: "Impossibile esportare i tuoi dati. Per favore, riprova.",
    CannotImportContact: "Impossibile importare i tuoi contatti. Per favore, riprova.",
    OnlyExcelFormat: "Sono ammessi solo file Excel.",
    NoChatWithOtherTenant:
      "L'opzione di chat non funziona con i contatti al di fuori della tua organizzazione. Per esplorare questa funzione, seleziona un contatto all'interno della tua organizzazione.",
    Information: "Informazioni",
    startChat: "Inizia una chat",
    SendMail: "Invia una email",
    PlanAMeeting: "Pianifica una riunione",
    Call: "Chiamata telefonica",
    FileExceedSize: "L'immagine del profilo che hai selezionato è troppo grande. {file}MB > 5MB",
    OnlyImageFormat: "Sono consentite solo immagini .jpeg/.png",
    LoadAnImage: "Carica un'immagine",
    Save: "Salva",
    None: "Nessuno",
    EditContact: "Modifica contatto",
    NewContact: "Nuovo contatto",
    nameError: "Questo campo è obbligatorio",
    emailError: "Inserisci un indirizzo email valido, ad esempio: esempio@dominio.com",
    websiteError: "Inserisci un URL del sito web valido, ad esempio: https://www.dominio.com o dominio.com",
    linkedinError: "Inserisci un URL del profilo LinkedIn valido, ad esempio: https://www.linkedin.com/in/tuoprofilo o tuoprofilo",
    facebookError: "Inserisci un URL del profilo Facebook valido, ad esempio: https://www.facebook.com/tuoprofilo o tuoprofilo",
    xError: "Inserisci un URL valido, ad esempio: https://www.dominio.com o dominio.com",
    businessPhoneError: "Il numero di telefono deve contenere solo cifre con (/, +, .)",
    cellphoneError: "Il numero di telefono deve contenere solo cifre con (/, +, .)",
    phoneError: "Il numero di telefono deve contenere solo cifre con (/, +, .)",
    businessFaxError: "Il numero di fax deve contenere solo cifre con (/, +, .)",
    Address: "Indirizzo",
    NotLandscape: "La tua immagine deve essere in formato paesaggio",
    ImportMessage: "L'importazione dei tuoi contatti è in corso. Ti verrà inviata un'email per confermare il completamento dell'importazione. Grazie",
    RequestInProgress: "La tua richiesta è in corso...",
    MessageSendMailImport: "Quando l'importazione è completata, riceverai un'e-mail a {UpnContext}.",
    RemoveTitle: "Leggere prima di cancellare il Rubrica Pro: ",
    RemoveItem1: "1. Quando si cancella Rubrica Pro, si cancellano anche tutti i dati memorizzati. Assicurati di recuperare tutte le informazioni necessarie prima di cancellarlo.",
    RemoveItem2: "2. Non è possibile recuperare una Rubrica Pro dopo averla cancellata. Una volta che l'hai cancellata, non saremo in grado di ripristinarla.",
    GroupAlreadyExist: "Il nome del gruppo esiste già. Si prega di selezionarne un altro",
    Copied: "Copiato !",
    InfoEventCreatedBy: "Creato da",
    ConfigurationHelp: "Abbiamo spostato tutte le impostazioni nella finestra principale di Contacts Pro per facilitare l'accesso.",
    GroupsAdmin: "Amministrazione del gruppo",
    permissions: "Permessi",
    connector: "Connettore",
    See: "Vedere",
    Groups: "Gruppi",
    email: "Indirizzo email",
    mobile: "Cellulare",
    Cancel: "Annulla",
    "internet.emailError": "Inserisci un'email valida, es. esempio@dominio.com",
    "internet.websiteError": "Inserisci un URL di sito web valido, es. https://www.dominio.com o dominio.com",
    "internet.linkedinError": "Inserisci un URL di sito web valido, es. https://www.dominio.com o dominio.com",
    "internet.facebookError": "Inserisci un URL di sito web valido, es. https://www.dominio.com o dominio.com",
    "internet.twitterError": "Inserisci un URL di sito web valido, es. https://www.dominio.com o dominio.com",
    Confirm: "Conferma",
    AddToGroup: "Aggiungi {name} a un gruppo",
    NoResult: "Nessun risultato trovato",
    Stay: "Rimani",
    GroupAdministration: "Amministrazione del gruppo",
    CreateGroup: "Crea un gruppo",
    searchGroup: "Cerca un gruppo",
    NoEntries: "Nessuna voce",
    AddGroupMember: "Aggiungi un membro al gruppo",
    GroupMember: "Membro(i) del gruppo",
    GroupName: "Nome del gruppo",
    RemoveFromGroup: "Rimuovi dal gruppo",
    RemoveContactFromGroupMessage: "Sei sicuro di voler rimuovere questo contatto dal gruppo",
    RemoveContactFromGroup: "Rimuovi il contatto dal gruppo",
    StartTypingAName: "Inizia a digitare il nome del contatto",
    Settings: "Impostazioni di visualizzazione",
    Copy: "Duplica",
    CopyGroup: "Copia gruppo",
    CopyGroupMessage: "Per copiare il gruppo, fornisci un nome univoco",
    NoContactInGroup: "Non ci sono contatti in questo gruppo",
    NoContactFound: "Nessun contatto trovato",
    searchContact: "Cerca un contatto",
    DeleteGroupMessage: "Sei sicuro di voler eliminare",
    chatTooltip: "Per avviare una chat Teams, aggiungi un indirizzo email valido O Questa opzione è disponibile solo per le email della tua organizzazione.",
    callTooltip: "Aggiungi un numero di telefono valido per attivare l'opzione 'Avvia una chiamata audio'.",
    mailTooltip: "Si prega di aggiungere un indirizzo email valido per attivare l'opzione 'Invia email'.",
    calendarTooltip: "Si prega di aggiungere un indirizzo email valido per attivare l'opzione 'Crea una riunione Teams'.",
    webTooltip: "Si prega di aggiungere un URL valido per attivare l'opzione 'Apri il sito web nel browser'.",
    linkedinTooltip: "Si prega di aggiungere un URL LinkedIn valido per attivare l'opzione 'Apri LinkedIn nel browser'.",
    facebookTooltip: "Si prega di aggiungere un URL Facebook valido per attivare l'opzione 'Apri Facebook nel browser'.",
    xTooltip: "Si prega di aggiungere un URL X valido per attivare l'opzione 'Apri X nel browser'.",
    ContinueEditing: "Continua a modificare",
    Reset: "Annulla",
    ConfirmCancelForm: "Vuoi annullare le modifiche o continuare a modificare?",
    CreateGroupBefore: "Non hai gruppi. Crea un nuovo gruppo per aggiungere i tuoi contatti.",
    MaxLengthError: "Questo campo non può superare i 50 caratteri.",
    PremiumMessage1: "Stai utilizzando attualmente la versione gratuita di Contatti Pro.",
    PremiumMessage2: "per passare alla versione Premium,",
    SureToExportContact: "Sei sicuro di voler esportare tutti i tuoi contatti?",
    MessageSendMailExport: "Una volta completata l'esportazione, riceverai un'email a {UpnContext}.",
    AddContactToGroup: "Aggiungi il contatto a un gruppo",
    "phones.mobileError": "Il numero di telefono deve essere composto da cifre con (), /, + e .",
    "phones.businessPhoneError": "Il numero di telefono deve essere composto da cifre con (), /, + e .",
    "phones.businessFaxError": "Il numero di telefono deve essere composto da cifre con (), /, + e .",
    NoGroup: "Non hai ancora nessun gruppo. Puoi aggiungerli utilizzando il pulsante qui sotto.",
    DeletePermanently: "Elimina definitivamente",
    NoMatch: "Nessuna corrispondenza trovata",
    Company: "Azienda",
    Success: "Le modifiche sono state salvate con successo.",
    NoEditPerm: "Non hai i diritti per modificare i contatti o i gruppi.",
    NoDeletePerm: "Non hai i diritti per eliminare i contatti o i gruppi.",
    NoAddPerm: "Non hai i diritti per aggiungere contatti o gruppi.",
    PremiumMessageCard: "Devi essere {license} per accedere a questo utente",
    PremiumButtonCard: "Aggiorna {license}",
    ContactLimitReached: "Limite di contatti raggiunto",
    ContactLimitReachedTitle1: "Hai raggiunto il limite di 1000 contatti.",
    ContactLimitReachedText: "Per aggiungere più contatti e accedere a funzionalità avanzate, considera l'upgrade al nostro livello Platinum.",
    ContactLimitReachedTitle2: "Vantaggi dell'aggiornamento a Platinum:",
    UnlimitedContacts: "Contatti illimitati",
    AdvancedPermissions: "Permessi avanzati",
    PowerAutomateConnector: "Connettore Power Automate",
    APIAccess: "Accesso API per sviluppatori",
    ViewPlans: "Visualizza piani",
    UpgradeToPaidPlan: "Aggiornare",
  },
  es: {
    DisplayMode: "Modo de visualización",
    Card: "Tarjeta",
    List: "Lista",
    Group: "Grupo",
    Contact: "Contacto",
    ExportContact: "Exportar contactos",
    ImportContact: "Importar contactos",
    About: "Acerca de",
    SuggestFeature: "Sugerir una característica",
    HelpCenter: "Centro de ayuda",
    SearchContact: "Por favor, busque utilizando Nombre, Trabajo, Empresa, Departamento, Notas, Grupos o Dirección para encontrar lo que necesita.",
    New: "Nuevo",
    NewGroup: "Nuevo grupo",
    Name: "Nombre",
    Close: "Cerrar",
    Create: "Crear",
    jobTitle: "Cargo",
    company: "Empresa",
    department: "Departamento",
    Email: "Correo electrónico",
    website: "Sitio web",
    linkedin: "Linkedin",
    facebook: "Facebook",
    twitter: "X",
    businessPhone: "Teléfono de trabajo",
    Cellphone: "Teléfono celular",
    Phone: "Teléfono fijo",
    businessFax: "Fax de trabajo",
    Addresses: "Direcciones",
    Notes: "Notas",
    General: "General",
    Internet: "Internet",
    Phones: "Teléfonos",
    ChooseGroup: "Elegir un grupo",
    AddContact: "Agregar contacto",
    Edit: "Editar",
    Delete: "Eliminar",
    name: "Nombre completo",
    DeleteContactMessage: "¿Estás seguro de que deseas eliminar este contacto?",
    DeleteContactMessageBold: "Se eliminará de forma permanente",
    DeleteContact: "Eliminar contacto",
    ClickHere: "Haz clic aquí",
    EditGroup: "Editar grupo",
    NoContact: "Todavía no tienes ningún contacto. Puedes agregarlos usando el botón de abajo.",
    DefaultError: "Ha ocurrido un error. Por favor, recarga la aplicación. Gracias.",
    Add: "Agregar",
    DeleteGroup: "Eliminar grupo",
    GroupError: "Ha ocurrido un error al editar o agregar tu grupo. Por favor, inténtalo de nuevo.",
    DeleteUserError: "No se puede eliminar este contacto. Por favor, inténtalo de nuevo.",
    EditUserError: "No se puede actualizar este contacto. Por favor, inténtalo de nuevo.",
    CreateUserError: "No se puede crear un contacto. Por favor, inténtalo de nuevo.",
    CreateGroupError: "No se puede crear un grupo. Asegúrate de haber ingresado un nombre con al menos 1 caracter.",
    EditGroupError: "No se puede actualizar este grupo. Asegúrate de haber ingresado un nombre con al menos 1 caracter.",
    DeleteGroupError: "No se puede eliminar este grupo. Por favor, inténtalo de nuevo más tarde.",
    NoName: "No se ha ingresado ningún nombre.",
    TheInput: "El campo",
    wrongURL: "no coincide con una URL válida (https://ejemplo.com).",
    DownloadModelRule1: "1. Descarga la plantilla de archivo de Excel y llénala con tus contactos.",
    DownloadModel: "Descargar plantilla",
    DownloadModelRule2: "2. Luego, haz clic en 'Importar contactos' y selecciona la plantilla que contiene tus contactos.",
    StartImport: "Iniciar importación",
    StartExport: "Iniciar exportación",
    CannotExportData: "No se puede exportar tus datos. Por favor, inténtalo de nuevo.",
    CannotImportContact: "No se pueden importar tus contactos. Por favor, inténtalo de nuevo.",
    OnlyExcelFormat: "Solo se permiten archivos de Excel.",
    NoChatWithOtherTenant: "La opción de chat no funciona con contactos fuera de tu organización. Para explorar esta función, selecciona un contacto dentro de tu organización.",
    Information: "Información",
    startChat: "Iniciar un chat",
    SendMail: "Enviar un correo",
    PlanAMeeting: "Planificar una reunión",
    Call: "Llamada telefónica",
    FileExceedSize: "La imagen de perfil que has seleccionado es demasiado grande. {file}MB > 5MB",
    OnlyImageFormat: "Solo se permiten imágenes en formato .jpeg/.png",
    LoadAnImage: "Cargar una imagen",
    Save: "Guardar",
    None: "Ninguno",
    EditContact: "Editar contacto",
    NewContact: "Nuevo contacto",
    nameError: "Este campo es obligatorio",
    emailError: "Por favor, ingrese una dirección de correo electrónico válida, por ejemplo: ejemplo@dominio.com",
    websiteError: "Por favor, ingrese una URL de sitio web válida, por ejemplo: https://www.dominio.com o dominio.com",
    linkedinError: "Por favor, ingrese una URL de perfil de LinkedIn válida, por ejemplo: https://www.linkedin.com/in/tuperfil o tuperfil",
    facebookError: "Por favor, ingrese una URL de perfil de Facebook válida, por ejemplo: https://www.facebook.com/tuperfil o tuperfil",
    xError: "Por favor, ingrese una URL válida, por ejemplo: https://www.dominio.com o dominio.com",
    businessPhoneError: "El número de teléfono debe contener solo números con (/, +, .)",
    cellphoneError: "El número de teléfono debe contener solo números con (/, +, .)",
    phoneError: "El número de teléfono debe contener solo números con (/, +, .)",
    businessFaxError: "El número de fax debe contener solo números con (/, +, .)",
    Address: "Dirección",
    NotLandscape: "Tu imagen debe estar en formato horizontal",
    ImportMessage: "La importación de sus contactos está en proceso. Se le enviará un correo electrónico para confirmar la finalización de la importación. Gracias",
    RequestInProgress: "Su solicitud está en curso...",
    MessageSendMailImport: "Cuando se complete la importación, recibirá un correo electrónico para {UpnContext}.",
    RemoveTitle: "Leggere prima di cancellare il Rubrica Pro: ",
    RemoveItem1: "1. Quando si cancella Rubrica Pro, si cancellano anche tutti i dati memorizzati. Assicurati di recuperare tutte le informazioni necessarie prima di cancellarlo.",
    RemoveItem2: "2. Non è possibile recuperare una Rubrica Pro dopo averla cancellata. Una volta che l'hai cancellata, non saremo in grado di ripristinarla.",
    GroupAlreadyExist: "El nombre del grupo ya existe. Por favor, seleccione otro",
    Copied: "Copiado !",
    InfoEventCreatedBy: "Creado por",
    ConfigurationHelp: "Hemos trasladado todas las configuraciones a la ventana principal de Contacts Pro para facilitar el acceso.",
    GroupsAdmin: "Administración de Grupos",
    permissions: "Permisos",
    connector: "Conector",
    See: "Ver",
    Groups: "Grupos",
    email: "Correo electrónico",
    mobile: "Móvil",
    Cancel: "Cancelar",
    "internet.emailError": "Por favor, introduce un correo electrónico válido, ej. ejemplo@dominio.com",
    "internet.websiteError": "Por favor, introduce una URL válida de sitio web, ej. https://www.dominio.com o dominio.com",
    "internet.linkedinError": "Por favor, introduce una URL válida de sitio web, ej. https://www.dominio.com o dominio.com",
    "internet.facebookError": "Por favor, introduce una URL válida de sitio web, ej. https://www.dominio.com o dominio.com",
    "internet.twitterError": "Por favor, introduce una URL válida de sitio web, ej. https://www.dominio.com o dominio.com",
    Confirm: "Confirmar",
    AddToGroup: "Añadir {name} a un grupo",
    NoResult: "No se encontraron resultados",
    Stay: "Quedarse",
    GroupAdministration: "Administración de grupo",
    CreateGroup: "Crear un grupo",
    searchGroup: "Buscar un grupo",
    NoEntries: "Sin entradas",
    AddGroupMember: "Agregar un miembro al grupo",
    GroupMember: "Miembro(s) del grupo",
    GroupName: "Nombre del grupo",
    RemoveFromGroup: "Eliminar del grupo",
    RemoveContactFromGroupMessage: "¿Estás seguro de que quieres eliminar este contacto del grupo?",
    RemoveContactFromGroup: "Eliminar contacto del grupo",
    StartTypingAName: "Empieza a escribir el nombre del miembro",
    Settings: "Configuración de visualización",
    Copy: "Duplicar",
    CopyGroup: "Copiar grupo",
    CopyGroupMessage: "Para copiar el grupo, proporciona un nombre único",
    NoContactInGroup: "No hay contactos en este grupo",
    NoContactFound: "No se encontraron contactos",
    searchContact: "Buscar un contacto",
    DeleteGroupMessage: "¿Estás seguro de que quieres eliminar",
    chatTooltip: "Para iniciar un chat de Teams, agrega una dirección de correo electrónico válida O Esta opción solo está disponible para correos electrónicos de tu organización.",
    callTooltip: "Agrega un número de teléfono válido para activar la opción 'Iniciar una llamada de audio'.",
    mailTooltip: "Por favor, agrega una dirección de correo electrónico válida para activar la opción 'Enviar correo electrónico'.",
    calendarTooltip: "Por favor, agregue una dirección de correo electrónico válida para activar la opción 'Crear una reunión de Teams'.",
    webTooltip: "Por favor, agrega una URL válida para activar la opción 'Abrir el sitio web en el navegador'.",
    linkedinTooltip: "Por favor, agrega una URL de LinkedIn válida para activar la opción 'Abrir LinkedIn en el navegador'.",
    facebookTooltip: "Por favor, agrega una URL de Facebook válida para activar la opción 'Abrir Facebook en el navegador'.",
    xTooltip: "Por favor, agrega una URL X válida para activar la opción 'Abrir X en el navegador'.",
    ContinueEditing: "Continuar editando",
    Reset: "Descartar",
    ConfirmCancelForm: "¿Desea descartar las ediciones o continuar editando?",
    CreateGroupBefore: "No tienes grupos. Crea un nuevo grupo para agregar tus contactos.",
    MaxLengthError: "Este campo no puede exceder los 50 caracteres.",
    PremiumMessage1: "Actualmente estás utilizando la versión gratuita de Contactos Pro.",
    PremiumMessage2: "para actualizar a la versión Premium,",
    SureToExportContact: "¿Estás seguro de que quieres exportar todos tus contactos?",
    MessageSendMailExport: "Una vez completada la exportación, recibirás un correo electrónico en {UpnContext}.",
    AddContactToGroup: "Añadir el contacto a un grupo",
    "phones.mobileError": "El número de teléfono debe consistir en dígitos con (), /, + y .",
    "phones.businessPhoneError": "El número de teléfono debe consistir en dígitos con (), /, + y .",
    "phones.businessFaxError": "El número de teléfono debe consistir en dígitos con (), /, + y .",
    NoGroup: "Todavía no tienes ningún grupo. Puedes añadirlos usando el botón de abajo.",
    DeletePermanently: "Eliminar permanentemente",
    NoMatch: "No se encontraron resultados",
    Company: "Empresa",
    Success: "Los cambios han sido guardados exitosamente.",
    NoEditPerm: "No tienes los derechos para editar los contactos o los grupos.",
    NoDeletePerm: "No tienes los derechos para eliminar los contactos o los grupos.",
    NoAddPerm: "No tienes los derechos para añadir contactos o grupos.",
    PremiumMessageCard: "Debes tener {license} para acceder a este usuario",
    PremiumButtonCard: "Actualizar {license}",
    ContactLimitReached: "Límite de contactos alcanzado",
    ContactLimitReachedTitle1: "Has alcanzado tu límite de contacto de 1000 contactos.",
    ContactLimitReachedText: "Para agregar más contactos y acceder a funciones avanzadas, considera actualizar a nuestro nivel Platinum.",
    ContactLimitReachedTitle2: "Beneficios de actualizar a Platinum:",
    UnlimitedContacts: "Contactos ilimitados",
    AdvancedPermissions: "Permisos avanzados",
    PowerAutomateConnector: "Conector Power Automate",
    APIAccess: "Acceso a la API para desarrolladores",
    ViewPlans: "Ver planes",
    UpgradeToPaidPlan: "Actualizar",
  },
  pt: {
    DisplayMode: "Modo de Exibição",
    Card: "Cartão",
    List: "Lista",
    Group: "Grupo",
    Contact: "Contato",
    ExportContact: "Exportar Contatos",
    ImportContact: "Importar Contatos",
    About: "Sobre",
    SuggestFeature: "Sugerir um Recurso",
    HelpCenter: "Centro de Ajuda",
    SearchContact: "Por favor, procure usando Nome, Cargo, Empresa, Departamento, Notas, Grupos ou Endereço para encontrar o que você precisa.",
    New: "Novo",
    NewGroup: "Novo Grupo",
    Name: "Nome",
    Close: "Fechar",
    Create: "Criar",
    jobTitle: "Cargo",
    company: "Empresa",
    department: "Departamento",
    Email: "Email",
    website: "Site",
    linkedin: "Linkedin",
    facebook: "Facebook",
    twitter: "Twitter",
    businessPhone: "Telefone Comercial",
    Cellphone: "Celular",
    Phone: "Telefone",
    businessFax: "Fax Comercial",
    Addresses: "Endereços",
    Notes: "Notas",
    General: "Geral",
    Internet: "Internet",
    Phones: "Telefones",
    ChooseGroup: "Escolher um Grupo",
    AddContact: "Adicionar Contato",
    Edit: "Editar",
    Delete: "Deletar",
    name: "Nome Completo",
    DeleteContactMessage: "Você tem certeza de que deseja deletar este contato?",
    DeleteContactMessageBold: "Ele será permanentemente deletado",
    DeleteContact: "Deletar Contato",
    ClickHere: "Clique Aqui",
    EditGroup: "Editar Grupo",
    NoContact: "Você ainda não tem nenhum contato. Você pode adicioná-los usando o botão abaixo.",
    DefaultError: "Ocorreu um erro. Por favor, recarregue o aplicativo. Obrigado.",
    Add: "Adicionar",
    DeleteGroup: "Deletar Grupo",
    GroupError: "Ocorreu um erro ao editar ou adicionar seu grupo. Por favor, tente novamente.",
    DeleteUserError: "Não foi possível deletar este contato. Por favor, tente novamente.",
    EditUserError: "Não foi possível atualizar este contato. Por favor, tente novamente.",
    CreateUserError: "Não foi possível criar um contato. Por favor, tente novamente.",
    CreateGroupError: "Não foi possível criar um grupo. Por favor, certifique-se de ter inserido um nome com pelo menos 1 caractere.",
    EditGroupError: "Não foi possível atualizar este grupo. Por favor, certifique-se de ter inserido um nome com pelo menos 1 caractere.",
    DeleteGroupError: "Não foi possível deletar este grupo. Por favor, tente novamente mais tarde.",
    NoName: "Nenhum nome foi inserido.",
    TheInput: "O campo",
    wrongURL: "não corresponde a uma URL válida (https://exemplo.com).",
    DownloadModelRule1: "1. Baixe o modelo de arquivo Excel e preencha com seus contatos.",
    DownloadModel: "Baixar Modelo",
    DownloadModelRule2: "2. Em seguida, clique em 'Importar Contatos' e selecione o modelo contendo seus contatos.",
    StartImport: "Iniciar Importação",
    StartExport: "Iniciar Exportação",
    CannotExportData: "Não foi possível exportar seus dados. Por favor, tente novamente.",
    CannotImportContact: "Não foi possível importar seus contatos. Por favor, tente novamente.",
    OnlyExcelFormat: "Apenas arquivos Excel são permitidos.",
    NoChatWithOtherTenant: "A opção de chat não funciona com contatos fora da sua organização. Para explorar essa funcionalidade, selecione um contato dentro da sua organização.",
    Information: "Informação",
    startChat: "Iniciar um Chat",
    SendMail: "Enviar um Email",
    PlanAMeeting: "Planejar uma Reunião",
    Call: "Ligação Telefônica",
    FileExceedSize: "A imagem de perfil selecionada é muito grande. {file}MB > 5MB",
    OnlyImageFormat: "Apenas imagens .jpeg/.png são permitidas",
    LoadAnImage: "Carregar uma imagem",
    Save: "Salvar",
    None: "Nenhum",
    EditContact: "Editar Contato",
    NewContact: "Novo Contato",
    nameError: "Este campo é obrigatório",
    emailError: "Por favor, insira um endereço de email válido, por exemplo, exemplo@dominio.com",
    websiteError: "Por favor, insira uma URL de site válida, por exemplo, https://www.dominio.com ou dominio.com",
    linkedinError: "Por favor, insira uma URL de perfil LinkedIn válida, por exemplo, https://www.linkedin.com/in/seuperfil ou seuperfil",
    facebookError: "Por favor, insira uma URL de perfil Facebook válida, por exemplo, https://www.facebook.com/seuperfil ou seuperfil",
    xError: "Por favor, insira uma URL válida, por exemplo, https://www.dominio.com ou dominio.com",
    businessPhoneError: "O número de telefone deve consistir em dígitos com (/, +, .)",
    cellphoneError: "O número de telefone deve consistir em dígitos com (/, +, .)",
    phoneError: "O número de telefone deve consistir em dígitos com (/, +, .)",
    businessFaxError: "O número de fax deve consistir em dígitos com (/, +, .)",
    Address: "Endereço",
    NotLandscape: "Sua imagem deve estar no formato paisagem",
    ImportMessage: "A importação de seus contatos está em andamento. Um email será enviado para você para confirmar a conclusão da importação. Obrigado",
    RequestInProgress: "Sua solicitação está em andamento...",
    MessageSendMailImport: "Quando a importação for concluída, você receberá um email em {UpnContext}.",
    RemoveTitle: "Leia antes de deletar o Contato Pro: ",
    RemoveItem1: "1. Ao deletar o Contato Pro, você também deleta todos os dados armazenados. Certifique-se de recuperar todas as informações necessárias antes de deletá-lo.",
    RemoveItem2: "2. Você não pode recuperar um Contato Pro após deletá-lo. Uma vez que você o deletar, não poderemos restaurá-lo.",
    GroupAlreadyExist: "O nome do grupo já existe. Por favor, selecione um diferente",
    Copied: "Copiado!",
    InfoEventCreatedBy: "Criado por",
    ConfigurationHelp: "Nós transferimos todas as configurações para a janela principal do Contatos Pro para facilitar o acesso.",
    GroupsAdmin: "Administração de Grupos",
    permissions: "Permissões",
    connector: "Conector",
    See: "Ver",
    Groups: "Grupos",
    email: "Endereço de Email",
    mobile: "Celular",
    Cancel: "Cancelar",
    "internet.emailError": "Por favor, insira um email válido, por exemplo, exemplo@dominio.com",
    "internet.websiteError": "Por favor, insira uma URL de site válida, por exemplo, https://www.dominio.com ou dominio.com",
    "internet.linkedinError": "Por favor, insira uma URL de site válida, por exemplo, https://www.dominio.com ou dominio.com",
    "internet.facebookError": "Por favor, insira uma URL de site válida, por exemplo, https://www.dominio.com ou dominio.com",
    "internet.twitterError": "Por favor, insira uma URL de site válida, por exemplo, https://www.dominio.com ou dominio.com",
    Confirm: "Confirmar",
    AddToGroup: "Adicionar {name} a um grupo",
    NoResult: "Nenhum resultado encontrado",
    Stay: "Permanecer",
    GroupAdministration: "Administração de Grupos",
    CreateGroup: "Criar um grupo",
    searchGroup: "Procurar por um grupo",
    NoEntries: "Nenhuma entrada",
    AddGroupMember: "Adicionar um membro ao grupo",
    GroupMember: "Membro(s) do grupo",
    GroupName: "Nome do grupo",
    RemoveFromGroup: "Remover do grupo",
    RemoveContactFromGroupMessage: "Você tem certeza de que deseja remover este contato do grupo",
    RemoveContactFromGroup: "Remover contato do grupo",
    StartTypingAName: "Comece a digitar o nome do contato",
    Settings: "Configurações de Exibição",
    Copy: "Duplicar",
    CopyGroup: "Copiar um grupo",
    CopyGroupMessage: "Para copiar o grupo, por favor, forneça um nome único",
    NoContactInGroup: "Não há contatos neste grupo",
    NoContactFound: "Nenhum contato encontrado",
    searchContact: "Procurar por um contato",
    DeleteGroupMessage: "Você tem certeza de que deseja deletar",
    chatTooltip: "Para iniciar um chat no Teams, adicione um email válido OU Esta opção está disponível apenas para emails dentro da sua organização.",
    callTooltip: "Adicione um número de telefone válido para ativar a opção 'Iniciar uma chamada de áudio'.",
    mailTooltip: "Por favor, adicione um endereço de email válido para ativar a opção 'Enviar um email'.",
    calendarTooltip: "Por favor, adicione um endereço de e-mail válido para ativar a opção 'Criar uma reunião do Teams'.",
    webTooltip: "Por favor, adicione uma URL válida para ativar a opção 'Abrir site no navegador'.",
    linkedinTooltip: "Por favor, adicione uma URL do LinkedIn válida para ativar a opção 'Abrir LinkedIn no navegador'.",
    facebookTooltip: "Por favor, adicione uma URL do Facebook válida para ativar a opção 'Abrir Facebook no navegador'.",
    xTooltip: "Por favor, adicione uma URL válida do X para ativar a opção 'Abrir X no navegador'.",
    ContinueEditing: "Continuar editando",
    Reset: "Descartar",
    ConfirmCancelForm: "Você quer descartar as edições ou continuar editando?",
    CreateGroupBefore: "Você não tem grupos. Crie um novo grupo para adicionar seus contatos.",
    MaxLengthError: "Este campo não pode exceder 50 caracteres.",
    PremiumMessage1: "Você está usando a versão gratuita do Contacts Pro.",
    PremiumMessage2: "para atualizar para a versão Premium,",
    SureToExportContact: "Você tem certeza de que deseja exportar todos os seus contatos?",
    MessageSendMailExport: "Uma vez que a exportação seja concluída, você receberá um email em {UpnContext}.",
    AddContactToGroup: "Adicionar o contato a um grupo",
    "phones.mobileError": "O número de telefone deve consistir em dígitos com (), /, + e .",
    "phones.businessPhoneError": "O número de telefone deve consistir em dígitos com (), /, + e .",
    "phones.businessFaxError": "O número de telefone deve consistir em dígitos com (), /, + e .",
    NoGroup: "Você ainda não tem nenhum grupo. Você pode adicioná-los usando o botão abaixo.",
    DeletePermanently: "Excluir permanentemente",
    NoMatch: "Nenhum resultado encontrado",
    Company: "Empresa",
    Success: "As alterações foram salvas com sucesso.",
    NoEditPerm: "Você não tem direitos para editar contatos ou grupos.",
    NoDeletePerm: "Você não tem direitos para excluir contatos ou grupos.",
    NoAddPerm: "Você não tem direitos para adicionar contatos ou grupos.",
    PremiumMessageCard: "Você deve ser {license} para acessar este usuário",
    PremiumButtonCard: "Atualizar {license}",
    ContactLimitReached: "Limite de contatos atingido",
    ContactLimitReachedTitle1: "Você atingiu seu limite de contato de 1000 contatos.",
    ContactLimitReachedText: "Para adicionar mais contatos e acessar recursos avançados, considere fazer upgrade para nosso nível Platinum.",
    ContactLimitReachedTitle2: "Benefícios de atualizar para Platinum:",
    UnlimitedContacts: "Contatos ilimitados",
    AdvancedPermissions: "Permissões avançadas",
    PowerAutomateConnector: "Conector Power Automate",
    APIAccess: "Acesso à API para desenvolvedores",
    ViewPlans: "Ver planos",
    UpgradeToPaidPlan: "Atualizar",
  },
};
