import { RichTextEditor, useTranslate } from "front";
import { createRef } from "react";
import { translations } from "../../translations";

export const useNoteTabs = () => {
  const t = useTranslate(translations);

  const richTextEditorRef = createRef<typeof RichTextEditor>();

  return { t, richTextEditorRef };
};
