import { useEffect } from "react";
import * as microsoftTeams from "@microsoft/teams-js";
import { useContactListCache } from "../../hooks/useQuery/useContactListCache";
import { deleteContactList } from "../../apis/actions/contactListAction";

export const useLogic = () => {
  const contact = useContactListCache();
  useEffect(() => {
    microsoftTeams.pages.config.registerOnRemoveHandler(async (evt) => {
      if (!contact?.queryContactList?.data?.id) {
        evt.notifyFailure();
      } else {
        await deleteContactList(contact.queryContactList.data?.id);
        evt.notifySuccess();
      }
    });
    microsoftTeams.pages.config.setValidityState(true);
    microsoftTeams.app.notifySuccess();
  }, [contact]);
  return {};
};
