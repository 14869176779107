export const addBase64Prefix = (image: string) => {
  const base64Prefixes = {
    png: "data:image/png;base64,",
    jpeg: "data:image/jpeg;base64,",
    jpg: "data:image/jpg;base64,",
    gif: "data:image/gif;base64,",
  };

  for (const prefix of Object.values(base64Prefixes)) {
    if (image.startsWith(prefix)) {
      return image;
    }
  }

  const extension = image.substring(0, 4);
  let base64Prefix = base64Prefixes.png;

  if (extension.includes("R0lG")) {
    base64Prefix = base64Prefixes.gif;
  } else if (extension.includes("/9j/")) {
    base64Prefix = base64Prefixes.jpeg;
  } else if (extension.includes("iVBORw0KGgoAAAANSUhEUgAAAMgAAACbCAYAAAAnQtTH")) {
    base64Prefix = base64Prefixes.png;
  }

  return base64Prefix + image;
};
