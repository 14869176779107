import { AppViewData } from "../interfaces/AppView/AppViewData";
import { AppView } from "../interfaces/AppView/AppView";
import { Contact } from "../views/Contact/Contact";
import { Configure } from "../views/Configure/Configure";
import { Widget } from "../views/Widget/Widget";
import { WidgetDetail } from "../views/Widget/Details/WidgetDetail";
import { WidgetConfiguration } from "../views/Widget/WidgetConfiguration";
import { RemoveContactList } from "../views/Remove/Remove.rendering";
import { Permissions } from "front";
import GroupAdministration from "../views/GroupAdministration/GroupAdministration";

const HomeView: AppViewData = {
  id: AppView.Contact,
  path: "/contact/:id",
  titleId: "Contact",
  element: Contact,
};

const ConfigureView: AppViewData = {
  id: AppView.Configure,
  path: "/configure",
  titleId: "Configuration",
  element: Configure,
};

const RemoveView: AppViewData = {
  id: AppView.Remove,
  path: "/remove/:id",
  titleId: "Remove",
  element: RemoveContactList,
};

const PermissionsView: AppViewData = {
  id: AppView.Permissions,
  path: "/permissions/:id",
  titleId: "Permissions",
  element: Permissions,
};

const WidgetView: AppViewData = {
  id: AppView.Widget,
  path: "/widget",
  titleId: "Widget",
  element: Widget,
};

const WidgetDetailView: AppViewData = {
  id: AppView.WidgetDetail,
  path: "/widget/details",
  titleId: "WidgetDetail",
  element: WidgetDetail,
};

const WidgetConfigureView: AppViewData = {
  id: AppView.WidgetConfigure,
  path: "/widget/configure",
  titleId: "WidgetConfigure",
  element: WidgetConfiguration,
};

const GroupAdministrationView: AppViewData = {
  id: AppView.GroupAdministration,
  path: "/groups/manage",
  titleId: "Group Administration",
  element: GroupAdministration,
};

export const AppViews: Record<AppView, AppViewData> = {
  [AppView.Contact]: HomeView,
  [AppView.Configure]: ConfigureView,
  [AppView.Permissions]: PermissionsView,
  [AppView.Widget]: WidgetView,
  [AppView.WidgetDetail]: WidgetDetailView,
  [AppView.WidgetConfigure]: WidgetConfigureView,
  [AppView.Remove]: RemoveView,
  [AppView.GroupAdministration]: GroupAdministrationView,
};
