import { Button, CloseIcon, Dialog, Flex, Text } from "@fluentui/react-northstar";
import { useDeleteContact } from "./DeleteContact.logic";
import { memo, useMemo } from "react";
import { primaryStyle } from "../../../../utils/primaryStyle";

const DeleteContact = memo(() => {
  const logic = useDeleteContact();

  const renderContent = useMemo(
    () => (
      <div style={{ padding: "1rem 0" }}>
        <Text content={logic.t("DeleteContactMessage")}></Text> <Text content={logic.t("DeleteContactMessageBold")} weight="bold"></Text>
      </div>
    ),
    []
  );

  const renderFooter = useMemo(
    () => (
      <>
        <Flex hAlign="end">
          <Button disabled={logic.mutateDeleteContact.isLoading} content={logic.t("Cancel")} style={{ marginRight: ".5rem" }} onClick={logic.onClose} />
          <Button disabled={logic.mutateDeleteContact.isLoading} primary style={primaryStyle} content={logic.t("Delete")} onClick={logic.handleSubmit} />
        </Flex>
      </>
    ),
    [logic.mutateDeleteContact]
  );

  return (
    <Dialog
      header={logic.t("DeleteContact")}
      headerAction={{ icon: <CloseIcon />, title: "Close", onClick: logic.onClose }}
      content={renderContent}
      footer={renderFooter}
      defaultOpen={true}
      onCancel={logic.onClose}
      closeOnOutsideClick
    />
  );
});

export default DeleteContact;
