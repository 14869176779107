import { ITabs } from "../../../../interfaces/IContact/ITabs";

export let initialState: {
  currentTab: ITabs;
  canModify: boolean;
} = {
  currentTab: ITabs.CONTACT,
  canModify: false,
};

const getDefaultState = () => ({
  currentTab: ITabs.CONTACT,
  canModify: false,
});

export type IState = typeof initialState;

export enum IAction {
  MODIFY_TABS = "modify_tabs",
  CAN_MODIFY = "can_modify",
  CAN_CONFIRM = "can_confirm",
}

export const componentIsUnmounting = () => {
  initialState = getDefaultState();
};

const actionHandlers = Object.fromEntries(Object.values(IAction).map((action) => [action, (_: IState, payload: IState) => ({ ...payload })]));

export function reducer(state: IState, { type, payload }: { type: IAction; payload: IState }): IState {
  const actionHandler = actionHandlers[type] || ((state) => state);
  return actionHandler(state, payload);
}
