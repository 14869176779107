import { ReactElement } from "react";
import Navbar from "../../components/Navbar/Navbar";
import ContactList from "../../components/ContactList/ContactList";
import { useContact } from "./Contact.logic";
import AppLoader from "../../components/AppLoader/AppLoader";
import PremiumBanner from "../../components/PremiumBanner/PremiumBanner";
import FlexWrapper from "../../components/FlexWrapper/FlexWrapper";
import { Flex, Loader, Text } from "@fluentui/react-northstar";
import { ErrorBanner, useMsTeamsSelector, useTranslate } from "front";
import { translations } from "../../translations";
import "./Contact.scss";

export const Contact = (): ReactElement | null => {
  const logic = useContact();
  const t = useTranslate(translations);

  if (logic.error)
    return (
      <FlexWrapper>
        <Text content={t("DefaultError")} />
      </FlexWrapper>
    );

  if (logic.permsAreLoading) return <AppLoader />;

  return (
    <>
      {logic.isLoading && <AppLoader />}
      <div className="contact-top-container">
        <PremiumBanner />
        <Navbar />
      </div>
      <Flex className="contact-bottom-container" data-is-premium={logic.isPremium}>
        {logic.contactAreLoading ? (
          <Flex fill className="contact-contact-loader" vAlign="center" hAlign="center">
            <Loader />
          </Flex>
        ) : (
          <ContactList />
        )}
      </Flex>
    </>
  );
};
