import { Flex } from "@fluentui/react-northstar";
import GroupAdministrationBottom from "../../components/GroupAdministrationBottom/GroupAdministrationBottom";
import GroupAdministrationTop from "../../components/GroupAdministrationTop/GroupAdministrationTop";
import { useGroupAdministration } from "./GroupAdministration.logic";
import React from "react";
import AppLoader from "../../components/AppLoader/AppLoader";

const GroupAdministration = () => {
  const logic = useGroupAdministration();

  if (logic.isLoading) return <AppLoader />;

  return (
    <Flex style={{ padding: "2rem 1.5rem" }} column>
      <GroupAdministrationTop />
      <GroupAdministrationBottom />
    </Flex>
  );
};

export default GroupAdministration;
