export const listSort = (a: string, b: string) => {
  const aIsNumber = !isNaN(Number(a));
  const bIsNumber = !isNaN(Number(b));

  if (a === "" && b !== "") return 1; // Place empty string at the end
  if (a !== "" && b === "") return -1; // Place empty string at the end

  if (aIsNumber && bIsNumber) {
    return Number(a) - Number(b);
  }

  if (aIsNumber) return -1;
  if (bIsNumber) return 1;

  return a.toLowerCase().localeCompare(b.toLowerCase());
};
