import { IPhones } from "interfaces/IContact/IPhones";

export const openCall = (phones: IPhones) => {
  let selectedPhoneNumber = phones?.mobile ?? phones?.home ?? phones?.businessPhone ?? phones?.businessFax;
  if (!selectedPhoneNumber) return;
  let call = document.createElement("a");
  call.setAttribute("target", "_blank");
  call.href = `tel:${selectedPhoneNumber}`;
  call.click();
  call.remove();
};
