import React, { useEffect, useState } from "react";
import { Divider, Flex, Loader } from "@fluentui/react-northstar";
import { FieldDetail, TextAreaDetail } from "./FieldDetail";
import { useLocation } from "react-router-dom";
import "./WidgetDetails.css";
import { showErrorPage } from "@witivio_teamspro/witivio-react-toolkit";
import { IContact } from "../../../interfaces/IContact/IContact";
import { getContact } from "../../../apis/actions/contactAction";
import { translations } from "../../../translations";
import { useTranslate } from "front";

interface IQuery {
  contactListId: string;
  contactId: string;
}

export const WidgetDetail = () => {
  const { search } = useLocation();
  const [loading, setLoading] = useState<boolean>(true);
  const [contactUnique, setContactUnique] = useState<IContact>();
  const t = useTranslate(translations);

  useEffect(() => {
    (async () => {
      const queryDic = new URLSearchParams(search);
      const query: IQuery = {
        contactListId: queryDic.get("contactListId") as string,
        contactId: queryDic.get("contactId") as string,
      };
      await fetchContact(query.contactListId, query.contactId);
    })();
  }, []);

  const fetchContact = async (contactListId: string, contactId: string) => {
    const { result } = await getContact(contactListId, contactId);
    if (!result) {
      await showErrorPage("Can't find contact with id : " + contactId);
      return;
    }
    setContactUnique(result);
    setLoading(false);
  };

  return loading ? (
    <Loader style={{ height: "100vh" }} />
  ) : (
    <Flex fill column>
      <Flex fill column>
        <FieldDetail name={"NameFormGroup"} val={contactUnique?.name as string} />
        <FieldDetail name={"JobTitleFormContact"} val={contactUnique?.jobTitle as string} />
        <FieldDetail name={"CompanyFormContact"} val={contactUnique?.company as string} />
        <FieldDetail name={"DepartmentFormContact"} val={contactUnique?.department as string} />
      </Flex>
      <Divider color="brand" content={t("SectionInternet")} important />
      <Flex fill column>
        <FieldDetail link={!!contactUnique?.internet?.email ? "mailto:" + contactUnique?.internet?.email : ""} name={"EmailFormContact"} val={contactUnique?.internet?.email as string} />
        <FieldDetail link={contactUnique?.internet?.website as string} name={"WebsiteFormContact"} val={contactUnique?.internet?.website as string} />
        <FieldDetail link={contactUnique?.internet?.linkedin as string} name={"LinkedInFormContact"} val={contactUnique?.internet?.linkedin as string} />
        <FieldDetail link={contactUnique?.internet?.facebook as string} name={"FacebookFormContact"} val={contactUnique?.internet?.facebook as string} />
        <FieldDetail link={contactUnique?.internet?.twitter as string} name={"TwitterFormContact"} val={contactUnique?.internet?.twitter as string} />
      </Flex>
      <Divider color="brand" content={t("SectionPhones")} important />
      <Flex fill column>
        <FieldDetail name={"BusinessPhoneFormContact"} val={contactUnique?.phones?.businessPhone as string} />
        <FieldDetail name={"MobileFormContact"} val={contactUnique?.phones?.mobile as string} />
        <FieldDetail name={"HomeFormContact"} val={contactUnique?.phones?.home as string} />
        <FieldDetail name={"BusinessFaxFormContact"} val={contactUnique?.phones?.businessFax as string} />
      </Flex>
      <Divider color="brand" content={t("SectionAddress")} important />
      <FieldDetail name={"AddressFormContact"} val={contactUnique?.addresses[0]?.fullAddress as string} />
      <Divider color="brand" content={t("SectionNotes")} important />
      <TextAreaDetail name={"NotesFormContact"} val={contactUnique?.notes ?? ""} />
    </Flex>
  );
};
