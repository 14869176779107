export enum IDialogs {
  GROUP = "group",
  DELETE_GROUP = "delete_group",
  COPY_GROUP = "copy_group",
  REMOVE_FROM_GROUP = "remove_from_group",
  ABOUT = "about",
  DELETE_CONTACT = "delete_contact",
  IMPORT_CONTACT = "import_contact",
  EXPORT_CONTACT = "export_contact",
  NOT_SAME_TENANT = "not_same_tenant",
  SETTINGS_CONTACT = "settings",
  CONTACT = "contact",
  UPGRADE = "upgrade"
}
