import { configPerms } from "../../const/configPerms";
export const buildRight = (from, defaultValue) => {
    const appConfigPerms = configPerms[from];
    const map = appConfigPerms.map(i => {
        return [i, defaultValue];
    });
    const perm = Object.fromEntries(map);
    const specPerm = specificRight(from);
    return { ...perm, ...specPerm };
};
export const buildDefaultRight = (from, defaultRight) => {
    const appConfigPerms = configPerms[from];
    const map = appConfigPerms.map(i => {
        return [i, !!defaultRight[i]];
    });
    const perm = Object.fromEntries(map);
    return perm;
};
const specificRight = (from) => {
    const specific = {};
    if (from == "Calendar") {
        specific.allowShareSynchro = false;
    }
    return specific;
};
