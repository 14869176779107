import { Flex, Input, SearchIcon, Text } from "@fluentui/react-northstar";
import React, { useMemo } from "react";
import { useGroupAdministrationBottom } from "./GroupAdministrationBottom.logic";
import Group from "../../components/Group/Group";
import Pagination from "../../components/Pagination/Pagination";
import { ALL_GROUPS_TAKE } from "../../const/const";
import { GROUP_ADMINISTRATION_BOTTOM_INPUT } from "../../test/testId";
import "./GroupAdministrationBottom.scss";

const GroupAdministrationBottom = () => {
  const logic = useGroupAdministrationBottom();

  const topContent = useMemo(
    () => (
      <Flex hAlign="center" vAlign="center" space="between" fill gap="gap.small">
        <Text content={logic.t("Groups")} weight="semibold" />
        <Input placeholder={logic.t("searchGroup")} icon={<SearchIcon />} inverted iconPosition="start" onChange={logic.handleInput} data-testid={GROUP_ADMINISTRATION_BOTTOM_INPUT} />
      </Flex>
    ),
    []
  );

  const bottomContent = () => (
    <Flex fill column style={{ marginTop: "1rem", minHeight: "425px" }}>
      {logic.sortedGroups.length ? (
        <>
          {logic.sortedGroups.map((g) => (
            <Group key={g.id} {...g} />
          ))}
        </>
      ) : (
        <Flex fill hAlign="center" vAlign="center" style={{ height: "70vh" }}>
          {logic.search ? <Text content={logic.t("NoMatch")} /> : <Text content={logic.t("NoEntries")} />}
        </Flex>
      )}
    </Flex>
  );

  return (
    <Flex column className="groupAdministrationBottom-container">
      {topContent}
      {bottomContent()}
      {logic.allGroups.length > 0 && (
        <Flex hAlign="start" vAlign="center" style={{ padding: "1rem 0" }}>
          <Pagination page={logic.currentPage} total={logic.allGroups.length} take={ALL_GROUPS_TAKE} changePage={logic.handlePage} />
        </Flex>
      )}
    </Flex>
  );
};

export default GroupAdministrationBottom;
