import { SyntheticEvent, useReducer, useEffect, useCallback, useState } from "react";
import { reducer, initialState, IAction, componentIsUnmounting } from "./Configure.reducer";
import { IDisplayMode } from "../../interfaces/IDisplayMode";
import { DropdownProps } from "@fluentui/react-northstar";
import * as microsoftTeams from "@microsoft/teams-js";
import { IContactListDTO } from "../../interfaces/IContact/IContactList";
import { CustomError, ReturnValue, useErrorHandling, useMsTeamsSelector, useTranslate } from "front";
import { createContactList } from "../../apis/actions/contactListAction";
import { translations } from "../../translations";
import { contactListSchemaDTO } from "../../schemas/ContactList.schema";

export const useConfigure = () => {
  // Services
  const t = useTranslate(translations);
  const { channelId, tenantId, userMail } = useMsTeamsSelector("channelId", "tenantId", "userMail");
  const { tryCatchAsync, tryCatch } = useErrorHandling();

  // State
  const [state, dispatch] = useReducer(reducer, { ...initialState });
  const [entityId, setEntityId] = useState<string | undefined>(undefined);

  // UseEffect
  useEffect(() => {
    getEntityId();
    microsoftTeams.pages.config.setValidityState(true);
    return () => componentIsUnmounting();
  }, []);

  const getEntityId = async () => {
    const { result } = await _getEntityId();
    setEntityId(result);
  };

  const _getEntityId = tryCatchAsync(async (): Promise<ReturnValue<string | undefined>> => {
    const { entityId } = await microsoftTeams.pages.getConfig();
    if (!entityId) return { state: false, error: "No entityId was found" };
    return { state: true, result: entityId };
  });

  useEffect(() => {
    microsoftTeams.pages.config.registerOnSaveHandler(
      async (saveEvent) =>
        await tryCatchAsync(_onSubmit)(saveEvent).then((res) => {
          if (res.error) saveEvent.notifyFailure();
        })
    );
  }, [state.displayMode]);

  const handleDropDown = useCallback(
    tryCatch((_: SyntheticEvent<Element, Event> | null, item: DropdownProps): ReturnValue<ReturnValue<void>> => {
      const value = item.value;
      if (!value) return { state: false, error: "No value in dropdown" };
      const displayMode = value === t(IDisplayMode.CARD) ? IDisplayMode.CARD : IDisplayMode.LIST;
      const payload = { ...state, displayMode };
      dispatch({ type: IAction.UPDATE_INPUT, payload });
      return { state: true };
    }),
    []
  );

  const _onSubmit = async (saveEvent: microsoftTeams.pages.config.SaveEvent): Promise<ReturnValue<void>> => {
    const defaultDisplayMode = state.displayMode === IDisplayMode.CARD ? 0 : 1;
    const params: IContactListDTO = {
      channelId,
      tenantId,
      defaultDisplayMode,
      chargebeeSubscriptionId: "",
      isPremium: false,
      createdBy: userMail,
      updatedBy: userMail,
      createdAt: new Date(),
      updatedAt: new Date(),
      isDeleted: false,
    };
    contactListSchemaDTO.parse(params);
    const res = await tryCatchAsync(createContactList)({ tenantId, params });
    if (res.error) return { state: false, error: res.error };
    const teamsTabReturn = await createTeamsTab(res.result!.id);
    if (!teamsTabReturn.state || teamsTabReturn.error) throw new CustomError(res.error || "CannotCreateTeamsTab", "_onSubmit in Configure.logic.tsx");
    saveEvent.notifySuccess();
    return { state: true };
  };

  const createTeamsTab = tryCatchAsync(async (id: string): Promise<ReturnValue<void>> => {
    await microsoftTeams.pages.config.setConfig({
      contentUrl: `https://${window.location.host}/contact/${id}`,
      removeUrl: `https://${window.location.host}/remove/${id}`,
      entityId: id,
      suggestedDisplayName: "Contacts Pro",
    });
    return { state: true };
  });

  const dropDownItems = [t(IDisplayMode.CARD), t(IDisplayMode.LIST)];

  return { state, t, dropDownItems, handleDropDown, entityId };
};
