import { z } from "zod";
import { idSchema } from "./Id.schema";
import { ISchemaError } from "interfaces/ISchemaError/ISchemaError";
import { internetSchema } from "./Internet.schema";
import { phonesSchema } from "./Phones.schema";
import { addressSchema } from "./Address.schema";
import { createdBySchema } from "./CreatedBy.schema";
import { updatedBySchema } from "./updatedBy.schema";
import { createdAtSchema } from "./CreatedAt.schema";
import { updatedAtSchema } from "./UpdatedAt.schema";
import { CONTACT_NOTES_MAX_LENGTH, CONTACT_PROPERTIES_MAX_LENGTH } from "../const/const";

export const contactSchema = z.object({
  id: idSchema,
  name: z
    .string({ invalid_type_error: ISchemaError.GROUP_NAME_INVALID_TYPE })
    .min(1, { message: ISchemaError.NO_CONTACT_NAME })
    .max(CONTACT_PROPERTIES_MAX_LENGTH, { message: ISchemaError.CONTACT_NAME_TOO_LONG }),
  contactListId: idSchema,
  groupId: z.string({ invalid_type_error: ISchemaError.GROUP_ID_INVALID_TYPE }).default(""),
  picture: z.string({ invalid_type_error: ISchemaError.PICTURE_INVALID_TYPE }).default(""),
  jobTitle: z.string({ invalid_type_error: ISchemaError.JOB_TITLE_INVALID_TYPE }).max(CONTACT_PROPERTIES_MAX_LENGTH, { message: ISchemaError.JOB_TITLE_TOO_LONG }).default(""),
  company: z.string({ invalid_type_error: ISchemaError.COMPANY_INVALID_TYPE }).max(CONTACT_PROPERTIES_MAX_LENGTH, { message: ISchemaError.COMPANY_TOO_LONG }).default(""),
  department: z.string({ invalid_type_error: ISchemaError.DEPARTMENT_INVALID_TYPE }).max(CONTACT_PROPERTIES_MAX_LENGTH, { message: ISchemaError.COMPANY_TOO_LONG }).default(""),
  internet: internetSchema,
  phones: phonesSchema,
  addresses: z.array(addressSchema).default([]),
  notes: z.string({ invalid_type_error: ISchemaError.NOTES_INVALID_TYPE }).max(CONTACT_NOTES_MAX_LENGTH, { message: ISchemaError.NOTES_TOO_LONG }).default(""),
  isDisabled: z.boolean({ invalid_type_error: ISchemaError.IS_DISABLED_INVALID_TYPE }).nullable(),
  createdBy: createdBySchema,
  updatedBy: updatedBySchema,
  createdAt: createdAtSchema,
  updatedAt: updatedAtSchema,
  objectType: z.string().default("ContactEntity"),
  isDeleted: z.boolean({ invalid_type_error: ISchemaError.IS_DELETED_INVALID_TYPE }),
});
