import { useMsTeamsSelector, usePermissionsSelector, usePremiumSelector } from "front";
import { useContactListCache } from "../../hooks/useQuery/useContactListCache";
import { useGroupsCache } from "../../hooks/useQuery/useGroupsCache";
import { useUsersCache } from "../../hooks/useQuery/useUsersCache";
import { useEffect } from "react";
import { setLimitation, useLimitationSelector } from "../../redux/reducer/LimitationReducer";
import { useDispatch } from "react-redux";
import { IAppDispatch } from "../../redux/store";
import { getLimitation } from "../../apis/actions/contactListAction";

export const useContact = () => {
  const dispatchCtx = useDispatch<IAppDispatch>();

  const { subscription } = useLimitationSelector("subscription", "maxContact");

  const { queryContactList } = useContactListCache();
  const { queryUsers } = useUsersCache();
  const { queryGroups } = useGroupsCache();
  const { isPremium } = usePremiumSelector("isPremium");
  const { tenantId } = useMsTeamsSelector("tenantId");
  const { loaded, userPerm } = usePermissionsSelector("loaded", "userPerm");

  useEffect(() => {
    if (subscription) return;
    getLimitationAsync();
  }, [subscription]);

  const getLimitationAsync = async () => {
    const limitation = await getLimitation(tenantId);
    dispatchCtx(setLimitation(limitation));
  };

  return {
    isLoading: queryContactList.isLoading || queryGroups.isLoading,
    permsAreLoading: !loaded || !userPerm.id || !subscription,
    contactAreLoading: queryUsers.isLoading,
    error: !!queryContactList.error || !!queryUsers.error,
    isPremium,
  };
};
