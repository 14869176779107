import { Flex, Input, Text, TextArea } from "@fluentui/react-northstar";
import React from "react";
import { translations } from "../../../translations";
import { useTranslate } from "front";

interface IFieldDetail {
  name: string;
  val: string;
  link?: string;
}

const FieldDetail = (props: IFieldDetail) => {

  const t = useTranslate(translations)

  const onClickLink = () => {
    if (!props.link) return;
    window.open(props.link);
  };

  return (
    <Flex fill gap={"gap.medium"} vAlign={"center"}>
      <Flex fill gap="gap.small" vAlign={"center"} style={{ margin: 5 }}>
        <Flex.Item size="size.quarter">
          <Text content={t(props.name)} />
        </Flex.Item>
        <Flex.Item grow>
          <Input
            fluid
            readOnly={true}
            value={props.val}
            input={{
              styles: {
                cursor: !!props.link ? "pointer" : "default",
                color: !!props.link ? "rgb(166, 167, 220) !important" : undefined,
              },
            }}
            onClick={onClickLink}
          />
        </Flex.Item>
      </Flex>
    </Flex>
  );
};

const TextAreaDetail = (props: IFieldDetail) => {
  const t = useTranslate(translations)
  return (
    <Flex fill gap={"gap.medium"} vAlign={"center"}>
      <Flex id="main_textarea" fill gap="gap.small" vAlign={"start"}>
        <Flex.Item size="size.quarter">
          <Text content={t(props.name)} />
        </Flex.Item>
        <Flex.Item grow>
          <TextArea fluid styles={{ height: "120px", caretColor: "transparent" }} value={props.val} />
        </Flex.Item>
      </Flex>
    </Flex>
  );
};

export { FieldDetail, TextAreaDetail };
