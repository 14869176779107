import { useState, useRef, useEffect } from "react";
export const useCarrousel = (props) => {
    // States
    const [currentIdx, setCurrentIdx] = useState(0);
    const [take, setTake] = useState(1);
    const [slideMax, setSlideMax] = useState(0);
    // Refs
    const initialTake = useRef(0);
    const sliderContainerRef = useRef(null);
    const slidePanelWidth = useRef(0);
    const handleBack = () => {
        if (!currentIdx)
            return;
        setCurrentIdx((prev) => prev - 1);
    };
    const handleNext = () => {
        if (currentIdx === slideMax)
            return;
        setCurrentIdx((prev) => prev + 1);
        setTake((t) => t + initialTake.current);
    };
    useEffect(() => {
        const items = props.children;
        if (!items)
            return;
        const slidePanel = document.querySelector(".slide");
        if (!sliderContainerRef.current || !slidePanel)
            return;
        slidePanelWidth.current = slidePanel.clientWidth;
        const numberOfCards = sliderContainerRef.current.clientWidth / (slidePanel.clientWidth + props.spaceBetweenItems);
        initialTake.current = Math.floor(numberOfCards) || 1;
        setTake(initialTake.current);
        const slideLimit = Math.ceil(items.length / initialTake.current) - 1;
        setSlideMax(slideLimit);
    }, [props.children]);
    return { handleBack, handleNext, slidePanelWidth: slidePanelWidth.current, sliderContainerRef, currentIdx, initialTake: initialTake.current, take, slideMax };
};
