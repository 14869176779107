import { AxiosConfig, guidSchema, ISubscription, ReturnValue, tryCatch } from "front";
import { IContactList, IContactListDTO } from "interfaces/IContact/IContactList";
import * as microsoftTeams from "@microsoft/teams-js";
import { z } from "zod";
import { contactListSchema, contactListSchemaDTO } from "../../schemas/ContactList.schema";

const ROUTE = "/contact-lists/";

const _createContactList = z
  .function()
  .args(guidSchema, contactListSchemaDTO)
  .returns(
    z.promise(
      z.object({
        state: z.boolean(),
        error: z.string().optional(),
        variables: z.object({ tenantId: guidSchema, params: contactListSchemaDTO }),
        result: contactListSchema,
      })
    )
  )
  .implement(async (tenantId, params) => {
    const req = AxiosConfig.instance.post(`${ROUTE}${tenantId}`, params);
    AxiosConfig.updateHeadersToTrack("");
    const res = await req;
    return { state: true, variables: { tenantId, params }, result: res.data };
  });

const updateContactListAction = async (data: { contactListId: string; params: IContactList }): Promise<ReturnValue<void>> => {
  const { contactListId, params } = data;
  const req = AxiosConfig.instance.put(`${ROUTE}${contactListId}`, params);
  AxiosConfig.updateHeadersToTrack("");
  await req;
  return { state: true, variables: data };
};

const getContactListAction = async (): Promise<ReturnValue<IContactList>> => {
  const { entityId } = await microsoftTeams.pages.getConfig();
  const req = AxiosConfig.instance.get(`${ROUTE}${entityId}`);
  AxiosConfig.updateHeadersToTrack("");
  const res = await req;
  return { state: true, result: res.data };
};

const checkContactListId = async (contactListId: string) => {
  const req = AxiosConfig.instance.get(`${ROUTE}${contactListId}/check`);
  AxiosConfig.updateHeadersToTrack("");
  const res = await req;
  return res.data;
};

const deleteContactListId = async (contactListId: string): Promise<ReturnValue<void>> => {
  const req = AxiosConfig.instance.delete(`${ROUTE}${contactListId}`);
  AxiosConfig.updateHeadersToTrack("");
  await req;
  return { state: true, variables: contactListId };
};

const getLimitationAction = async (tenantId: string): Promise<{ subscription: ISubscription; maxContact: number }> => {
  const req = AxiosConfig.instance.get(`${ROUTE}/tenantId/${tenantId}/limitation`);
  AxiosConfig.updateHeadersToTrack("");
  const res = await req;
  return res.data;
};

export const createContactList = async (data: { tenantId: string; params: IContactListDTO }): Promise<ReturnValue<IContactList>> => await _createContactList(data.tenantId, data.params);

export const updateContactList = tryCatch(updateContactListAction);
export const getContactList = tryCatch(getContactListAction);
export const checkContactList = tryCatch(checkContactListId);
export const deleteContactList = tryCatch(deleteContactListId);
export const getLimitation = tryCatch(getLimitationAction);
