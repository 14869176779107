import { IDisplayMode } from "../interfaces/IDisplayMode";

export const getDisplayMode = (defaultDisplayMode: 0 | 1 | 2): IDisplayMode => {
  const map = {
    0: IDisplayMode.CARD,
    1: IDisplayMode.LIST,
    2: IDisplayMode.GROUP,
  };

  return map[defaultDisplayMode];
};
