import React from "react";

const NoContentIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 252 182.2" style={{ width: 250, height: 250 }}>
      <linearGradient
        id="SVGID_1_"
        gradientUnits="userSpaceOnUse"
        x1="136"
        y1="-3.2"
        x2="136"
        y2="68"
        gradientTransform="matrix(1 0 0 -1 0 184)"
      >
        <stop offset="0" stop-color="#6264a7"></stop>
        <stop offset="1" stop-color="#8b8cc7"></stop>
      </linearGradient>
      <path
        d="M179.7 182.2H79.2c-4.8 0-8-3.9-7.1-8.7l10-55.3c.9-4.8 5.4-8.7 10.2-8.7h100.5c4.8 0 8 3.9 7.1 8.7l-10 55.3a11 11 0 01-10.2 8.7z"
        fill="url(#SVGID_1_)"
      ></path>
      <path transform="rotate(-62.2 200 76.6)" fill="#c8c6c4" d="M156.8 40.6h86.4v72.1h-86.4z"></path>
      <path transform="rotate(-62.2 203 71)" fill="#edebe9" d="M170.7 39.2h64.7v63.5h-64.7z"></path>
      <path
        d="M208.6 47c-4.8-1.5-10.5 1.9-12.7 7.7-1.8 5 2.5 8.7.5 12.8-1.9 4.1-8.1 4.3-8.5 9.6-.4 5.3 2.3 7.3-1.1 13.4l6.4 3.4s0-4.1 2.8-6 9.3-5.3 7.8-10.1c-1.4-4.9 1.5-8.3 3.2-9.4s5-4.7 6.8-8.8c1.9-4.2.1-10.9-5.2-12.6z"
        fill="#e8d441"
      ></path>
      <path
        d="M214.8 62.1c4.5 2.6 4.2 8.7 1.2 12.6-2.5 3.3-7.4 1.9-9.2 4.8-1.8 3 1.3 8.4-2.6 10.5-3.9 2.1-6.7 1.2-8.4 5.5l-5.8-3.1s2.6-1.6 2.1-4.3c-.6-2.7-.7-8.8 3.5-9.7 4.2-1 5.1-4.4 5-6s-.4-5 2.4-8.3c2.8-3.3 8.9-3.6 11.8-2z"
        fill="#fff"
      ></path>
      <path
        d="M194.4 92.1s11.2-19.9 16.6-25"
        fill="none"
        stroke="#e8d441"
        stroke-width="1.7"
        stroke-linecap="round"
        stroke-miterlimit="10"
      ></path>
      <path d="M201.9 95.5l-16.6-8.8c-.7-.4-1.7.1-1.8 1l-1.4 8.7 13.7 7.2 6.4-6.1c.6-.5.5-1.6-.3-2z" fill="#b3b0ad"></path>
      <linearGradient
        id="SVGID_2_"
        gradientUnits="userSpaceOnUse"
        x1="89.6"
        y1="108.9"
        x2="119.9"
        y2="120.5"
        gradientTransform="matrix(1 0 0 -1 0 184)"
      >
        <stop offset="0" stop-color="#e1dfdd"></stop>
        <stop offset=".2" stop-color="#eae8e7"></stop>
        <stop offset=".6" stop-color="#f6f5f4"></stop>
        <stop offset="1" stop-color="#faf9f8"></stop>
      </linearGradient>
      <path fill="url(#SVGID_2_)" d="M93.3 0l86.8 4.6-5.9 111.8-86.8-4.6z"></path>
      <path
        d="M154 23.6c-.3-.5-.9-.8-1.5-.7l-10.1 1.3c-.5.1-1 .4-1.2.9-.2.5-.2 1.1.1 1.6L143 29l-11 7-5.5-6.9-16.3 10.4 2.2 3.4 13.2-8.4 5.5 6.9 14.3-9.1 1.9 2.5c.3.4.7.7 1.2.7h.2c.6-.1 1-.4 1.2-.9L154 25c.3-.5.3-1 0-1.4z"
        fill="#fae969"
      ></path>
      <path fill="#e1dfdd" d="M101.5 86.1l.3-4.7 57.7 3 1.8-47.5 4.7.3-2.1 52.2z"></path>
      <path
        fill="#e1dfdd"
        d="M143.9 45.5l12.2.7-1.8 33-12.2-.6zM125.3 51.7l12.2.7-1.4 25.8-12.2-.7zM106.4 62.9l12.2.6-.8 13.7-12.2-.6z"
      ></path>
      <path fill="#e2e2f6" d="M90.5 42.4l25.8 76.5L34 146.6 0 45.8l57.9-19.5z"></path>
      <path fill="#bdbde6" d="M90.5 42.4l-24.3 8.2-8.3-24.3z"></path>
      <path transform="rotate(-18.7 67 71.4)" fill="#c3c3e1" d="M50.4 68.9h33.2v4.9H50.4z"></path>
      <path transform="rotate(-18.7 59.7 87)" fill="#c3c3e1" d="M31.2 84.6h57v4.9h-57z"></path>
      <path transform="rotate(-18.7 63.7 98.8)" fill="#c3c3e1" d="M35.1 96.4h57v4.9h-57z"></path>
      <path transform="rotate(-18.7 63 59.5)" fill="#c3c3e1" d="M46.4 57.1h33.2V62H46.4z"></path>
      <path opacity=".8" fill="#e8d441" d="M40.4 64.6l5.5 16.5-16.4 5.4L24 70.2l16.4-5.6"></path>
      <path
        fill="#a6a7dc"
        d="M179.7 182.2H79.2c-4.8 0-9.3-3.9-10.1-8.6l-9.2-55.2c-.8-4.8 2.4-8.6 7.2-8.6h100.5c4.8 0 9.3 3.9 10.1 8.6l9.2 55.2a7.1 7.1 0 01-7.2 8.6z"
      ></path>
      <path
        fill="#a6a7dc"
        d="M89.2 114.4l-29.1 5.5-2.3-13.8c-.8-4.6 2.3-8.3 6.9-8.3h13.4c4.6 0 8.9 3.7 9.7 8.3l1.4 8.3z"
      ></path>
    </svg>
  );
};

export default NoContentIcon;
