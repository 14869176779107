export enum AppView {
  Contact = 0,
  Configure = 1,
  Widget = 2,
  WidgetDetail = 3,
  WidgetConfigure = 4,
  Remove = 5,
  Permissions = 6,
  GroupAdministration = 7,
}
