import { DropdownProps } from "@fluentui/react-northstar";
import { openModal, useTranslate } from "front";
import { useContactListCache } from "../../../../hooks/useQuery/useContactListCache";
import { IDisplayMode } from "../../../../interfaces/IDisplayMode";
import { SyntheticEvent, useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { translations } from "../../../../translations";

export const useSettings = () => {
  const t = useTranslate(translations);
  const dispatch = useDispatch();
  const { queryContactList, updateContactListMutate } = useContactListCache();

  const getInitialDisplayMode = useMemo(() => {
    return queryContactList.data?.defaultDisplayMode === 2 ? 0 : queryContactList.data?.defaultDisplayMode ?? 0;
  }, [queryContactList.data?.defaultDisplayMode]);

  // State
  const [displayMode, setDisplayMode] = useState<0 | 1 | 2>(getInitialDisplayMode);

  const onClose = useCallback(() => {
    dispatch(openModal(undefined));
  }, []);

  const updateContact = () => {
    updateContactListMutate(
      { contactListId: queryContactList.data?.id, params: { ...queryContactList.data, defaultDisplayMode: displayMode } },
      {
        onSuccess: () => {
          dispatch(openModal(undefined));
        },
      }
    );
  };

  const handleDropDown = useCallback((_: SyntheticEvent | null, item: DropdownProps): void => {
    const value = item.value;
    if (!value) return;
    const displayMode = value === t(IDisplayMode.CARD) ? 0 : 1;
    setDisplayMode(displayMode);
  }, []);

  const convertNumToString = () => {
    const map = {
      0: IDisplayMode.CARD,
      1: IDisplayMode.LIST,
      2: IDisplayMode.GROUP,
    };
    return t(map[displayMode]);
  };

  const dropDownItems = [t(IDisplayMode.CARD), t(IDisplayMode.LIST)];

  return { onClose, updateContact, dropDownItems, handleDropDown, convertNumToString, displayMode };
};
