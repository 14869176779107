import { useDialogsContainer } from "./DialogsContainer.logic";
import React, { memo } from "react";

const DialogsContainer = memo(() => {
  const logic = useDialogsContainer();

  return (
    <>
      {logic.displayDialogs.map((item) => (
        <div key={item.key}>{item.show && item.content}</div>
      ))}
    </>
  );
});

export default DialogsContainer;
