import { Avatar, Button, CloseIcon, Flex, Text } from "@fluentui/react-northstar";
import React, { useState } from "react";
import "./PillButton.scss";
import { shouldTruncate } from "front";

const PillButton = (props: { disabled: boolean; name: string; icon?: { show: boolean; picture: string }; onChange: any }) => {
  const [show, setShow] = useState<boolean>(true);

  const handleClose = () => {
    props.onChange();
    setShow(false);
  };

  return (
    <Flex vAlign="center" hAlign="center" className="contactDialog-group-pill" style={{ display: show ? "flex" : "none" }}>
      {props.icon?.show && <Avatar image={props.icon.picture} name={props.name} size="small" style={{ transform: "translateX(-8px)", borderRadius: "50%" }} />}
      <Text content={shouldTruncate(props.name, 15) ? `${props.name.substring(0, 15)}...` : props.name} disabled={props.disabled} />
      <Button icon={<CloseIcon size="small" />} iconOnly text disabled={props.disabled} onClick={handleClose} />
    </Flex>
  );
};

export default PillButton;
