import { QUERY_KEY_PARTICIPANTS } from "../../../../const/queryKey";
import { setModalWithData, useAppConfigurationSelector, useModalSelector, useMsTeamsSelector, useTranslate } from "front";
import { useGroupsCache } from "../../../../hooks/useQuery/useGroupsCache";
import { useUsersCache } from "../../../../hooks/useQuery/useUsersCache";
import { queryClient } from "../../../../index";
import { IContact } from "../../../../interfaces/IContact/IContact";
import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { IAppDispatch } from "../../../../redux/store";
import { translations } from "../../../../translations";

export const useRemoveContactFromGroup = () => {
  const t = useTranslate(translations);
  const { data } = useModalSelector("data");
  const { appId } = useAppConfigurationSelector("appId");
  const { tenantId } = useMsTeamsSelector("tenantId");

  const { queryUsers, mutateEditContact } = useUsersCache();
  const dispatchCtx = useDispatch<IAppDispatch>();

  const getContact = useMemo(() => {
    if (!data.contactId || !queryUsers.data) return;
    const contact = queryUsers.data.contacts.find((u) => u.id === data.contactId);
    if (!contact) return;
    return contact;
  }, [data.contactId]);

  const { mutateDeleteGroup } = useGroupsCache();

  const handleDelete = useCallback(async () => {
    if (!getContact) return;
    const initialGroups = getContact?.groupId.split(", ") ?? [];
    const filteredGroups = initialGroups.filter((ig) => ig !== data.groupId);

    const params = {
      contactListId: appId,
      contactId: data.contactId,
      contact: { ...getContact, groupId: filteredGroups.join(", ") },
    };

    await mutateEditContact.mutateAsync(params);

    queryClient.setQueryData([QUERY_KEY_PARTICIPANTS, appId, data.groupId, tenantId], (oldData: IContact[] | undefined) => {
      if (!oldData) return [];
      return oldData.filter((od) => od.id !== data.contactId);
    });

    handleClose();
  }, []);

  const handleClose = useCallback(() => dispatchCtx(setModalWithData({ isOpen: undefined, data: undefined })), []);

  return { t, handleDelete, handleClose, isLoading: mutateDeleteGroup.isLoading, name: getContact?.name ?? "" };
};
