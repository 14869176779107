import React, { useEffect, useState } from "react";
import { IContact } from "../../interfaces/IContact/IContact";
import { openModal, openPremiumDialog, setModalWithData, useMsTeamsSelector, usePermissionsSelector, usePremiumSelector, useTranslate } from "front";
import { IDialogs } from "../../interfaces/IDialog/IDialogs";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { setUserToDelete } from "../../redux/reducer/ContactListReducer";
import { IAppDispatch } from "../../redux/store";
import { translations } from "../../translations";
import { generateGUID } from "../../utils/generateGUID";
import { useUsersCache } from "../../hooks/useQuery/useUsersCache";
import { Flex, Text, Tooltip } from "@fluentui/react-northstar";
import { appInsightInstance } from "../../services/TelemetryService/TelemetryService";

export const useUserCard = (props: IContact & { idx: number; removeFromGroup?: (id: string) => void }) => {
  // Services
  const t = useTranslate(translations);
  const dispatch = useDispatch<IAppDispatch>();
  const { queryUsers } = useUsersCache();

  const { isOnMobile } = useMsTeamsSelector("isOnMobile");
  const { userPerm } = usePermissionsSelector("userPerm");
  const { isPremium } = usePremiumSelector("isPremium");

  useEffect(() => {
    if (!props.removeFromGroup) return;
    const item = { key: generateGUID(), content: t("RemoveFromGroup"), onClick: removeFromGroup, disabled: false };
    setMenu((prev) => [...prev, item]);
  }, []);

  const seeContact = useCallback(() => {
    if (props.isDisabled) return;
    dispatch(setModalWithData({ isOpen: IDialogs.CONTACT, data: { edit: false, contact: { id: props.id } } }));
  }, [props]);

  const editContact = useCallback(() => {
    if (props.isDisabled) return;
    dispatch(setModalWithData({ isOpen: IDialogs.CONTACT, data: { edit: true, contact: { id: props.id } } }));
  }, [props]);

  const deleteUser = useCallback(() => {
    dispatch(openModal(IDialogs.DELETE_CONTACT));
    dispatch(setUserToDelete({ contact: props, isLonely: queryUsers.data?.contacts.length === 1 }));
  }, []);

  const removeFromGroup = useCallback(() => props.removeFromGroup!(props.id), []);

  const openPremium = useCallback(() => {
    dispatch(openPremiumDialog(appInsightInstance));
  }, []);

  const [menu, setMenu] = useState([
    { key: generateGUID(), content: t("See"), onClick: seeContact, disabled: props.isDisabled },
    {
      key: generateGUID(),
      disabled: !userPerm.perm.update || props.isDisabled,
      onClick: editContact,
      content: (
        <>
          {userPerm.perm.update ? (
            <Flex vAlign="center" hAlign="start" gap="gap.small">
              <Text content={t("Edit")} />
            </Flex>
          ) : (
            <Tooltip
              content={t("NoEditPerm")}
              pointing
              trigger={
                <Flex vAlign="center" hAlign="start" gap="gap.small">
                  <Text content={t("Edit")} />
                </Flex>
              }
            />
          )}
        </>
      ),
    },
    {
      key: generateGUID(),
      disabled: !userPerm.perm.delete,
      onClick: deleteUser,
      content: (
        <>
          {userPerm.perm.delete ? (
            <Flex vAlign="center" hAlign="start" gap="gap.small">
              <Text content={t("Delete")} />
            </Flex>
          ) : (
            <Tooltip
              content={t("NoDeletePerm")}
              pointing
              trigger={
                <Flex vAlign="center" hAlign="start" gap="gap.small">
                  <Text content={t("Delete")} />
                </Flex>
              }
            />
          )}
        </>
      ),
    },
  ]);

  return { menu, editContact, seeContact, isOnMobile, t, isPremium, openPremium };
};
