import { getContactImportTemplate } from "../../../../apis/actions/contactAction";
import { openModal, openPremiumDialog, setErrorMessage, useMsTeamsSelector, useTranslate } from "front";
import { useUsersCache } from "../../../../hooks/useQuery/useUsersCache";
import { useCallback, useEffect, useReducer, useRef } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { IAppDispatch, ReduxStoreState } from "../../../../redux/store";
import { translations } from "../../../../translations";
import { reducer, initialState, IAction } from "./ImportContact.reducer";
import { useContactPermissions } from "../../../../hooks/useContactPermissions/useContactPermissions";
import { appInsightInstance } from "../../../../services/TelemetryService/TelemetryService";

export const useImportContact = () => {
  // Services
  const dispatchCtx = useDispatch<IAppDispatch>();
  const t = useTranslate(translations);
  const { appId } = useSelector((s: ReduxStoreState) => s.appConfiguration);
  const msTeamsContext = useMsTeamsSelector("channelId", "tenantId", "userMail", "groupId");
  const { canAddContact } = useContactPermissions();

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<{ fileInput: File }>();

  const { mutateImportContact } = useUsersCache();

  // State
  const [state, dispatch] = useReducer(reducer, { ...initialState });

  // Ref
  const inpRef = useRef<HTMLInputElement>(null);

  const onClose = useCallback(() => dispatchCtx(openModal(undefined)), []);

  const handleDownloadModel = async () => {
    const { result } = await getContactImportTemplate();
    const url = window.URL.createObjectURL(new Blob([result]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "contacts-template.xlsx");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  const openFiles = () => {
    const ref = inpRef.current;
    if (!ref) return;
    ref.click();
  };

  useEffect(() => {
    handleSubmit(onSubmit)();
  }, [watch("fileInput")]);

  const onSubmit = async (data: { fileInput: File }) => {
    try {
      if (!data.fileInput) throw "No File";
      const { channelId, userMail, groupId, tenantId } = msTeamsContext;
      const formData = new FormData();
      formData.append("contactListId", appId);
      formData.append("email", userMail);
      formData.append("tenantId", tenantId);
      formData.append("groupId", groupId);
      formData.append("channelId", channelId);
      formData.append("fileExcel", data.fileInput);
      await mutateImportContact.mutateAsync(formData);
      dispatch({ type: IAction.SET_STATE, payload: { ...state, state: "success" } });
    } catch (error) {
      dispatch({ type: IAction.SET_STATE, payload: { ...state, state: "error" } });
    }
  };

  const fileIsExcelFormat = (file: File | undefined): any => {
    if (!file) return t("OnlyExcelFormat");
    const excelTypes = ["application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];
    return excelTypes.includes(file.type) || t("OnlyExcelFormat");
  };

  const openPremium = useCallback(() => {
    dispatchCtx(openPremiumDialog(appInsightInstance));
  }, []);

  return {
    ...state,
    t,
    onClose,
    handleSubmit,
    handleDownloadModel,
    inpRef,
    openFiles,
    onSubmit,
    control,
    fileIsExcelFormat,
    errors,
    msTeamsContext,
    isLoading: mutateImportContact.isLoading,
    canAddContact: canAddContact,
    openPremium,
  };
};
