import { useSelector } from "react-redux";
import { ReduxStoreState } from "../../redux/store";
import { IDialogs } from "../../interfaces/IDialog/IDialogs";
import Settings from "./Dialogs/Settings/Settings";
import DeleteContact from "./Dialogs/DeleteContact/DeleteContact";
import React, { useMemo } from "react";
import Group from "./Dialogs/Group/Group";
import ImportContact from "./Dialogs/ImportContact/ImportContact";
import ExportContact from "./Dialogs/ExportContact/ExportContact";
import NotSameTenant from "./Dialogs/NotSameTenant/NotSameTenant";
import ContactDialog from "./Dialogs/ContactDialog/ContactDialog";
import DeleteGroup from "./Dialogs/DeleteGroup/DeleteGroup";
import CopyGroup from "./Dialogs/CopyGroup/CopyGroup";
import { UpgradeDialog, useConfigurationSelector } from "front";
import RemoveContactFromGroup from "./Dialogs/RemoveContactFromGroup copy/RemoveContactFromGroup";
import { appInsightInstance } from "services/TelemetryService/TelemetryService";
import { AboutDialog } from "front";

export const useDialogsContainer = () => {
  const { isOpen } = useSelector((s: ReduxStoreState) => s.modal);
  const { data: config } = useConfigurationSelector("data");

  const displayDialogs = useMemo(
    () => [
      {
        key: IDialogs.GROUP,
        show: isOpen === IDialogs.GROUP,
        content: <Group />,
      },
      {
        key: IDialogs.DELETE_GROUP,
        show: isOpen === IDialogs.DELETE_GROUP,
        content: <DeleteGroup />,
      },
      {
        key: IDialogs.COPY_GROUP,
        show: isOpen === IDialogs.COPY_GROUP,
        content: <CopyGroup />,
      },
      {
        key: IDialogs.REMOVE_FROM_GROUP,
        show: isOpen === IDialogs.REMOVE_FROM_GROUP,
        content: <RemoveContactFromGroup />,
      },
      {
        key: IDialogs.ABOUT,
        show: isOpen === IDialogs.ABOUT,
        content: <AboutDialog />,
      },
      {
        key: IDialogs.UPGRADE,
        show: isOpen === IDialogs.UPGRADE,
        content: <UpgradeDialog appId={config.adminCenterAppId} appInsight={appInsightInstance} />,
      },
      {
        key: IDialogs.SETTINGS_CONTACT,
        show: isOpen === IDialogs.SETTINGS_CONTACT,
        content: <Settings />,
      },
      {
        key: IDialogs.DELETE_CONTACT,
        show: isOpen === IDialogs.DELETE_CONTACT,
        content: <DeleteContact />,
      },
      {
        key: IDialogs.IMPORT_CONTACT,
        show: isOpen === IDialogs.IMPORT_CONTACT,
        content: <ImportContact />,
      },
      {
        key: IDialogs.EXPORT_CONTACT,
        show: isOpen === IDialogs.EXPORT_CONTACT,
        content: <ExportContact />,
      },
      {
        key: IDialogs.NOT_SAME_TENANT,
        show: isOpen === IDialogs.NOT_SAME_TENANT,
        content: <NotSameTenant />,
      },
      {
        key: IDialogs.CONTACT,
        show: isOpen === IDialogs.CONTACT,
        content: <ContactDialog />,
      },
    ],
    [isOpen]
  );

  return { displayDialogs };
};
