import { findKeyPath } from "front";
import { FieldValues, RegisterOptions } from "react-hook-form";
import { getValueFromPath } from "./getValueFromPath";
import { IFormInput } from "../interfaces/IFormInput/IFormInput";
import { IContact } from "../interfaces/IContact/IContact";
import { NO_INPUT_VALUE } from "../const/const";

export const generateFormField = <T extends FieldValues>(
  fieldName: string,
  initialObject: Omit<IContact, "updatedAt" | "createdAt">,
  icon: JSX.Element,
  rules: Omit<RegisterOptions<T, any>, "disabled" | "valueAsNumber" | "valueAsDate" | "setValueAs">,
  fill: boolean,
  t: any
): IFormInput => {
  const keypath = findKeyPath(initialObject, fieldName);
  return {
    formKey: keypath,
    placeholder: t(fieldName),
    icon,
    value: getValueFromPath(keypath, initialObject) ?? NO_INPUT_VALUE,
    fill,
    rules: rules,
  };
};
