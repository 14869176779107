import { Flex } from "@fluentui/react-northstar";
import React, { ReactNode, memo } from "react";

const FlexWrapper = ({ children }: { children: ReactNode }) => {
  return (
    <Flex fill vAlign="center" hAlign="center" style={{ position: "absolute", top: 90, left: 0, height: "calc(100vh - 90px)", padding: "0 2rem" }} column gap="gap.medium">
      {children}
    </Flex>
  );
};

export default FlexWrapper;
