import { Button, Divider, EditIcon, Flex, Text, Tooltip, TrashCanIcon } from "@fluentui/react-northstar";
import { IGroup } from "interfaces/IGroup/IGroup";
import React, { memo } from "react";
import { useGroup } from "./Group.logic";
import { shouldTruncate } from "front";

const propsAreEqual = (prevProps: Readonly<IGroup>, nextProps: Readonly<IGroup>): boolean => prevProps.name === nextProps.name;

const Group = memo((props: IGroup) => {
  const logic = useGroup(props);
  return (
    <Flex fill column>
      <Divider />
      <Flex hAlign="center" vAlign="center" space="between">
        <Tooltip content={props.name} trigger={<Text content={shouldTruncate(props.name, 20) ? `${props.name.substring(0, 20)}...` : props.name} />} />
        <Flex hAlign="center" vAlign="center">
          {!logic.userPerm.perm.update ? (
            <Tooltip
              content={logic.t("NoEditPerm")}
              trigger={<Button icon={<EditIcon />} iconOnly text onClick={logic.handleEdit} data-testid="group-edit-btn" disabled={!logic.userPerm.perm.update} />}
            />
          ) : (
            <Button icon={<EditIcon />} iconOnly text onClick={logic.handleEdit} data-testid="group-edit-btn" disabled={!logic.userPerm.perm.update} />
          )}

          {!logic.userPerm.perm.delete ? (
            <Tooltip
              content={logic.t("NoDeletePerm")}
              trigger={<Button icon={<TrashCanIcon />} iconOnly text onClick={logic.handleDelete} data-testid="group-delete-btn" disabled={!logic.userPerm.perm.delete} />}
            />
          ) : (
            <Button icon={<TrashCanIcon />} iconOnly text onClick={logic.handleDelete} data-testid="group-delete-btn" disabled={!logic.userPerm.perm.delete} />
          )}
        </Flex>
      </Flex>
    </Flex>
  );
}, propsAreEqual);

export default Group;
