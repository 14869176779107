import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IContact } from "interfaces/IContact/IContact";

const initialState: {
  id: string;
  deleteUser: {
    contact: IContact | undefined;
    isLonely: boolean;
  };
} = {
  id: "",
  deleteUser: {
    contact: undefined,
    isLonely: false,
  },
};

const contactListSlice = createSlice({
  name: "contactList",
  initialState: initialState,
  reducers: {
    setContactListId(state, action: PayloadAction<string>) {
      state.id = action.payload;
    },
    setUserToDelete(state, action: PayloadAction<{ contact: IContact | undefined; isLonely: boolean }>) {
      state.deleteUser = action.payload;
    },
  },
});

export const { setContactListId, setUserToDelete } = contactListSlice.actions;
export default contactListSlice.reducer;
