import { ChangeEvent, useEffect, useRef, useState } from "react";
import { UseFormSetValue } from "react-hook-form";
import { translations } from "../../translations";
import { IError, setErrorMessage, useTranslate } from "front";
import { useDispatch } from "react-redux";
import { IAppDispatch } from "../../redux/store";
import React from "react";
import { IContact } from "../../interfaces/IContact/IContact";
import Compressor from "compressorjs";
import { IContactDialogRef } from "../../interfaces/IContactDialogRef/IContactDialogRef";
import { v4 as uuidv4 } from "uuid";

export const useContactImageLoader = (props: {
  setValue: UseFormSetValue<IContact>;
  readOnly: boolean;
  defaultImage: string | undefined;
  name: string | undefined;
  onRef?: (ref: IContactDialogRef) => void;
}) => {
  // States
  const [imgSrc, setImgSrc] = useState<any>();
  const dispatch = useDispatch<IAppDispatch>();

  const t = useTranslate(translations);

  // Refs
  const inpRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!props.onRef) return;
    props.onRef({
      resetImage: resetImgSrc,
    });
  }, []);

  useEffect(() => {
    if (!imgSrc) return;
    props.setValue("picture", imgSrc as never, { shouldDirty: true });
  }, [imgSrc]);

  const openFiles = () => {
    const ref = inpRef.current;
    if (!ref) return;
    ref.click();
  };

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const file = e.target.files[0];
    if (!file) return;
    const isImage = fileIsImageFormat(file);
    if (!isImage) {
      const error : IError = {
        id: uuidv4(),
        message: t("OnlyImageFormat"),
        error: {}
      }
      dispatch(setErrorMessage(error));
      return;
    }
    if (file.size > 5 * 1024 * 1204) {
      const error : IError = {
        id: uuidv4(),
        message: t("FileExceedSize", { file: Math.floor(file.size / 500000) }),
        error: {}
      }
      dispatch(setErrorMessage(error));
      return;
    }

    new Compressor(file, {
      quality: 0.6, // Définissez la qualité de compression (0.6 est généralement un bon compromis entre qualité et taille)
      maxHeight: 200,
      maxWidth: 200,
      success(result) {
        const reader = new FileReader();
        reader.onload = (event) => {
          const img = new Image();
          img.onload = () => {
            setImgSrc(event.target?.result);
            props.setValue("picture", event.target?.result as never);
          };
          img.src = event.target?.result as string;
        };
        reader.readAsDataURL(result);
      },
      error(err) {
        console.error(err.message);
      },
    });
  };

  const fileIsImageFormat = (file: File): boolean => {
    const fileTypes = ["image/jpeg", "image/png"];
    return fileTypes.includes(file.type);
  };

  const resetImgSrc = () => setImgSrc(undefined);

  return { imgSrc, inpRef, handleInput, openFiles, translations };
};
