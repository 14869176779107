import { IAppDispatch } from "redux/store";
import { getEmailDomain } from "./getEmailDomain";
import * as microsoftTeams from "@microsoft/teams-js";
import { IDialogs } from "../interfaces/IDialog/IDialogs";
import { IError } from "front";
import { v4 as uuidv4 } from "uuid";

export const openChat = (
  email: string,
  upn: string,
  dispatch: IAppDispatch,
  openModal: Function,
  setErrorMessage: Function,
  t: any
) => {
  try {
    if (!email) throw t("NoMail");
    if (!upn) return;
    if (getEmailDomain(upn) !== getEmailDomain(email)) return dispatch(openModal(IDialogs.NOT_SAME_TENANT));
    const url = `https://teams.microsoft.com/l/chat/0/0?users=${email}`;
    return microsoftTeams.app.openLink(url);
  } catch (err) {
    if (typeof err !== "string") return;
    const errorMessage : IError = {
      id: uuidv4(),
      message: err,
      error: {}
    }
    dispatch(setErrorMessage(errorMessage));
  }
};
