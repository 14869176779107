import React from "react";
import { Flex, Input, Text } from "@fluentui/react-northstar";
import { useTranslate } from "front";
import { Control, Controller } from "react-hook-form";
import { translations } from "../../translations";
import "./ContactInput.scss";
import { IContact } from "../../interfaces/IContact/IContact";
import { getValueFromPath } from "../../utils/getValueFromPath";
import { IFormInput } from "../../interfaces/IFormInput/IFormInput";
import { NO_INPUT_VALUE } from "../../const/const";

const ContactInput = (
  props: IFormInput & {
    canModify: boolean;
    control: Control<IContact, any>;
    fill?: boolean;
    errors: any;
  }
) => {
  const t = useTranslate(translations);

  return (
    <Flex key={props.formKey} vAlign="center" hAlign="start" style={{ margin: "0 2rem 1rem 0" }}>
      {props.icon}
      <Flex column fill>
        <Text content={props.placeholder} weight="semibold" style={{ lineHeight: "1" }} />
        {props.canModify ? (
          <Controller
            name={props.formKey as any}
            control={props.control}
            rules={props.rules ?? {}}
            render={({ field }) => {
              const error = !!getValueFromPath(props.formKey, props.errors);
              return (
                <Flex column>
                  <Input style={{ marginTop: ".5rem" }} fluid={!!props.fill} {...field} errorIndicator={error} error={error} />
                  {error && <Text content={props.errors[props.formKey]?.type === "maxLength" ? t(`MaxLengthError`) : t(`${props.formKey}Error`)} error style={{ marginTop: ".25rem" }} size="small" />}
                </Flex>
              );
            }}
          />
        ) : (
          <Text content={props.value ? props.value : NO_INPUT_VALUE} />
        )}
      </Flex>
    </Flex>
  );
};

export default ContactInput;
