import { Button, CloseIcon, Dialog, Dropdown, Flex } from "@fluentui/react-northstar";
import { useSettings } from "./Settings.logic";
import { memo, useMemo } from "react";
import { useTranslate } from "front";
import { translations } from "../../../../translations";
import { primaryStyle } from "../../../../utils/primaryStyle";

const Settings = memo(() => {
  const logic = useSettings();
  const t = useTranslate(translations);

  const content = useMemo(
    () => (
      <div className="configure-container">
        <h4>{t("DisplayMode")}</h4>
        <Dropdown data-testid="FUI-input" items={logic.dropDownItems} checkable defaultValue={logic.convertNumToString} onChange={logic.handleDropDown} fluid />
      </div>
    ),
    []
  );

  const footer = useMemo(
    () => (
      <Flex hAlign="end">
        <Button primary content={t("Save")} onClick={logic.updateContact} style={{ ...primaryStyle, marginRight: ".5rem" }} />
      </Flex>
    ),
    [logic.updateContact]
  );

  return (
    <Dialog
      header={t("Settings")}
      headerAction={{ icon: <CloseIcon />, title: "Close", onClick: logic.onClose }}
      content={content}
      footer={footer}
      defaultOpen={true}
      onCancel={logic.onClose}
      closeOnOutsideClick
    />
  );
});

export default Settings;
