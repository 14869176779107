/* eslint-disable @typescript-eslint/no-explicit-any */
import { z } from "zod";
import { CustomError } from "./customError";
export const errorMessage = (err) => {
    // Axios error
    if (err.response) {
        return err.message;
    }
    // Axios error
    if (err.request) {
        return err.request;
    }
    // Zod Error
    if (err instanceof z.ZodError) {
        return err.issues[0].message;
    }
    // Volontary Error
    if (err instanceof CustomError) {
        return err.message;
    }
    // Volontary Error
    if (err instanceof Error) {
        return err.message + err.stack;
    }
    // Volontary Error
    if (typeof err === "string") {
        return err;
    }
    return "Unhandled Error";
};
