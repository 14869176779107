import React from "react";
import { useConfigure } from "./Configure.logic";
import { Dropdown, Flex, Loader, Image, Text } from "@fluentui/react-northstar";
import { initialState } from "./Configure.reducer";
import { useTranslate } from "front";
import { translations } from "../../translations";
export const Configure = () => {
  const logic = useConfigure();
  const t = useTranslate(translations);

  const renderCreate = () => (
    <div className="configure-container" data-testid="render-create-container">
      <div>
        <h4>{logic.t("DisplayMode")}</h4>
        <Dropdown data-testid="FUI-input" items={logic.dropDownItems} checkable defaultValue={logic.t(initialState.displayMode)} onChange={logic.handleDropDown} fluid />
      </div>
    </div>
  );

  const renderUpdate = () => (
    <Flex
      fill
      column
      gap={"gap.medium"}
      styles={{
        display: "flex",
        padding: "15px",
        overflowY: "scroll",
      }}
      data-testid="render-update-container"
    >
      <div style={{ display: "flex", flexFlow: "column nowrap", justifyContent: "center", alignItems: "center" }} data-testid="render-help-container">
        <Text
          style={{
            textAlign: "center",
            marginBottom: "1rem",
          }}
          content={t("ConfigurationHelp")}
        ></Text>
        <Image src={"/images/configuration/help.webp"} />
      </div>
    </Flex>
  );
  if (!logic.entityId) return renderCreate();
  else {
    return renderUpdate();
  }
};
