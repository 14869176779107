import { Flex } from "@fluentui/react-northstar";
import { RichTextEditor } from "front";
import { useNoteTabs } from "./NoteTabs.logic";
import { Control, Controller } from "react-hook-form";
import { IContact } from "../../interfaces/IContact/IContact";

const NoteTabs = (props: { canModify: boolean; control: Control<IContact, any> }) => {
  const logic = useNoteTabs();

  return (
    <Flex column gap="gap.medium" fill>
      <Flex>
        <Controller
          name={"notes"}
          control={props.control}
          render={({ field }) => <RichTextEditor ref={logic.richTextEditorRef} maxHeight={150} maxContent={300} setValue={field.value} handleChange={field.onChange} readOnly={!props.canModify} />}
        />
      </Flex>
    </Flex>
  );
};

export default NoteTabs;
