import React from "react";
import { Button, CalendarIcon, CallIcon, ChatIcon, EmailIcon, Flex, Tooltip } from "@fluentui/react-northstar";
import { openModal, setErrorMessage, useMsTeamsSelector, useTranslate } from "front";
import { IPhones } from "../../interfaces/IContact/IPhones";
import { memo } from "react";
import { useDispatch } from "react-redux";
import { IAppDispatch } from "../../redux/store";
import { translations } from "../../translations";
import { hasPhone } from "../../utils/hasPhone";
import { openCalendar } from "../../utils/openCalendar";
import { openCall } from "../../utils/openCall";
import { openChat } from "../../utils/openChat";
import "./ContactButtons.scss";
import { openMailTo } from "../../utils/contactUtils";

const ContactButtons = memo((props: { userMail: string; phones: IPhones; showAll?: boolean; responsive?: boolean }) => {
  const dispatch = useDispatch<IAppDispatch>();
  const t = useTranslate(translations);
  const { userMail } = useMsTeamsSelector("userMail");

  const quickActions = [
    {
      name: "email",
      icon: <EmailIcon />,
      onClick: () => openMailTo(props.userMail),
      disabled: !props.userMail,
      disabledMessage: t("mailTooltip"),
      show: !!props.showAll,
    },
    {
      name: "chat",
      icon: <ChatIcon />,
      onClick: () => openChat(props.userMail, userMail, dispatch, openModal, setErrorMessage, t),
      disabled: !props.userMail,
      disabledMessage: t("chatTooltip"),
      show: true,
    },
    {
      name: "calendar",
      icon: <CalendarIcon />,
      onClick: () => openCalendar(props.userMail),
      disabled: !props.userMail,
      disabledMessage: t("calendarTooltip"),
      show: true,
      responsive: true,
    },
    {
      name: "call",
      icon: <CallIcon />,
      onClick: () => openCall(props.phones),
      disabled: !hasPhone(props.phones),
      disabledMessage: t("callTooltip"),
      responsive: props.showAll,
      show: !!props.showAll,
    },
  ];

  return (
    <Flex hAlign="center" vAlign="center">
      {quickActions.map((qa) => (
        <>
          {qa.show && (
            <>
              {qa.disabled ? (
                <Tooltip
                  key={qa.name}
                  content={qa.disabled ? qa.disabledMessage : ""}
                  pointing
                  trigger={
                    <Button
                      data-responsive={!!qa.responsive}
                      icon={qa.icon}
                      iconOnly
                      text
                      onClick={qa.onClick}
                      disabledFocusable={qa.disabled}
                      style={{ filter: "grayscale(100%)" }}
                      data-testid={`quick-action-${qa.name}`}
                    />
                  }
                />
              ) : (
                <Button data-responsive={!!qa.responsive} icon={qa.icon} iconOnly text onClick={qa.onClick} data-testid={`quick-action-${qa.name}`} />
              )}
            </>
          )}
        </>
      ))}
      {/* {props.showAll && <Button icon={<EmailIcon />} iconOnly text disabled={!props.userMail} onClick={() => openMailTo(props.userMail)} />}
      <Button icon={<ChatIcon />} text iconOnly disabled={!props.userMail} onClick={() => openChat(props.userMail, userMail as string, dispatch, openModal, setErrorMessage, t)} />
      <Button
        className="contactButton-disable-on-phone"
        data-responsive={props.responsive}
        icon={<CalendarIcon />}
        text
        iconOnly
        disabled={!props.userMail}
        onClick={() => openCalendar(props.userMail)}
      />
      {props.showAll && (
        <Button
          className="contactButton-disable-on-phone"
          data-responsive={props.responsive}
          icon={<CallIcon />}
          text
          iconOnly
          disabled={!hasPhone(props.phones)}
          onClick={() => openCall(props.phones)}
        />
      )} */}
    </Flex>
  );
});

export default ContactButtons;
