import React from "react";

const FacebookIcon = (props?: { style?: any }) => {
  return (
    <svg width="256" height="256" viewBox="0 0 256 256" fill="none" xmlns="http://www.w3.org/2000/svg" style={props?.style}>
      <g clipPath="url(#clip0_21_2)">
        <path
          d="M256 128C256 57.308 198.692 0 128 0C57.308 0 0 57.308 0 128C0 191.888 46.808 244.843 108 254.445V165H75.5V128H108V99.8C108 67.72 127.11 50 156.348 50C170.352 50 185 52.5 185 52.5V84H168.86C152.959 84 148 93.867 148 103.99V128H183.5L177.825 165H148V254.445C209.192 244.843 256 191.889 256 128Z"
          fill="#1877F2"
        />
        <path
          d="M177.825 165L183.5 128H148V103.99C148 93.866 152.959 84 168.86 84H185V52.5C185 52.5 170.352 50 156.347 50C127.11 50 108 67.72 108 99.8V128H75.5V165H108V254.445C114.616 255.482 121.303 256.002 128 256C134.697 256.002 141.384 255.482 148 254.445V165H177.825Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_21_2">
          <rect width="256" height="256" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FacebookIcon;
