import { AxiosConfig, ReturnValue, tryCatch } from "front";
import { IContact } from "interfaces/IContact/IContact";

const ROUTE = "/contact-lists/";

const getParticipantsFromGroupAction = async ({ queryKey }: { queryKey: string[] }): Promise<ReturnValue<IContact[]>> => {
  const [_, contactListId, groupId, tenantId] = queryKey;
  const req = AxiosConfig.instance.get(`${ROUTE}${contactListId}/contacts/tenantId/${tenantId}/group/${groupId}`);
  AxiosConfig.updateHeadersToTrack("");
  const res = await req;
  return { state: true, variables: queryKey, result: res.data };
};

export const getParticipantFromGroup = tryCatch(getParticipantsFromGroupAction);
