import { PHONES_PROPERTIES_MAX_LENGTH } from "../const/const";
import { ISchemaError } from "../interfaces/ISchemaError/ISchemaError";
import { z } from "zod";

export const phonesSchema = z.object({
  businessPhone: z
    .string({ invalid_type_error: ISchemaError.BUSINESS_FAX_INVALID_TYPE })
    .regex(/^([\d \(\)-\/+]){1,20}$/, { message: ISchemaError.BUSINESS_PHONE_INVALID_FORMAT })
    .max(PHONES_PROPERTIES_MAX_LENGTH, { message: ISchemaError.BUSINESS_PHONE_TOO_LONG })
    .or(z.literal("").optional()),
  mobile: z
    .string({ invalid_type_error: ISchemaError.MOBILE_INVALID_TYPE })
    .regex(/^([\d \(\)-\/+]){1,20}$/, { message: ISchemaError.MOBILE_INVALID_FORMAT })
    .max(PHONES_PROPERTIES_MAX_LENGTH, { message: ISchemaError.MOBILE_PHONE_TOO_LONG })
    .or(z.literal("").optional()),
  home: z
    .string({ invalid_type_error: ISchemaError.HOME_INVALID_TYPE })
    .regex(/^([\d \(\)-\/+]){1,20}$/, { message: ISchemaError.HOME_INVALID_FORMAT })
    .max(PHONES_PROPERTIES_MAX_LENGTH, { message: ISchemaError.HOME_PHONE_TOO_LONG })
    .or(z.literal("").optional()),
  businessFax: z
    .string({ invalid_type_error: ISchemaError.BUSINESS_FAX_INVALID_TYPE })
    .regex(/^([\d \(\)-\/+]){1,20}$/, { message: ISchemaError.BUSINESS_FAX_INVALID_FORMAT })
    .max(PHONES_PROPERTIES_MAX_LENGTH, { message: ISchemaError.BUSINESS_FAX_TOO_LONG })
    .or(z.literal("").optional()),
});
