import { QUERY_KEY_PARTICIPANTS } from "../../../../const/queryKey";
import { setModalWithData, useAppConfigurationSelector, useModalSelector, useTranslate } from "front";
import { useGroupsCache } from "../../../../hooks/useQuery/useGroupsCache";
import { useParticipantsCache } from "../../../../hooks/useQuery/useParticipantsCache";
import { queryClient } from "../../../../index";
import { IContact } from "../../../../interfaces/IContact/IContact";
import { IGroup } from "../../../../interfaces/IGroup/IGroup";
import { useCallback, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { IAppDispatch } from "../../../../redux/store";
import { translations } from "../../../../translations";

export const useCopyGroup = () => {
  const t = useTranslate(translations);
  const { data } = useModalSelector("data");
  const { appId } = useAppConfigurationSelector("appId");
  const dispatchCtx = useDispatch<IAppDispatch>();
  const { mutateCreateGroup } = useGroupsCache();
  const { queryGroups } = useGroupsCache();
  const { queryParticipants } = useParticipantsCache(data.id);

  const [errorHandler, setErrorHandler] = useState<string>("");

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<{ name: string }>({
    mode: "onChange",
    defaultValues: {
      name: "",
    },
  });

  const handleInputValidation = (value: string) => !queryGroups.data.find((g) => g.name.toLocaleLowerCase() === value.toLocaleLowerCase());

  const onSubmit: SubmitHandler<{ name: string }> = useCallback(
    async (formData: { name: string }) => {
      const participants = queryParticipants.data?.map((p) => p.id) ?? [];
      const params = { name: formData.name, participants: { add: participants, remove: [] }, contactListId: appId };
      await mutateCreateGroup.mutateAsync(params, {
        onSuccess: (res: IGroup) => {
          queryClient.setQueryData([QUERY_KEY_PARTICIPANTS, appId, res.id], (_: IContact[] | undefined) => queryParticipants.data ?? []);
          handleClose();
        },
        onError: (err: unknown) => {
          if (typeof err !== "string") return;
          setErrorHandler(err);
        },
      });
    },
    [queryParticipants.data]
  );

  const handleClose = useCallback(() => dispatchCtx(setModalWithData({ isOpen: undefined, data: undefined })), []);

  return { t, handleSubmit, onSubmit, handleClose, isLoading: mutateCreateGroup.isLoading, control, errors, isValid, errorHandler, handleInputValidation };
};
