import { InputProps } from "@fluentui/react-northstar";
import { ALL_GROUPS_TAKE } from "../../const/const";
import { useTranslate } from "front";
import { useGroupsCache } from "../../hooks/useQuery/useGroupsCache";
import { IGroup } from "../../interfaces/IGroup/IGroup";
import { useEffect, useMemo, useState } from "react";
import { translations } from "../../translations";

export const useGroupAdministrationBottom = () => {
  const t = useTranslate(translations);
  const { queryGroups } = useGroupsCache();

  // States
  const [currentPage, setCurrentPage] = useState(1);
  const [groups, setGroups] = useState<IGroup[]>([]);
  const [allGroups, setAllGroups] = useState<IGroup[]>([]);
  const [search, setSearch] = useState<string>("");

  const handleInput = (
    _: unknown,
    data:
      | (InputProps & {
          value: string;
        })
      | undefined
  ) => {
    if (typeof data?.value !== "string") return;
    const allGroups = queryGroups.data.filter((g) => g.name.toLocaleLowerCase().includes(data.value.toLocaleLowerCase()));
    setAllGroups(allGroups);
    setSearch(data.value);
    setCurrentPage(1);
  };

  useEffect(() => {
    if (!queryGroups.data.length) return;
    setAllGroups(queryGroups.data);
    setCurrentPage(1);
  }, [queryGroups.data]);

  useEffect(() => {
    if (!queryGroups.data.length) return setGroups([]);
    const take = (currentPage - 1) * ALL_GROUPS_TAKE;
    const newArr = [...queryGroups.data.filter((g) => g.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())).slice(take, take + ALL_GROUPS_TAKE)];
    setGroups(newArr);
  }, [queryGroups.data.length, currentPage, search]);

  const sortedGroups = useMemo(() => {
    return [...groups].sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
  }, [groups]);

  const handlePage = (page: number) => setCurrentPage(page);

  return { t, sortedGroups, allGroups, handlePage, handleInput, currentPage, search };
};
