import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo } from "react";
import { Alert, Dialog, ExclamationTriangleIcon, Flex, Text } from "@fluentui/react-northstar";
import { useMsTeamsSelector } from "../../../redux/reducers/MicrosoftTeamsReducer/MicrosoftTeamsReducer";
import { translations } from "../../../translations";
import { useDispatch } from "react-redux";
import { openModal } from "../../../redux/reducers/ModalReducer/ModalReducer";
import { useTranslate } from "../../../hook/useTranslate";
import { useAppConfigurationSelector } from "../../../redux/reducers/AppConfigurationReducer/AppConfigurationReducer";
export const ManageRoleDialog = (props) => {
    const t = useTranslate(translations);
    const { isOnMobile } = useMsTeamsSelector("isOnMobile");
    const { from } = useAppConfigurationSelector("from");
    const dispatch = useDispatch();
    const closeDialog = useCallback(() => {
        dispatch(openModal(undefined));
    }, []);
    const closeDialogByConfirm = useCallback(() => {
        dispatch(openModal(undefined));
        props.handleRoleChange(props.index, props.selectedRole);
    }, [props.handleRoleChange]);
    const actionDialog = useMemo(() => {
        return closeDialogByConfirm;
    }, [closeDialogByConfirm]);
    const renderManageRole = () => {
        return (_jsxs("div", { style: { height: 'fit-content' }, children: [_jsx(Flex, { fill: true, column: true, gap: "gap.medium", wrap: true, styles: {
                        marginTop: "10px",
                        overflowY: "scroll",
                        overflowX: "hidden",
                    }, children: _jsx(Text, { content: t("Permissions:ManageRoleTagLine") }) }), from == "Keepass" &&
                    _jsx(Flex, { fill: true, column: true, gap: "gap.medium", wrap: true, styles: {
                            marginTop: "10px",
                            overflowY: "scroll",
                            overflowX: "hidden",
                        }, children: _jsx(Alert, { styles: { width: "100%", marginTop: "10px" }, warning: true, content: _jsxs(Flex, { gap: "gap.small", vAlign: "center", children: [_jsx(ExclamationTriangleIcon, { outline: true }), _jsx(Text, { content: t("AccessMaster") })] }) }) })] }));
    };
    return (_jsx(Dialog, { styles: { width: isOnMobile ? "95vw" : "500px", zIndex: 9999 }, defaultOpen: true, closeOnOutsideClick: true, header: _jsx(Flex, { gap: "gap.small", vAlign: "center", children: _jsx(Text, { content: t("Permissions:ManageRoleTitle") }) }), confirmButton: t("Continue"), content: renderManageRole(), cancelButton: t("Return"), onCancel: () => closeDialog(), onConfirm: actionDialog }));
};
