import { Button, ExclamationTriangleIcon, Flex, Text } from "@fluentui/react-northstar";
import React, { useMemo } from "react";
import "./DialogBanner.scss";

const DialogBanner = (props: {
  show: boolean;
  txt: string;
  confirmLabel: string | undefined;
  cancelLabel: string | undefined;
  cancelAction: (() => void) | undefined;
  confirmAction: (() => void) | undefined;
}) => {
  if (!props.show) return <></>;

  const leftBanner = useMemo(
    () => (
      <Flex vAlign="center" hAlign="center" gap="gap.small" className="dialogBanner-left-container">
        {props.cancelAction && <ExclamationTriangleIcon />}
        <Text content={props.txt} />
      </Flex>
    ),
    [props.txt, props.cancelAction]
  );

  const rightBanner = useMemo(
    () => (
      <>
        {props.cancelAction && props.cancelLabel && props.confirmAction && props.confirmLabel && (
          <Flex vAlign="center" hAlign="center" gap="gap.small" className="dialogBanner-right-container">
            <Button content={props.cancelLabel} text onClick={props.cancelAction} />
            <Button content={props.confirmLabel} primary inverted onClick={props.confirmAction} />
          </Flex>
        )}
      </>
    ),
    [props.cancelLabel, props.cancelAction, props.confirmAction, props.confirmAction]
  );

  return (
    <Flex vAlign="center" hAlign="center" space="between" className="dialogBanner-container">
      {leftBanner}
      {rightBanner}
    </Flex>
  );
};

export default DialogBanner;
