import { QUERY_KEY_PARTICIPANTS } from "../../const/queryKey";
import { useQuery } from "react-query";
import { queryOptions } from "./queryOptions";
import { useAppConfigurationSelector, useMsTeamsSelector, useTrack } from "front";
import { IContact } from "../../interfaces/IContact/IContact";
import { getParticipantFromGroup } from "../../apis/actions/participantsAction";
import { IQueryName } from "interfaces/IQueryName/IQueryName";

export const useParticipantsCache = (groupId: string) => {
  const { appId } = useAppConfigurationSelector("appId");
  const { tenantId } = useMsTeamsSelector("tenantId");
  const { trackItAsync } = useTrack();

  const queryParticipants = useQuery<IContact[]>([QUERY_KEY_PARTICIPANTS, appId, groupId, tenantId], trackItAsync(IQueryName.QUERY_PARTICIPANTS, getParticipantFromGroup), {
    ...queryOptions,
    enabled: !!appId || !!groupId,
    staleTime: Infinity,
    refetchOnMount: false,
  });

  return { queryParticipants };
};
