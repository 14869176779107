import { DropdownProps } from "@fluentui/react-northstar";
import { useTranslate } from "front";
import { useGroupsCache } from "../../hooks/useQuery/useGroupsCache";
import { IContact } from "../../interfaces/IContact/IContact";
import { useEffect, useMemo, useRef, useState } from "react";
import { Control, UseFormWatch } from "react-hook-form";
import { translations } from "../../translations";

export const useGroupTabs = (props: { canModify: boolean; contact: IContact | undefined; control: Control<IContact, any>; watch: UseFormWatch<any> }) => {
  const t = useTranslate(translations);
  const { queryGroups } = useGroupsCache();
  const [dropdownValue, setDropdownValue] = useState<any>([]);
  const [canShowNoResult, setCanShowNoResult] = useState(false);

  useEffect(() => {
    if (props.canModify) return;
    setDropdownValue([]);
  }, [props.canModify]);

  const getAllGroups = useMemo(() => {
    const allGroups = queryGroups.data;
    return allGroups.filter((ag) => props.contact?.groupId?.includes(ag.id));
  }, [props.contact, queryGroups.data]);

  const handleDropdown = (_: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element> | null, data: DropdownProps, field: any) => {
    const ids = (data.value as any).map((g: any) => {
      if (typeof g === "string") {
        const selectedGroup = queryGroups.data.find((i) => i.name === g);
        return selectedGroup?.id;
      }
      return g.id;
    });
    field.value.add = ids;
    field.onChange(field.value);
  };

  const handleRemove = (id: string, field: any) => {
    field.value.remove.push(id);
    field.onChange(field.value);
  };

  const onSearchQueryChange = (_: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element> | null, data: DropdownProps) => {
    const value = data.searchQuery as string;
    const result = queryGroups.data.find((g) => g.name.toLowerCase().includes(value.toLocaleLowerCase()));
    setCanShowNoResult(!result);
  };

  return { t, allGroups: queryGroups.data, handleDropdown, getAllGroups, handleRemove, dropdownValue, setDropdownValue, onSearchQueryChange, canShowNoResult };
};
