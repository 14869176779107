import { AxiosConfig, ReturnValue, tryCatch } from "front";
import { IGroup, IGroupDTO } from "interfaces/IGroup/IGroup";

const ROUTE = "/contact-lists/";

const _createGroup = async (data: IGroupDTO & { participants: { add: string[]; remove: string[] } }): Promise<ReturnValue<IGroup>> => {
  const req = AxiosConfig.instance.post(`${ROUTE}${data.contactListId}/groups`, data);
  AxiosConfig.updateHeadersToTrack("");
  const res = await req;
  return { state: true, variables: data, result: res.data };
};

const _editGroup = async (data: IGroup & { participants: { add: string[]; remove: string[] } }): Promise<ReturnValue<void>> => {
  const req = AxiosConfig.instance.patch(`${ROUTE}${data.contactListId}/groups/${data.id}`, data);
  AxiosConfig.updateHeadersToTrack("");
  await req;
  return { state: true, variables: data };
};

const _deleteGroup = async (data: { tenantId: string; contactListId: string; id: string }): Promise<ReturnValue<void>> => {
  const req = AxiosConfig.instance.delete(`${ROUTE}${data.contactListId}/groups/${data.id}/tenantId/${data.tenantId}`);
  AxiosConfig.updateHeadersToTrack("");
  await req;
  return { state: true, variables: data };
};

const _getAllGroups = async ({ queryKey }: { queryKey: string[] }): Promise<ReturnValue<IGroup[]>> => {
  const contactListId = queryKey[1];
  const req = AxiosConfig.instance.get(`${ROUTE}${contactListId}/groups`);
  AxiosConfig.updateHeadersToTrack("");
  const res = await req;
  return { state: true, variables: queryKey, result: res.data };
};

export const createGroup = tryCatch(_createGroup);
export const editGroup = tryCatch(_editGroup);
export const deleteGroup = tryCatch(_deleteGroup);
export const getAllGroups = tryCatch(_getAllGroups);
