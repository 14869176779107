import { v4 as uuidv4 } from "uuid";
import { useMsTeamsSelector } from "../../redux/reducers/MicrosoftTeamsReducer/MicrosoftTeamsReducer";
import { errorMessage } from "../../utils/errorMessage";
import { useDispatch } from "react-redux";
import { setErrorMessage } from "../../redux/reducers/ErrorReducer/ErrorReducer";
import { errorSchema } from "../../schemas/Error.schema";
export const useErrorHandling = () => {
    const { entityId, tenantId } = useMsTeamsSelector("entityId", "tenantId");
    const dispatch = useDispatch();
    function tryCatch(callback) {
        return (...args) => {
            try {
                return callback(...args);
            }
            catch (err) {
                return handleError(err, callback.name, args);
            }
        };
    }
    function tryCatchAsync(callback) {
        return async (...args) => {
            try {
                return await callback(...args);
            }
            catch (err) {
                return handleError(err, callback.name, args);
            }
        };
    }
    const handleError = (err, functionName, data) => {
        const error = {
            id: uuidv4(),
            message: errorMessage(err),
            functionName,
            functionArgs: data,
            stack: err.stack,
            appId: entityId,
            tenantId,
            date: new Date(),
            error: err,
        };
        errorSchema.safeParse(error);
        dispatch(setErrorMessage(error));
        console.error(error);
        return { state: false, error, variables: data, result: undefined };
    };
    const _hasError = (data) => Boolean(data.error && data.result === undefined);
    return { tryCatch, tryCatchAsync, _hasError };
};
