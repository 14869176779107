import React from "react";
import { useUsersCache } from "../../hooks/useQuery/useUsersCache";
import { useDispatch, useSelector } from "react-redux";
import { setPagination } from "../../redux/reducer/PaginationReducer";
import { IAppDispatch, ReduxStoreState } from "../../redux/store";

export const useDisplayByCard = () => {
  const pagination = useSelector((s: ReduxStoreState) => s.pagination);
  const { queryUsers } = useUsersCache();
  const dispatch = useDispatch<IAppDispatch>();

  const changePage = (page: number) => {
    const skip = (page - 1) * pagination.take;
    dispatch(setPagination({ ...pagination, skip, currentPage: page }));
  };

  return { pagination, changePage, total: queryUsers.data?.total };
};
