import { exportContact } from "../../../../apis/actions/contactAction";
import { IError, openModal, setErrorMessage, useMsTeamsSelector, useTranslate } from "front";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IAppDispatch, ReduxStoreState } from "../../../../redux/store";
import { translations } from "../../../../translations";
import { v4 as uuidv4 } from "uuid";

export const useExportContact = () => {
  // Services
  const dispatchCtx = useDispatch<IAppDispatch>();
  const t = useTranslate(translations);
  const { appId } = useSelector((s: ReduxStoreState) => s.appConfiguration);
  const msTeamsContext = useMsTeamsSelector("channelId", "tenantId", "userMail", "groupId");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onClose = useCallback(() => dispatchCtx(openModal(undefined)), []);

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const { channelId, groupId, tenantId, userMail } = msTeamsContext;
      const data = {
        contactListId: appId,
        email: userMail,
        tenantId,
        groupId,
        channelId,
        contactListName: "",
      };
      await exportContact(data);
    } catch (error) {
      const errorMessage : IError = {
        id: uuidv4(),
        message: t("CannotExportData"),
        error: {}
      }
      setErrorMessage(errorMessage);
    }
  };

  return { t, onClose, handleSubmit, isLoading, msTeamsContext, userMail: msTeamsContext.userMail };
};
