import { Button, CloseIcon, Dialog, Flex, Text } from "@fluentui/react-northstar";
import { memo, useMemo } from "react";
import { useNotSameTenant } from "./NotSameTenant.logic";

const NotSameTenant = memo(() => {
  const logic = useNotSameTenant();

  const renderContent = useMemo(() => <Text content={logic.t("NoChatWithOtherTenant")} />, []);

  const renderFooter = useMemo(
    () => (
      <Flex hAlign="end">
        <Button content={logic.t("Close")} style={{ marginRight: ".5rem" }} onClick={logic.onClose} />
      </Flex>
    ),
    []
  );

  return (
    <Dialog
      header={logic.t("Information")}
      headerAction={{ icon: <CloseIcon />, title: "Close", onClick: logic.onClose }}
      content={renderContent}
      footer={renderFooter}
      defaultOpen={true}
      onCancel={logic.onClose}
      closeOnOutsideClick
    />
  );
});

export default NotSameTenant;
