import { BuildingIcon, CallIcon, Flex, NotepadPersonIcon, Text } from "@fluentui/react-northstar";
import ContactInput from "../../components/ContactInput/ContactInput";
import { IContact } from "../../interfaces/IContact/IContact";
import React, { useMemo } from "react";
import { Control, FieldErrors, UseFormSetValue, UseFormWatch } from "react-hook-form";
import { useCompanyTabs } from "./CompanyTabs.logic";
import { generateFormField } from "../../utils/generateFormField";
import { IFormInput } from "../../interfaces/IFormInput/IFormInput";
import AddressInput from "../../components/AddressInput/AddressInput";

const CompanyTabs = (props: {
  defaultValues: Omit<IContact, "updatedAt" | "createdAt">;
  contact: IContact | undefined;
  canModify: boolean;
  control: Control<IContact, any>;
  errors: FieldErrors;
  setValue: UseFormSetValue<IContact>;
  watch: UseFormWatch<IContact>;
}) => {
  const logic = useCompanyTabs();

  const companyTabs: IFormInput[] = useMemo(
    () => [
      generateFormField("company", props.defaultValues, <BuildingIcon outline style={{ margin: ".75rem" }} />, { maxLength: 50 }, true, logic.t),
      generateFormField("department", props.defaultValues, <NotepadPersonIcon outline style={{ margin: ".75rem" }} />, { maxLength: 50 }, true, logic.t),
      generateFormField("businessPhone", props.defaultValues, <CallIcon outline style={{ margin: ".75rem" }} />, { pattern: /^([\d \(\)-\/+]){1,20}$/ }, true, logic.t),
      generateFormField("businessFax", props.defaultValues, <CallIcon outline style={{ margin: ".75rem" }} />, { pattern: /^([\d \(\)-\/+]){1,20}$/ }, true, logic.t),
    ],
    [props.contact, props.defaultValues, logic.t]
  );

  return (
    <>
      <Flex fill vAlign="start" hAlign="start" style={{ flexFlow: "row wrap" }}>
        <div className="contactDialog-contact-tab-container">
          {companyTabs.map((i) => (
            <ContactInput key={i.formKey} {...i} canModify={props.canModify} control={props.control} errors={props.errors} />
          ))}
        </div>

        <Flex vAlign="center" hAlign="start" style={{ margin: "0 2rem 1rem 0", width: "100%" }} column>
          <AddressInput {...props} />
        </Flex>
      </Flex>
    </>
  );
};

export default CompanyTabs;
