export enum IQueryName {
  QUERY_CONTACT_LIST = "query contact list",
  MUTATION_EDIT_CONTACT_LIST = "edit contact list",
  QUERY_GROUPS = "query groups",
  MUTATION_CREATE_GROUP = "create group",
  MUTATION_EDIT_GROUP = "edit group",
  MUTATION_DELETE_GROUP = "delete group",
  QUERY_PARTICIPANTS = "query participants",
  QUERY_CONTACTS = "query contacts",
  MUTATION_CREATE_CONTACT = "create contact",
  MUTATION_EDIT_CONTACT = "edit contact",
  MUTATION_DELETE_CONTACT = "delete contact",
  MUTATION_IMPORT_CONTACTS = "import contacts",
}
