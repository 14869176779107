import { Flex, Loader } from "@fluentui/react-northstar";
import { useMsTeamsSelector } from "front";
import { memo } from "react";

const AppLoader = memo(() => {
  const { theme } = useMsTeamsSelector("theme");

  const containerStyles = {
    heigth: "100vh",
    position: "fixed",
    top: 0,
    left: 0,
    background: theme.siteVariables.bodyBackground === "#2D2C2C" ? "rgb(31, 31, 31)" : "#ebebeb",
    zIndex: 1000,
  };

  return (
    <Flex styles={containerStyles} fill vAlign="center" hAlign="center">
      <Loader />
    </Flex>
  );
});

export default AppLoader;
