import { Button, CloseIcon, Dialog, Flex, Text } from "@fluentui/react-northstar";
import { memo, useMemo } from "react";
import { primaryStyle } from "../../../../utils/primaryStyle";
import { useRemoveContactFromGroup } from "./RemoveContactFromGroup.logic";

const RemoveContactFromGroup = memo(() => {
  const logic = useRemoveContactFromGroup();

  const renderContent = useMemo(
    () => (
      <div style={{ padding: "1rem 0" }}>
        <Text content={logic.t("RemoveContactFromGroupMessage")}></Text> <Text content={logic.name} weight="bold"></Text>
      </div>
    ),
    []
  );

  const renderFooter = useMemo(
    () => (
      <>
        <Flex hAlign="end">
          <Button disabled={logic.isLoading} content={logic.t("Close")} style={{ marginRight: ".5rem" }} onClick={logic.handleClose} />
          <Button disabled={logic.isLoading} primary style={primaryStyle} content={logic.t("Delete")} onClick={logic.handleDelete} />
        </Flex>
      </>
    ),
    [logic.isLoading]
  );

  return (
    <Dialog
      header={logic.t("RemoveContactFromGroup")}
      headerAction={{ icon: <CloseIcon />, title: "Close", onClick: logic.handleClose }}
      content={renderContent}
      footer={renderFooter}
      defaultOpen={true}
      onCancel={logic.handleClose}
      closeOnOutsideClick
    />
  );
});

export default RemoveContactFromGroup;
