import React from "react";

const XIcon = (props?: { style?: any; svgColor: string }) => {
  return (
    <svg
      width="256"
      height="256"
      viewBox="0 0 256 256"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={props?.style}
    >
      <path
        d="M148.704 110.848L229.712 16H210.512L140.192 98.352L84 16H19.2L104.16 140.544L19.2 240H38.4L112.672 153.024L172.016 240H236.816L148.704 110.848ZM122.416 141.632L113.808 129.232L45.312 30.56H74.8L130.064 110.192L138.672 122.592L210.528 226.112H181.04L122.416 141.632Z"
        fill={props?.svgColor}
      />
    </svg>
  );
};

export default XIcon;
