import { Button, CloseIcon, Dialog, Flex, Text } from "@fluentui/react-northstar";
import { memo, useMemo } from "react";
import { useExportContact } from "./ExportContact.logic";
import { translations } from "../../../../translations";
import { useTranslate } from "front";

const ExportContact = memo(() => {
  const logic = useExportContact();
  const t = useTranslate(translations);

  const content = useMemo(
    () => (
      <>
        {logic.isLoading ? (
          <Flex padding={"padding.medium"} column>
            <Text styles={{ marginBottom: "15px" }} content={t("RequestInProgress")} />
            <Text styles={{ marginBottom: "15px" }} content={t("MessageSendMailExport", { UpnContext: logic.msTeamsContext.userMail })} />
          </Flex>
        ) : (
          <Text content={t("SureToExportContact")} />
        )}
      </>
    ),
    [logic.isLoading]
  );

  const footer = useMemo(
    () => (
      <>
        {logic.isLoading ? (
          <>
            <Flex hAlign="end">
              <Button primary content={logic.t("Close")} onClick={logic.onClose} />
            </Flex>
          </>
        ) : (
          <Flex hAlign="end">
            <Button primary disabled={logic.isLoading} content={logic.t("ExportContact")} onClick={logic.handleSubmit} />
          </Flex>
        )}
      </>
    ),
    [logic.isLoading]
  );

  return (
    <Dialog
      header={t("StartExport")}
      headerAction={{ icon: <CloseIcon />, title: "Close", onClick: logic.onClose }}
      content={content}
      footer={footer}
      defaultOpen={true}
      onCancel={logic.onClose}
      closeOnOutsideClick
    />
  );
});

export default ExportContact;
