import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { IAppDispatch } from "../../../../redux/store";
import { openModal, useTranslate } from "front";
import { translations } from "../../../../translations";

export const useNotSameTenant = () => {
  // Services
  const dispatch = useDispatch<IAppDispatch>();
  const t = useTranslate(translations);

  const onClose = useCallback(() => {
    dispatch(openModal(undefined));
  }, []);

  return {
    t,
    onClose,
  };
};
