import { configureStore } from "@reduxjs/toolkit";
import {
  appConfigurationReducer,
  authReducer,
  configurationReducer,
  draftsReducer,
  errorReducer,
  infosReducer,
  modalReducer,
  msTeamsReducer,
  permissionsReducer,
  premiumReducer,
  scopesReducer,
  userReducer,
} from "front";
import ContactListReducer from "./reducer/ContactListReducer";
import PaginationReducer from "./reducer/PaginationReducer";
import GroupReducer from "./reducer/GroupReducer";
import LimitationReducer from "./reducer/LimitationReducer";

const reducer = {
  configuration: configurationReducer,
  msTeams: msTeamsReducer,
  auth: authReducer,
  scopes: scopesReducer,
  user: userReducer,
  premium: premiumReducer,
  modal: modalReducer,
  contactList: ContactListReducer,
  appConfiguration: appConfigurationReducer,
  pagination: PaginationReducer,
  group: GroupReducer,
  error: errorReducer,
  draft: draftsReducer,
  permissions: permissionsReducer,
  limitation: LimitationReducer,
  infosApp: infosReducer,
};

export const initStore = () => {
  return configureStore({
    reducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
  });
};

const store = initStore();

export type IStore = ReturnType<typeof initStore>;

export type ReduxStoreState = ReturnType<typeof store.getState>;
export type IAppDispatch = typeof store.dispatch;

export default store;
