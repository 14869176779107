import { Flex, LocationIcon, Text } from "@fluentui/react-northstar";
import { GoogleRenderComponent, findKeyPath } from "front";
import React from "react";
import { Control, Controller, FieldErrors, UseFormSetValue, UseFormWatch } from "react-hook-form";
import { useAddressInput } from "./AddressInput.logic";
import { IContact } from "../../interfaces/IContact/IContact";

const AddressInput = (props: {
  defaultValues: Omit<IContact, "updatedAt" | "createdAt">;
  contact: IContact | undefined;
  canModify: boolean;
  control: Control<IContact, any>;
  errors: FieldErrors;
  setValue: UseFormSetValue<IContact>;
  watch: UseFormWatch<IContact>;
}) => {
  const logic = useAddressInput(props);
  return (
    <>
      <Flex vAlign="center" hAlign="center">
        <LocationIcon outline style={{ margin: ".75rem" }} />
        <Text content={logic.t("Address")} weight="semibold" style={{ lineHeight: "1" }} />
      </Flex>
      <Controller
        key={"address"}
        name={"addresses[0].fullAddress" as any}
        control={props.control}
        render={() => {
          return (
            <Flex
              column
              fill
              style={{
                height: props.watch(findKeyPath(props.defaultValues, "fullAddress")) ? "300px" : "min-height",
                paddingLeft: "2.5rem",
              }}
            >
              <GoogleRenderComponent
                key={"address"}
                address={{
                  fullAddress: props.watch(findKeyPath(props.defaultValues, "fullAddress")),
                  lat: props.watch(findKeyPath(props.defaultValues, "lat")),
                  lng: props.watch(findKeyPath(props.defaultValues, "lng")),
                }}
                disabled={!props.canModify}
                readOnly={props.canModify}
                updateAddress={logic.handleAddress}
                hideLabel
              />
            </Flex>
          );
        }}
      />
    </>
  );
};

export default AddressInput;
