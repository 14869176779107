import { Button, CloseIcon, Dialog, Flex, Input, Text } from "@fluentui/react-northstar";
import { memo, useMemo } from "react";
import { primaryStyle } from "../../../../utils/primaryStyle";
import { useCopyGroup } from "./CopyGroup.logic";
import { Controller } from "react-hook-form";

const CopyGroup = memo(() => {
  const logic = useCopyGroup();

  const errorLabel = useMemo(
    () => ({
      required: logic.t("nameError"),
      validate: logic.t("GroupAlreadyExist"),
      maxLength: logic.t("MaxLengthError"),
    }),
    []
  );

  const renderContent = useMemo(
    () => (
      <div style={{ padding: "1rem 0" }}>
        <Text content={logic.t("CopyGroupMessage")}></Text>
        <Controller
          name={"name"}
          control={logic.control}
          rules={{ required: true, maxLength: 50, validate: logic.handleInputValidation }}
          render={({ field }) => (
            <Flex column>
              <Input style={{ marginTop: ".5rem" }} label={logic.t("Name")} fluid {...field} errorIndicator={!!logic.errors.name} error={!!logic.errors.name} disabled={logic.isLoading} />
              {logic.errors.name && <Text content={errorLabel[logic.errors.name.type as "required" | "validate" | "maxLength"]} error />}
            </Flex>
          )}
        />
      </div>
    ),
    [logic.errors.name, logic.isLoading]
  );

  const renderFooter = useMemo(
    () => (
      <>
        <Flex hAlign="end" space="between">
          <Flex hAlign="center" vAlign="center">
            {logic.errorHandler && <Text content={logic.errorHandler} error />}
          </Flex>
          <Flex gap="gap.small">
            <Button disabled={logic.isLoading} content={logic.t("Close")} onClick={logic.handleClose} />
            <Button disabled={logic.isLoading || !logic.isValid} primary style={primaryStyle} content={logic.t("Copy")} onClick={logic.handleSubmit(logic.onSubmit)} />
          </Flex>
        </Flex>
      </>
    ),
    [logic.isLoading, logic.isValid, logic.errorHandler]
  );

  return (
    <Dialog
      header={logic.t("CopyGroup")}
      headerAction={{ icon: <CloseIcon />, title: "Close", onClick: logic.handleClose }}
      content={renderContent}
      footer={renderFooter}
      defaultOpen={true}
      onCancel={logic.handleClose}
      closeOnOutsideClick
    />
  );
});

export default CopyGroup;
