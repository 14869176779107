import { Accordion, Button, ContactGroupIcon, EditIcon, Flex, MenuButton, MoreIcon, ScreenshareIcon, Text, Tooltip, TrashCanIcon } from "@fluentui/react-northstar";
import { useDisplayByGroup } from "./DisplayByGroup.logic";
import { generateGUID, shouldTruncate, useTranslate } from "front";
import { translations } from "../../translations";
import { useMemo } from "react";
import RenderPanel from "../../components/RenderPanel/RenderPanel";
import FlexWrapper from "../../components/FlexWrapper/FlexWrapper";

const DisplayByGroup = () => {
  const logic = useDisplayByGroup();
  const t = useTranslate(translations);

  // Should be refacto
  const menus = useMemo(
    () => [
      {
        key: generateGUID(),
        disabled: !logic.userPerm.perm.update,
        onClick: logic.handleEdit,
        content: (
          <>
            {logic.userPerm.perm.update ? (
              <Flex vAlign="center" hAlign="start" gap="gap.small">
                <EditIcon outline />
                <Text content={logic.t("Edit")} />
              </Flex>
            ) : (
              <Tooltip
                content={logic.t("NoEditPerm")}
                pointing
                trigger={
                  <Flex vAlign="center" hAlign="start" gap="gap.small">
                    <EditIcon outline />
                    <Text content={logic.t("Edit")} />
                  </Flex>
                }
              />
            )}
          </>
        ),
      },
      {
        key: generateGUID(),
        disabled: !logic.userPerm.perm.add,
        onClick: logic.handleCopy,
        content: (
          <>
            {logic.userPerm.perm.add ? (
              <Flex vAlign="center" hAlign="start" gap="gap.small">
                <ScreenshareIcon outline />
                <Text content={logic.t("Copy")} />
              </Flex>
            ) : (
              <Tooltip
                content={logic.t("NoAddPerm")}
                pointing
                trigger={
                  <Flex vAlign="center" hAlign="start" gap="gap.small">
                    <ScreenshareIcon outline />
                    <Text content={logic.t("Copy")} />
                  </Flex>
                }
              />
            )}
          </>
        ),
      },
      {
        key: generateGUID(),
        disabled: !logic.userPerm.perm.delete,
        onClick: logic.handleDelete,
        content: (
          <>
            {logic.userPerm.perm.delete ? (
              <Flex vAlign="center" hAlign="start" gap="gap.small">
                <TrashCanIcon outline />
                <Text content={logic.t("Delete")} />
              </Flex>
            ) : (
              <Tooltip
                content={logic.t("NoDeletePerm")}
                pointing
                trigger={
                  <Flex vAlign="center" hAlign="start" gap="gap.small">
                    <TrashCanIcon outline />
                    <Text content={logic.t("Delete")} />
                  </Flex>
                }
              />
            )}
          </>
        ),
      },
    ],
    []
  );

  const getPanels = useMemo(() => {
    if (!logic.data.length) return [{ title: t("None"), content: "" }];
    const panels = logic.data.map((item) => {
      const panel = {
        key: item.id,
        title: {
          id: `${item.id}-title`,
          content: (
            <Flex hAlign="start" vAlign="center" style={{ maxHeight: 20 }} space="between" styles={{ width: logic.isOnMobile ? "100%" : "max-content" }}>
              <Tooltip content={item.name} trigger={<Text content={shouldTruncate(item.name, 20) ? `${item.name.substring(0, 20)}...` : item.name} />} />
              <div onClick={(e) => logic.cancelPropagation(e, item.id)}>
                <MenuButton title="More" trigger={<Button icon={<MoreIcon />} iconOnly text />} menu={menus} on="click" />
              </div>
            </Flex>
          ),
          onClick: () => logic.handleAccordionClick(item.id),
        },
        content: {
          id: `${item.id}-content`,
          content: logic.isShow.includes(item.id) ? <RenderPanel group={item} /> : <></>,
        },
      };
      return panel;
    });
    return panels;
  }, [logic.data, logic.isShow]);

  if (!logic.data.length)
    return (
      <FlexWrapper>
        <Text content={logic.t("NoGroup")} weight="semibold" align="center" styles={{ paddingLeft: "2rem" }} />
        <Button data-testid="contactList-add-contact" primary content={logic.t("CreateGroup")} icon={<ContactGroupIcon />} onClick={logic.openNewGroupDialog} styles={{ marginLeft: "2rem" }} />
      </FlexWrapper>
    );

  return <Accordion panels={getPanels} />;
};

export default DisplayByGroup;
