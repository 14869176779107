import { Alert, Flex, Text } from "@fluentui/react-northstar";
import { openPremiumDialog, usePremiumSelector, useTranslate, setModalWithData } from "front";
import { useCallback, memo } from "react";
import { useDispatch } from "react-redux";
import { IAppDispatch } from "redux/store";
import { appInsightInstance } from "../../services/TelemetryService/TelemetryService";
import { translations } from "../../translations";

const PremiumBanner = memo(() => {
  const t = useTranslate(translations);
  const dispatch = useDispatch<IAppDispatch>();
  const { isPremium } = usePremiumSelector("isPremium");

  const openPremium = useCallback(() => {
    dispatch(openPremiumDialog(appInsightInstance));
  }, []);

  if (isPremium) return;

  return (
    <Alert
      content={
        <Flex>
          <Text
            styles={{
              textDecoration: "none",
              cursor: "default",
              marginRight: "5px",
            }}
            content={t("PremiumMessage1")}
          />

          <Text
            styles={{
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={openPremium}
            weight={"bold"}
            content={<>{t("ClickHere")}</>}
          />
          <Text
            styles={{
              textDecoration: "none",
              cursor: "default",
              marginLeft: "5px",
            }}
            content={t("PremiumMessage2")}
          />
        </Flex>
      }
      visible
      warning
    />
  );
});

export default PremiumBanner;
