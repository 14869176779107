import { Flex, Button } from "@fluentui/react-northstar";
import { alphabetArr } from "./Alphabet.data";
import { useAlphabetPicker } from "./AlphabetPicker.logic";
import React, { memo } from "react";
import "./AlphabetPicker.scss";

const AlphabetPicker = memo(() => {
  const logic = useAlphabetPicker();
  const btnStyles = {
    width: "10px",
    marginBottom: ".5rem",
    borderTopRightRadius: "62.4375rem !important",
    borderTopLeftRadius: "62.4375rem !important",
    borderBottomRightRadius: "62.4375rem !important",
    borderBottomLeftRadius: "62.4375rem !important",
  };

  return (
    <Flex column className="alphabetPicker-container">
      {alphabetArr.map((item) => (
        <Flex style={{ position: "relative" }} key={item}>
          {logic.letter === item && <div className="alphabetPicker-primary-banner"></div>}
          <Button disabled={logic.shouldBeDisabled(item)} key={item} content={item} circular styles={btnStyles} size="small" onClick={() => logic.handleFilter(item)} primary={logic.letter === item} />
        </Flex>
      ))}
    </Flex>
  );
});

export default AlphabetPicker;
