export const getBrowserName = () => {
    const userAgent = navigator.userAgent;
    if (userAgent.includes("Firefox")) {
        return "Firefox";
    }
    else if (userAgent.includes("SamsungBrowser")) {
        return "Samsung Internet";
    }
    else if (userAgent.includes("Opera") || userAgent.includes("OPR")) {
        return "Opera";
    }
    else if (userAgent.includes("Trident")) {
        return "Internet Explorer";
    }
    else if (userAgent.includes("Edge")) {
        return "Microsoft Edge";
    }
    else if (userAgent.includes("Chrome")) {
        return "Chrome";
    }
    else if (userAgent.includes("Safari")) {
        return "Safari";
    }
    else {
        return "unknown";
    }
};
