import { Button, ChevronEndIcon, ChevronStartIcon, Flex, Skeleton, Text } from "@fluentui/react-northstar";
import UserCard from "../../components/UserCard/UserCard";
import React, { memo } from "react";
import { useRenderPanel } from "./RenderPanel.logic";
import "./RenderPanel.scss";
import { IGroup } from "../../interfaces/IGroup/IGroup";
import { Carrousel } from "front";

const RenderPanel = (props: { group: IGroup }) => {
  const logic = useRenderPanel(props);

  if (logic.isLoading) {
    return (
      <Skeleton style={{ position: "relative", height: "255px" }}>
        <Flex gap="gap.small">
          <Flex hAlign="center" vAlign="center" space="between" style={{ padding: ".5rem 1rem", zIndex: 1000, position: "absolute", width: "100%", top: "50%", transform: "translateY(-50%)" }}>
            <Skeleton.Button iconOnly />
            <Skeleton.Button iconOnly />
          </Flex>
          <Flex gap="gap.small" style={{ padding: "0 3.5rem" }}>
            {Array.from({ length: 10 }).map(() => (
              <Flex hAlign="center" vAlign="center" gap="gap.small">
                <Skeleton animation="pulse">
                  <Flex column gap="gap.small">
                    <Skeleton.Shape height="255px" width="225px" />
                  </Flex>
                </Skeleton>
              </Flex>
            ))}
          </Flex>
        </Flex>
      </Skeleton>
    );
  }

  if (!logic.participants.length) {
    return (
      <Flex fill hAlign="center" vAlign="center" style={{ padding: "2rem", height: "250px" }}>
        <Text content={logic.t("NoContactInGroup")} />
      </Flex>
    );
  }

  return (
    <Flex gap="gap.smaller">
      <Carrousel spaceBetweenItems={10}>
        {logic.participants.map((item, i) => (
          <UserCard key={`contact-${i}`} {...item} idx={i} removeFromGroup={logic.handleRemoveFromGroup} />
        ))}
      </Carrousel>
    </Flex>
  );
};

export default RenderPanel;
