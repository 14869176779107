import React from "react";
import { Button } from "@fluentui/react-northstar";
import { IArrowPaginationButton } from "./types/IArrowPaginationButton";
import { IPaginationProps } from "./types/IPaginationProps";
import { generateGUID } from "../../utils/generateGUID";
import { IPaginationButton } from "./types/IPaginationButton";
import { primaryStyle } from "../../utils/primaryStyle";

const MAXIMUM_PAGINATION_LENGTH = 5;

export const usePagination = (props: IPaginationProps) => {
  const totalPages = Math.ceil(props.total / props.take);

  const handleExtrermityButton = (sign: IArrowPaginationButton) => {
    const map: Record<IArrowPaginationButton, { canClick: boolean; value: number }> = {
      [IArrowPaginationButton.AFTER]: {
        canClick: props.page !== totalPages,
        value: props.page + 1,
      },
      [IArrowPaginationButton.BEFORE]: {
        canClick: props.page !== 1,
        value: props.page - 1,
      },
    };
    return map[sign];
  };

  const getPrimaryStyle = (disabled: boolean, primary: boolean) => {
    const style = primary && !disabled ? { ...primaryStyle, margin: "0 .25rem" } : { margin: "0 .25rem" };
    return style;
  };

  const renderExtremityButton = (sign: IArrowPaginationButton) => {
    const btnInfo = handleExtrermityButton(sign);
    return generateButton({
      content: sign as string,
      disabled: !btnInfo.canClick,
      primary: true,
      value: btnInfo.value,
    });
  };

  const generateButton = ({ content, primary, disabled, value }: IPaginationButton) => {
    return (
      <Button
        size="small"
        key={generateGUID()}
        circular
        primary={primary}
        disabled={disabled}
        content={content}
        onClick={() => props.changePage(value)}
        className="pagination-btn"
        styles={{borderTopRightRadius: "62.4375rem !important", borderTopLeftRadius: "62.4375rem !important", borderBottomRightRadius: "62.4375rem !important", borderBottomLeftRadius: "62.4375rem !important"}}
      />
    );
  };

  const shouldDisplay = (pageToDisplay: number) => (pageToDisplay >= totalPages ? null : pageToDisplay);
  const simplePagination = (length: number) =>
    Array.from({ length }).map((_, i) => {
      const idx = i + 1;
      return generateButton({ content: String(idx), disabled: false, primary: idx === props.page, value: idx });
    });

  const complexPagination = () => {
    const currentPage = props.page;
    if (currentPage >= MAXIMUM_PAGINATION_LENGTH) {
      const btnIndex = [-1, 0, +1];
      const btnToDisplay = btnIndex.map((item) => shouldDisplay(currentPage + item));
      return btnToDisplay
        .filter((btn) => btn)
        .map((item) =>
          generateButton({ content: String(item), disabled: false, primary: item === props.page, value: Number(item) })
        );
    } else {
      return simplePagination(MAXIMUM_PAGINATION_LENGTH);
    }
  };

  const generatePaginationStructure = () => {
    if (totalPages === 0) {
      return <></>;
    } else if (totalPages <= MAXIMUM_PAGINATION_LENGTH) {
      return simplePagination(totalPages);
    } else {
      return complexPagination();
    }
  };

  return {
    renderExtremityButton,
    generateButton,
    generatePaginationStructure,
    shouldDisplay,
    MAXIMUM_PAGINATION_LENGTH,
    totalPages,
  };
};
