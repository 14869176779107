export const manageWatchHookForm = (functionToExe, dependancy) => {
    const data = {};
    dependancy.forEach((d) => {
        data[d] = functionToExe(d);
    });
    return data;
};
export const getKeyRegistered = (obj, parentKey = "") => {
    let keys = [];
    for (const key in obj) {
        if (typeof obj[key] === "object" && !Array.isArray(obj[key])) {
            const childKeys = getKeyRegistered(obj[key], `${parentKey}${key}.`);
            keys = keys.concat(childKeys);
        }
        else {
            keys.push(`${parentKey}${key}`);
        }
    }
    return keys.flat();
};
export const findKeyPath = (obj, targetKey, parentKey = "") => {
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            let currentKey = parentKey ? `${parentKey}.${key}` : key;
            if (key === targetKey) {
                return currentKey;
            }
            else if (typeof obj[key] === "object" && obj[key] !== null) {
                let nestedKey = findKeyPath(obj[key], targetKey, currentKey);
                if (nestedKey !== null) {
                    return nestedKey;
                }
            }
        }
    }
    return null;
};
export const setValuesHookForm = (setValue, dependancy, obj) => {
    dependancy.forEach((d) => {
        const keys = d.split(".");
        let value = obj;
        keys.forEach((key) => {
            value = value[key];
        });
        setValue(d, value);
    });
};
export const OverwriteObjectDataWithWatchData = (dependancy, watchData, obj) => {
    dependancy.forEach((d) => {
        const keys = d.split(".");
        let currentObject = obj;
        for (let i = 0; i < keys.length - 1; i++) {
            const key = keys[i];
            currentObject = currentObject[key];
        }
        currentObject[keys[keys.length - 1]] = watchData[d];
    });
};
