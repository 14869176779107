import { IDisplayModeDTO } from "../interfaces/IDisplayMode";
import { z } from "zod";
import { idSchema } from "./Id.schema";
import { channelIdSchema } from "./ChannelId.schema";
import { tenantIdSchema } from "./TenantId.schema";
import { createdAtSchema } from "./CreatedAt.schema";
import { updatedAtSchema } from "./UpdatedAt.schema";
import { updatedBySchema } from "./updatedBy.schema";
import { createdBySchema } from "./CreatedBy.schema";
import { ISchemaError } from "../interfaces/ISchemaError/ISchemaError";

export const contactListSchema = z.object({
  id: idSchema,
  channelId: channelIdSchema,
  tenantId: tenantIdSchema,
  defaultDisplayMode: z.nativeEnum(IDisplayModeDTO),
  chargebeeSubscriptionId: z.string().default(""),
  isPremium: z.boolean({ invalid_type_error: ISchemaError.IS_PREMIUM_INVALID_TYPE }),
  createdBy: createdBySchema,
  updatedBy: updatedBySchema,
  isDeleted: z.boolean({ invalid_type_error: ISchemaError.IS_DELETED_INVALID_TYPE }),
  objectType: z.string().default("ContactList"),
  createdAt: createdAtSchema,
  updatedAt: updatedAtSchema,
});

export const contactListSchemaDTO = contactListSchema.omit({ id: true, objectType: true });
