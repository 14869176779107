import { Dropdown, Flex, Text } from "@fluentui/react-northstar";
import { useGroupTabs } from "./GroupTabs.logic";
import { IContact } from "../../interfaces/IContact/IContact";
import { Control, Controller, UseFormWatch } from "react-hook-form";
import PillButton from "../../components/Pill/PillButton";
import "./GroupTabs.scss";
import { useMemo } from "react";

const GroupTabs = (props: { canModify: boolean; contact: IContact | undefined; control: Control<IContact, any>; watch: UseFormWatch<any> }) => {
  const logic = useGroupTabs(props);

  const UI = useMemo(
    () => ({
      addToGroupLabel: props.contact?.name ? logic.t("AddToGroup", { name: props.contact.name }) : logic.t("AddContactToGroup"),
    }),
    [props.contact?.name]
  );
  if (!logic.allGroups.length)
    return (
      <Flex hAlign="center" vAlign="center" style={{ minHeight: "80px" }}>
        <Text content={logic.t("CreateGroupBefore")} />
      </Flex>
    );

  return (
    <Flex column gap="gap.medium" fill className="groupTabs-container" data-show-no-result={logic.canShowNoResult}>
      <Flex vAlign="center" hAlign="start" column gap="gap.small">
        <Text content={UI.addToGroupLabel} weight="semibold" />
        <Flex fill gap="gap.small">
          <Controller
            name={"groupId"}
            control={props.control}
            render={({ field }) => (
              <>
                <Dropdown
                  search
                  disabled={!props.canModify}
                  multiple
                  fluid
                  style={{ width: "100%" }}
                  items={logic.allGroups.map((g, idx) => ({
                    id: g.id,
                    header: g.name,
                    key: idx,
                    content: (
                      <Flex key={g.id} hAlign="start" vAlign="center" gap="gap.small">
                        <Text content={g.name} />
                      </Flex>
                    ),
                  }))}
                  placeholder={logic.t("StartTypingAName")}
                  noResultsMessage={logic.t("NoResult")}
                  onSearchQueryChange={logic.onSearchQueryChange}
                  value={logic.dropdownValue.map((t: any) => {
                    if (typeof t === "string") {
                      return t;
                    } else {
                      return t.header;
                    }
                  })}
                  onChange={(e, data) => {
                    logic.handleDropdown(e, data, field);
                    logic.setDropdownValue(data.value);
                  }}
                />
              </>
            )}
          />
        </Flex>
      </Flex>
      <Flex vAlign="center" hAlign="start" column gap="gap.small">
        <Text content={logic.t("Groups")} weight="semibold" />
        <Flex className="contactDialog-groups-list-container" fill hAlign="start" vAlign="start" gap="gap.small">
          {logic.getAllGroups.map((g) => (
            <Controller
              key={g.id}
              name={"groupId"}
              control={props.control}
              render={({ field }) => <PillButton key={g.id} disabled={!props.canModify} name={g.name} onChange={() => logic.handleRemove(g.id, field)} />}
            />
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default GroupTabs;
