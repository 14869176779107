import { Button, CalendarIcon, CallIcon, ChatIcon, EditIcon, EmailIcon, Flex, GlobeIcon, LinkedInIcon, MenuButton, MoreIcon, Tooltip, TrashCanIcon } from "@fluentui/react-northstar";
import { generateGUID, openModal, setErrorMessage } from "front";
import { IContact } from "../../interfaces/IContact/IContact";
import React from "react";
import { openChat } from "../../utils/openChat";
import { useQuickActionBar } from "./QuickActionBar.logic";
import { hasPhone } from "../../utils/hasPhone";
import { openCalendar } from "../../utils/openCalendar";
import { openCall } from "../../utils/openCall";
import { openMailTo } from "../../utils/contactUtils";
import XIcon from "../../icons/XIcon";
import FacebookIcon from "../../icons/FacebookIcon";
import { QUICK_ACTION_BAR_ICONS_CONTAINER } from "../../test/testId";

const QuickActionBar = (props: { canModify: boolean; contact: IContact | undefined; switchModify: (bool: boolean) => void; displayDeleteContactBanner: () => void }) => {
  const logic = useQuickActionBar();
  const quickActions = [
    {
      name: "chat",
      icon: <ChatIcon />,
      onClick: () => openChat(props.contact?.internet?.email ?? "", logic.userMail as string, logic.dispatchCtx, openModal, setErrorMessage, logic.t),
      disabled: props.canModify || !props.contact?.internet?.email,
      disabledMessage: logic.t("chatTooltip"),
      show: true,
    },
    {
      name: "call",
      icon: <CallIcon />,
      onClick: () => openCall(props.contact?.phones ?? ([] as any)),
      disabled: props.canModify || !hasPhone(props.contact?.phones),
      disabledMessage: logic.t("callTooltip"),
      show: true,
    },
    {
      name: "email",
      icon: <EmailIcon />,
      onClick: () => openMailTo(props.contact?.internet?.email ?? ""),
      disabled: props.canModify || !props.contact?.internet?.email,
      activateMessage: "",
      disabledMessage: logic.t("mailTooltip"),
      show: true,
    },
    {
      name: "calendar",
      icon: <CalendarIcon />,
      onClick: () => openCalendar(props.contact?.internet?.email ?? ""),
      disabled: props.canModify || !props.contact?.internet?.email,
      disabledMessage: logic.t("calendarTooltip"),
      show: true,
    },
    {
      name: "web",
      icon: <GlobeIcon />,
      onClick: () => window.open(props.contact?.internet?.website ?? ""),
      disabled: props.canModify || !props.contact?.internet?.website,
      disabledMessage: logic.t("webTooltip"),
      show: true,
    },
    {
      name: "linkedin",
      icon: <LinkedInIcon />,
      onClick: () => window.open(props.contact?.internet?.linkedin ?? ""),
      disabled: props.canModify || !props.contact?.internet?.linkedin,
      disabledMessage: logic.t("linkedinTooltip"),
      webIcon: true,
      show: true,
    },
    {
      name: "facebook",
      icon: <FacebookIcon />,
      onClick: () => window.open(props.contact?.internet?.facebook ?? ""),
      disabled: props.canModify || !props.contact?.internet?.facebook,
      disabledMessage: logic.t("facebookTooltip"),
      webIcon: true,
      show: true,
    },
    {
      name: "x",
      icon: <XIcon svgColor={logic.isLightTheme ? "black" : "white"} />,
      onClick: () => window.open(props.contact?.internet?.twitter ?? ""),
      disabled: props.canModify || !props.contact?.internet?.twitter,
      disabledMessage: logic.t("xTooltip"),
      webIcon: true,
      show: true,
    },
    {
      name: "edit",
      icon: <EditIcon />,
      onClick: () => props.switchModify(true),
      activeMessage: logic.t("EditContact"),
      disabled: props.canModify || !logic.userPerm.perm.update,
      disabledMessage: logic.t("NoEditPerm"),
      show: !props.canModify,
    },
    {
      name: "delete",
      icon: <TrashCanIcon />,
      onClick: () => props.displayDeleteContactBanner(),
      activeMessage: logic.t("DeletePermanently"),
      disabled: props.canModify || !logic.userPerm.perm.delete,
      disabledMessage: logic.t("NoDeletePerm"),
      show: !props.canModify,
    },
  ];

  return (
    <Flex hAlign="start" vAlign="center" space="between" style={{ padding: ".5rem 0" }}>
      <Flex data-testid={QUICK_ACTION_BAR_ICONS_CONTAINER}>
        {quickActions.map((qa) => (
          <div key={qa.name}>
            {qa.show ? (
              <>
                {qa.disabled ? (
                  <Tooltip
                    key={generateGUID()}
                    content={qa.disabled ? qa.disabledMessage : qa.activateMessage}
                    pointing
                    trigger={<Button icon={qa.icon} iconOnly text onClick={qa.onClick} disabledFocusable={qa.disabled} style={{ filter: "grayscale(100%)" }} data-testid={`quick-action-${qa.name}`} />}
                  />
                ) : (
                  <>
                    {qa.activeMessage ? (
                      <Tooltip
                        key={generateGUID()}
                        content={qa.disabled ? qa.disabledMessage : qa.activeMessage}
                        pointing
                        trigger={
                          <Button icon={qa.icon} iconOnly text onClick={qa.onClick} disabledFocusable={qa.disabled} style={{ filter: "grayscale(100%)" }} data-testid={`quick-action-${qa.name}`} />
                        }
                      />
                    ) : (
                      <Button icon={qa.icon} iconOnly text onClick={qa.onClick} data-testid={`quick-action-${qa.name}`} />
                    )}
                  </>
                )}
              </>
            ) : undefined}
          </div>
        ))}
      </Flex>
    </Flex>
  );
};

export default QuickActionBar;
