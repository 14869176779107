import { ArrowDownIcon, Button, Flex, MenuButton, MoreIcon, Table, Text, Tooltip, UserFriendsIcon } from "@fluentui/react-northstar";
import { IContact } from "../../interfaces/IContact/IContact";
import "./DisplayByList.css";
import { generateGUID } from "../../utils/generateGUID";
import { openModal, setModalWithData, usePermissionsSelector, useTranslate } from "front";
import { translations } from "../../translations";
import { useDispatch, useSelector } from "react-redux";
import { IAppDispatch, ReduxStoreState } from "redux/store";
import { setUserToDelete } from "../../redux/reducer/ContactListReducer";
import { IDialogs } from "../../interfaces/IDialog/IDialogs";
import Pagination from "../../components/Pagination/Pagination";
import { setPagination } from "../../redux/reducer/PaginationReducer";
import { useUsersCache } from "../../hooks/useQuery/useUsersCache";
import React, { useState } from "react";
import { generateUniqueId } from "../../utils/generateUniqueId";
import ContactButtons from "../../components/ContactButtons/ContactButtons";
import { listSort } from "../../utils/listSort";

const DisplayByList = (props: { users: IContact[] }) => {
  const t = useTranslate(translations);
  const pagination = useSelector((s: ReduxStoreState) => s.pagination);
  const dispatchCtx = useDispatch<IAppDispatch>();
  const { queryUsers } = useUsersCache();
  const { userPerm } = usePermissionsSelector("userPerm");

  const [filterName, setFilterName] = useState<keyof IContact | "email" | "">();

  const handleFilterName = (key: string) => {
    if (key === filterName) return setFilterName("");
    setFilterName(key as any);
  };

  const sortedUsers = (): IContact[] => {
    let sorted: IContact[] = [...props.users];

    if (filterName) {
      sorted.sort((a, b) => {
        let valueA = "";
        let valueB = "";

        if (filterName === "email") {
          valueA = a.internet?.email || "";
          valueB = b.internet?.email || "";
        } else {
          if (filterName === "name") {
            valueA = (b[filterName] as unknown as string) || "";
            valueB = (a[filterName] as unknown as string) || "";
          } else {
            valueA = (a[filterName] as unknown as string) || "";
            valueB = (b[filterName] as unknown as string) || "";
          }
        }
        return listSort(valueA, valueB);
      });
    }

    return sorted;
  };

  const openDeleteContactDialog = (contactId: string) => {
    const contact = props.users.find((c) => c.id === contactId);
    if (!contact) return;
    dispatchCtx(openModal(IDialogs.DELETE_CONTACT));
    dispatchCtx(setUserToDelete({ contact, isLonely: queryUsers.data?.contacts.length === 1 }));
  };

  const editContact = (contactId: string, editMode: boolean) => {
    dispatchCtx(setModalWithData({ isOpen: IDialogs.CONTACT, data: { edit: editMode, contact: { id: contactId } } }));
  };

  const getMenu = (contactId: string) => {
    const menu = [
      {
        key: generateGUID(),
        disabled: !userPerm.perm.update,
        onClick: () => editContact(contactId, true),
        content: (
          <>
            {userPerm.perm.update ? (
              <Flex vAlign="center" hAlign="start" gap="gap.small">
                <Text content={t("Edit")} />
              </Flex>
            ) : (
              <Tooltip
                content={t("NoEditPerm")}
                pointing
                trigger={
                  <Flex vAlign="center" hAlign="start" gap="gap.small">
                    <Text content={t("Edit")} />
                  </Flex>
                }
              />
            )}
          </>
        ),
      },
      {
        key: generateGUID(),
        disabled: !userPerm.perm.delete,
        onClick: () => openDeleteContactDialog(contactId),
        content: (
          <>
            {userPerm.perm.delete ? (
              <Flex vAlign="center" hAlign="start" gap="gap.small">
                <Text content={t("Delete")} />
              </Flex>
            ) : (
              <Tooltip
                content={t("NoDeletePerm")}
                pointing
                trigger={
                  <Flex vAlign="center" hAlign="start" gap="gap.small">
                    <Text content={t("Delete")} />
                  </Flex>
                }
              />
            )}
          </>
        ),
      },
    ];

    return menu;
  };

  const fields = [
    { key: "icon", className: "max-content cursor-pointer" },
    { key: "name", className: "user-list-cell cursor-pointer" },
    { key: "jobTitle", className: "user-list-cell disable-on-phone cursor-pointer" },
    { key: "company", className: "user-list-cell cursor-pointer" },
    { key: "department", className: "user-list-cell disable-on-phone cursor-pointer" },
    { key: "businessPhone", className: "user-list-cell disable-on-phone cursor-pointer" },
    { key: "mobile", className: "user-list-cell disable-on-phone cursor-pointer" },
    { key: "email", className: "user-list-cell disable-on-phone cursor-pointer" },
    { key: "more", className: "cursor-pointer" },
  ];

  const shouldDisplayHeader = (label: string) => {
    if (["icon", "more"].includes(label)) return "";
    return t(label);
  };

  const setItem = (field: any, from: string, disabled: boolean, value?: any) => {
    return {
      content: value,
      key: field.key,
      className: field.className,
      truncateContent: true,
      style: disabled ? { opacity: 0.5, pointerEvents: "none", cursor: "not-allowed" } : { cursor: "pointer" },
    };
  };

  const header = () => {
    return {
      key: generateUniqueId(),
      items: fields.map((f) => {
        if (f.key === "icon" || f.key === "businessPhone" || f.key === "cellphone" || f.key === "more") return setItem(f, "header", shouldDisplayHeader(f.key));
        return setItem(
          f,
          "header",
          false,
          <Flex vAlign="center" hAlign="center" onClick={() => handleFilterName(f.key)}>
            <Text content={shouldDisplayHeader(f.key)} />
            <ArrowDownIcon size="small" style={{ marginLeft: ".5rem" }} styles={{ transform: filterName === f.key ? "rotate(180deg)" : "none" }} />
          </Flex>
        );
      }),
    };
  };

  const userInfos = sortedUsers().map((u) => {
    const values = {
      onClick: u.isDisabled ? undefined : () => editContact(u.id, false),
      key: generateUniqueId(),
      items: fields.map((f, i) => {
        if (f.key == "icon") return setItem(f, "body", !!u.isDisabled, <UserFriendsIcon />);
        if (f.key == "more")
          return setItem(
            f,
            "body",
            !!u.isDisabled,
            <Flex onClick={(e: any) => e.stopPropagation()} vAlign="center" hAlign="center">
              <ContactButtons userMail={u.internet?.email ?? ""} phones={u.phones} responsive />
              <MenuButton title="More" trigger={<Button icon={<MoreIcon />} iconOnly text />} menu={getMenu(u.id)} on="click" />
            </Flex>
          );
        if (f.key === "businessPhone") return setItem(f, "body", !!u.isDisabled, u.phones?.businessPhone ?? "");
        if (f.key === "mobile") return setItem(f, "body", !!u.isDisabled, u.phones?.mobile ?? "");
        if (f.key === "email") return setItem(f, "body", !!u.isDisabled, u.internet?.email ?? "");

        return setItem(f, "body", !!u.isDisabled, u[f.key as keyof IContact]);
      }),
    };
    return values;
  });

  const changePage = (page: number) => {
    const skip = (page - 1) * pagination.take;
    dispatchCtx(setPagination({ ...pagination, skip, currentPage: page }));
  };

  return (
    <Flex gap="gap.medium" column fill>
      <Pagination page={pagination.currentPage} take={pagination.take} total={queryUsers.data?.total ?? 0} changePage={changePage} />
      <div style={{ width: "100%" }}>
        <Table
          data-testid={"displayByList-user-container"}
          style={{ width: "100%" }}
          variables={{
            cellContentOverflow: "none",
          }}
          header={header()}
          rows={userInfos}
          aria-label="Static table"
        />
      </div>

      <Pagination page={pagination.currentPage} take={pagination.take} total={queryUsers.data?.total ?? 0} changePage={changePage} />
    </Flex>
  );
};

export default DisplayByList;
