import { WidgetSettingsForm } from "@witivio_teamspro/witivio-react-toolkit";
import { checkContactList, getContactList } from "../../apis/actions/contactListAction";
import { IContactList } from "../../interfaces/IContact/IContactList";
import React from "react";

export const WidgetConfiguration = () => {
  let contactList: IContactList | undefined = undefined;

  const checkContactListIdExists = async (contactListId: string): Promise<boolean> => {
    const isExisting = await checkContactList(contactListId);
    if (isExisting) {
      contactList = await getContactList(contactListId);
    }
    return isExisting;
  };

  const getTitle = (): string => "Contact Pro";
  const getChannelId = (): string => contactList?.channelId ?? "";

  return (
    <WidgetSettingsForm
      appName={"Contacts Pro"}
      checkExists={checkContactListIdExists}
      manifestId={process.env.REACT_APP_ManifestID ?? ""}
      getChannelId={getChannelId}
      getWidgetTitle={getTitle}
    />
  );
};
