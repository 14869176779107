import { setModalWithData, useModalSelector, useMsTeamsSelector, useTranslate } from "front";
import { useGroupsCache } from "../../../../hooks/useQuery/useGroupsCache";
import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { IAppDispatch } from "../../../../redux/store";
import { translations } from "../../../../translations";

export const useDeleteGroup = () => {
  const t = useTranslate(translations);
  const { data } = useModalSelector("data");
  const { locale, tenantId } = useMsTeamsSelector("locale", "tenantId");
  const dispatchCtx = useDispatch<IAppDispatch>();

  const { queryGroups, mutateDeleteGroup } = useGroupsCache();

  const handleDelete = useCallback(async () => {
    const { contactListId, id } = data;
    await mutateDeleteGroup.mutateAsync({ tenantId, contactListId, id });
    handleClose();
  }, []);

  const getGroup = useMemo(() => {
    if (!queryGroups.data) return;
    return queryGroups.data.find((g) => g.id === data.id);
  }, [queryGroups.data]);

  const handleClose = useCallback(() => dispatchCtx(setModalWithData({ isOpen: undefined, data: undefined })), []);

  return { t, handleDelete, handleClose, isLoading: mutateDeleteGroup.isLoading, name: getGroup?.name ?? "", locale };
};
