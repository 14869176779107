import { setModalWithData, translations, usePermissionsSelector, useTranslate } from "front";
import { IDialogs } from "../../interfaces/IDialog/IDialogs";
import { IGroup } from "../../interfaces/IGroup/IGroup";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { IAppDispatch } from "../../redux/store";

export const useGroup = (props: IGroup) => {
  const dispatchCtx = useDispatch<IAppDispatch>();
  const { userPerm } = usePermissionsSelector("userPerm");

  const t = useTranslate(translations);

  const handleEdit = useCallback(() => {
    dispatchCtx(setModalWithData({ isOpen: IDialogs.GROUP, data: { isEditing: true, group: props } }));
  }, []);

  const handleDelete = useCallback(() => {
    const { contactListId, id } = props;
    dispatchCtx(setModalWithData({ isOpen: IDialogs.DELETE_GROUP, data: { contactListId, id } }));
  }, []);

  return {
    handleEdit,
    handleDelete,
    userPerm,
    t,
  };
};
