import { openModal, setModalWithData, useAppConfigurationSelector, useMsTeamsSelector, usePermissionsSelector, useTranslate } from "front";
import { useGroupsCache } from "../../hooks/useQuery/useGroupsCache";
import { IBehavior } from "../../interfaces/IBehavior";
import { IDialogs } from "../../interfaces/IDialog/IDialogs";
import { IGroup } from "../../interfaces/IGroup/IGroup";
import { useDispatch } from "react-redux";
import { setGroupReducerState } from "../../redux/reducer/GroupReducer";
import { IAppDispatch } from "../../redux/store";
import { useCallback, useMemo, useRef, useState } from "react";
import { translations } from "../../translations";

export const useDisplayByGroup = () => {
  const t = useTranslate(translations);
  const dispatchCtx = useDispatch<IAppDispatch>();
  const { appId } = useAppConfigurationSelector("appId");
  const { isOnMobile } = useMsTeamsSelector("isOnMobile");
  const { queryGroups } = useGroupsCache();
  const dispatch = useDispatch<IAppDispatch>();
  const [isShow, setIsShow] = useState<string[]>([]);
  const concernedGroup = useRef<string>();
  const { userPerm } = usePermissionsSelector("userPerm");

  const handleModifyGroup = (data: IGroup, type: IBehavior) => {
    dispatch(setGroupReducerState({ state: type, data }));
    dispatch(openModal(IDialogs.GROUP));
  };

  const handleAccordionClick = (groupId: string) => {
    concernedGroup.current = groupId;
    const isHere = isShow.includes(groupId);
    if (isHere) {
      setIsShow((prev) => prev.filter((p) => p !== groupId));
    } else {
      setIsShow((prev) => [...prev, groupId]);
    }
  };

  const handleEdit = useCallback(() => {
    dispatchCtx(setModalWithData({ isOpen: IDialogs.GROUP, data: { isEditing: true, group: { id: concernedGroup.current } } }));
  }, []);

  const handleDelete = useCallback(() => {
    dispatchCtx(setModalWithData({ isOpen: IDialogs.DELETE_GROUP, data: { contactListId: appId, id: concernedGroup.current } }));
  }, []);

  const handleCopy = useCallback(() => {
    dispatchCtx(setModalWithData({ isOpen: IDialogs.COPY_GROUP, data: { id: concernedGroup.current } }));
  }, []);

  const cancelPropagation = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, groupId: string) => {
    e.stopPropagation();
    concernedGroup.current = groupId;
  };

  const sortedGroups = useMemo(() => {
    return [...queryGroups.data].sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
  }, [queryGroups.data]);

  const openNewGroupDialog = useCallback(() => dispatch(setModalWithData({ isOpen: IDialogs.GROUP, data: { isEditing: false, group: undefined } })), []);

  return { data: sortedGroups, handleModifyGroup, handleAccordionClick, isShow, handleEdit, handleDelete, handleCopy, cancelPropagation, t, openNewGroupDialog, isOnMobile, userPerm };
};
