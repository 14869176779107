import React, { useMemo, useRef } from "react";
import { useGroup } from "./Group.logic";
import { Avatar, Button, CloseIcon, Dialog, Dropdown, Flex, Input, Pill, SearchIcon, Text } from "@fluentui/react-northstar";
import { Controller } from "react-hook-form";
import PillButton from "../../../../components/Pill/PillButton";
import "./Group.scss";
import UserSelected from "../../../../components/UserSelected/UserSelected";
import DialogBanner from "../../../../components/DialogBanner/DialogBanner";

const Group = () => {
  const logic = useGroup();

  const errorLabel = useMemo(
    () => ({
      required: logic.t("nameError"),
      validate: logic.t("GroupAlreadyExist"),
      maxLength: logic.t("MaxLengthError"),
    }),
    []
  );

  const UI = useMemo(
    () => ({
      title: logic.isEditing ? logic.t("EditGroup") : logic.t("CreateGroup"),
      submit: logic.isEditing ? logic.t("Save") : logic.t("Create"),
    }),
    [logic.isEditing]
  );

  const nameInput = useMemo(
    () => (
      <Controller
        name={"name"}
        control={logic.control}
        rules={{
          required: true,
          maxLength: 50,
          validate: logic.handleInputValidation,
        }}
        render={({ field }) => (
          <>
            <Input label={logic.t("GroupName")} error={!!logic.errors.name} fluid {...field} style={{ marginBottom: logic.errors.name ? ".2rem" : "1rem" }} />
            {logic.errors.name && <Text content={errorLabel[logic.errors.name.type as "required" | "validate" | "maxLength"]} error style={{ margin: "0 0 1rem 0" }} />}
          </>
        )}
      />
    ),
    [logic.errors.name, logic.groups]
  );

  const groupMemberInput = () => (
  <Flex column id="groupMemberInput-container" data-contact-is-not-empty={logic.searchedContacts.length > 0}>
    <Text content={logic.t("AddGroupMember")} style={{ marginBottom: ".25rem" }} />

    <Controller
      name={"participants"}
      control={logic.control}
      render={({ field }) => (
        <>
          <Dropdown
            search
            multiple
            fluid
            style={{ width: "100%" }}
            items={logic.handleDropdownItems()}
            placeholder={logic.t("StartTypingAName")}
            noResultsMessage={logic.t("NoContactFound")}
            onSearchQueryChange={logic.handleSearch}
            value={logic.dropdownValue.map((t: any) => {
              if (typeof t === "string") {
                return t;
              } else {
                return t.header;
              }
            })}
            onChange={(e, data) => {
              logic.handleDropdown(e, data, field);
            }}
          />

          <Flex column gap="gap.smaller" style={{ marginTop: "1rem", maxHeight: "100px", overflowY: "scroll" }}>
            {logic.currentSelectedParticipants.map((p) => (
              <UserSelected key={p.id} avatar={p.picture} name={p.name} email={p.internet?.email} handleRemove={() => logic.handleRemove(p.id, field)} />
            ))}
          </Flex>
        </>
      )}
    />
  </Flex>
);

  const displayAllMembers = () => (
    <Flex column>
      <Flex fill hAlign="center" vAlign="center" space="between" gap="gap.small">
        <Text content={logic.t("GroupMember")} />
        <Input placeholder={logic.t("searchContact")} icon={<SearchIcon />} iconPosition="start" onChange={logic.handleInput} />
      </Flex>
      <Flex className="groups-list-container" fill hAlign="start" vAlign="start" gap="gap.small" wrap style={{ maxHeight: 100, overflowY: "scroll" }}>
        {logic.participants?.map((p) => (
          <Controller
            key={p.id}
            name={"participants"}
            control={logic.control}
            render={({ field }) => <PillButton key={p.id} disabled={false} name={p.name} icon={{ show: true, picture: p.picture }} onChange={() => logic.handleRemove(p.id, field)} />}
          />
        ))}
      </Flex>
    </Flex>
  );

  const renderContent = (
    <Flex column gap="gap.large" style={{ marginTop: "1rem" }}>
      {nameInput}
      {groupMemberInput()}
      {displayAllMembers()}
      <DialogBanner
        show={logic.bannerIsOpen}
        txt={logic.t("ConfirmCancelForm")}
        confirmLabel={logic.t("ContinueEditing")}
        cancelLabel={logic.t("Reset")}
        confirmAction={logic.closeBanner}
        cancelAction={logic.handleClose}
      />
    </Flex>
  );

  const renderFooter = useMemo(
    () => (
      <>
        <Flex hAlign="end" gap="gap.small">
          <Button disabled={logic.isLoading} content={logic.t("Close")} onClick={logic.openBanner} />
          <Button disabled={logic.isLoading || !logic.isValid || !logic.isDirty} primary content={UI.submit} onClick={logic.handleSubmit(logic.onSubmit)} />
        </Flex>
      </>
    ),
    [logic.isLoading, logic.isDirty, logic.isValid]
  );

  return (
    <Dialog
      header={UI.title}
      headerAction={{ icon: <CloseIcon />, title: logic.t("Close"), onClick: logic.openBanner }}
      content={renderContent}
      footer={renderFooter}
      defaultOpen={true}
      onCancel={logic.openBanner}
      closeOnOutsideClick={false}
    />
  );
};

export default Group;
