import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IBehavior } from "interfaces/IBehavior";
import { IGroup } from "interfaces/IGroup/IGroup";

const initialState: {
  state: IBehavior | undefined;
  data: IGroup | undefined;
} = {
  state: undefined,
  data: undefined,
};

const groupSlice = createSlice({
  name: "group",
  initialState: initialState,
  reducers: {
    setGroupReducerState(state, action: PayloadAction<typeof initialState>) {
      Object.assign(state, action.payload);
    },
  },
});

export const { setGroupReducerState } = groupSlice.actions;
export default groupSlice.reducer;
