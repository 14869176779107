import { cloneElement, isValidElement } from "react";
const deepClone = (source) => {
    if (source === null || typeof source !== 'object')
        return source;
    if (isValidElement(source))
        return cloneElement(source);
    if (Array.isArray(source))
        return source.map((item) => deepClone(item));
    const clonedObj = {};
    for (const key in source) {
        if (source.hasOwnProperty(key))
            clonedObj[key] = deepClone(source[key]);
    }
    return clonedObj;
};
export const ObjectModule = {
    deepClone
};
