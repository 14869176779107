import { usePermissionsSelector, usePremiumSelector } from "front";
import { useUsersCache } from "../../hooks/useQuery/useUsersCache";
import { useEffect, useMemo, useState } from "react";
import { useLimitationSelector } from "../../redux/reducer/LimitationReducer";

export const useContactPermissions = () => {
  // Cache
  const { queryUsers } = useUsersCache();

  // Selector
  const { userPerm } = usePermissionsSelector("userPerm");
  const { maxContact } = useLimitationSelector("maxContact");

  // States
  const [limitIsReached, setLimitIsReached] = useState<boolean>(false);

  useEffect(() => {
    if (!queryUsers.data) return undefined;
    setLimitIsReached(queryUsers.data.total >= maxContact);
  }, [queryUsers]);

  const canAddContact = useMemo(() => !limitIsReached && userPerm.perm.add, [limitIsReached, userPerm.perm.add]);

  return { canAddContact };
};
