import { useMsTeamsSelector, usePermissionsSelector, useTranslate } from "front";
import { useDispatch } from "react-redux";
import { IAppDispatch } from "../../redux/store";
import { translations } from "../../translations";

export const useQuickActionBar = () => {
  const t = useTranslate(translations);
  const { userMail, isLightTheme } = useMsTeamsSelector("userMail", "isLightTheme");
  const { userPerm } = usePermissionsSelector("userPerm");
  const dispatchCtx = useDispatch<IAppDispatch>();
  return { t, userMail, dispatchCtx, isLightTheme, userPerm };
};
