import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useConfigurationSelector } from "../redux/reducers/ConfigurationReducer/ConfigurationReducer";
import { useModalSelector } from "../redux/reducers/ModalReducer/ModalReducer";
import { usePremiumSelector } from "../redux/reducers/PremiumReducer/PremiumReducer";
import { useAppConfigurationSelector } from "../redux/reducers/AppConfigurationReducer/AppConfigurationReducer";
import { useMsTeamsSelector } from "../redux/reducers/MicrosoftTeamsReducer/MicrosoftTeamsReducer";
import { getBrowserName } from "../utils/getBrowserName";
import { AxiosConfig } from "../apis/AxiosConfig/AxiosConfig";
import { useSelector } from "react-redux";
import { useErrorHandling } from "./useErrorHandling/useErrorHandling";
export const useTrack = () => {
    const location = useLocation();
    const { tryCatchAsync, _hasError } = useErrorHandling();
    // Refs
    const values = useRef({
        browser: "",
    });
    const trackRef = useRef();
    const config = useConfigurationSelector("data");
    const dialogs = useModalSelector("isOpen");
    const notifications = useSelector((s) => s.notifications);
    const premium = usePremiumSelector("isPremium", "isPlatinum", "subscriptionLicenceId", "isSubscriptionOwner");
    const app = useAppConfigurationSelector("appOwner", "appId");
    const teamsContext = useMsTeamsSelector("locale", "teamName", "channelName", "tenantId", "tabName", "userMail", "userId", "userName", "sku", "isInPersonalApp");
    useEffect(() => {
        values.current.browser = getBrowserName();
    }, []);
    useEffect(() => {
        buildParams();
    }, [config, dialogs, premium, app, teamsContext, location]);
    const buildParams = () => {
        const params = {
            locale: teamsContext.locale,
            teamName: encodeURI(teamsContext.teamName),
            channelName: encodeURI(teamsContext.channelName),
            tenantId: teamsContext.tenantId,
            tabName: encodeURI(teamsContext.tabName),
            userMail: encodeURI(teamsContext.userMail),
            userId: teamsContext.userId,
            ...app,
            ...premium,
            isDiamond: false,
            browser: encodeURI(values.current.browser),
            notificationsUpdate: !!notifications?.update,
            notificationsDelete: !!notifications?.delete,
            currentModal: encodeURI(dialogs.isOpen),
            appVersion: config.data.apiVersion,
            adminCenterAppId: config.data.adminCenterAppId,
            currentView: encodeURI(location.pathname),
        };
        trackRef.current = params;
    };
    const errorTrack = (err, instance) => {
        if (!instance)
            return console.error("Not able to throw error on App Insight");
        const message = { ...trackRef.current, error: err };
        instance.trackException({ exception: { name: "Tracking Error", message } });
    };
    const trackClient = async (name, data, options) => {
        const trackedItems = { ...trackRef.current, name, data: JSON.stringify(data) };
        try {
            if (options.SQL.required) {
                AxiosConfig.updateHeadersToTrack(trackedItems);
                await options.SQL.apiCall();
            }
            if (options.appInsight.required) {
                options.appInsight.instance.trackEvent({ name, properties: trackedItems });
            }
        }
        catch (error) {
            errorTrack(error, options.appInsight.instance);
        }
    };
    // This function is called before axios call
    const trackServer = (name, data) => {
        const trackedItems = { ...trackRef.current, name, data: JSON.stringify(data) };
        AxiosConfig.updateHeadersToTrack(trackedItems);
    };
    /**
     * @deprecated since version 1.0
     */
    function trackItAsync(name, callback) {
        return async (...args) => {
            try {
                trackServer(name, args);
                const { state, error, result } = await tryCatchAsync(callback)(...args);
                if (!state)
                    throw error;
                return result;
            }
            catch (error) {
                throw error;
            }
        };
    }
    function trackItAsync2(name, callback) {
        return async (...args) => {
            try {
                trackServer(name, args);
                const res = await tryCatchAsync(callback)(...args);
                if (res.error || !res.state)
                    throw res.error;
                return res;
            }
            catch (error) {
                throw error;
            }
        };
    }
    return { trackClient, trackServer, trackItAsync, trackItAsync2 };
};
