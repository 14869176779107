import { Flex, Text } from "@fluentui/react-northstar";

import { useMsTeamsSelector, useTranslate } from "front";
import { translations } from "../../translations";
import { useLogic } from "./Remove.logic";

export const RemoveContactList = () => {
  const { isOnMobile } = useMsTeamsSelector("isOnMobile");
  const t = useTranslate(translations);
  useLogic();
  return (
    <div
      id="configuration"
      style={{
        height: window.innerHeight,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Flex fill column styles={{ padding: isOnMobile ? "15px" : "0" }}>
        <Text weight="bold" content={t("RemoveTitle")} />
        <Flex padding="padding.medium">
          <Text content={t("RemoveItem1")} />
        </Flex>
        <Flex padding="padding.medium">
          <Text content={t("RemoveItem2")} />
        </Flex>
      </Flex>
    </div>
  );
};
