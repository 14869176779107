import AlphabetPicker from "../../components/AlphabetPicker/AlphabetPicker";
import { useContactList } from "./ContactList.logic";
import { Button, Flex, ParticipantAddIcon, Text } from "@fluentui/react-northstar";
import React, { memo } from "react";
import FlexWrapper from "../../components/FlexWrapper/FlexWrapper";
import { primaryStyle } from "../../utils/primaryStyle";
import "./ContactList.scss";

const ContactList = memo(() => {
  const logic = useContactList();

  return (
    <>
      <div>{logic.hasData && <AlphabetPicker />}</div>
      <Flex fill styles={{ padding: "0 .5rem" }}>
        {logic.hasData ? (
          <div className="contactList-users-container" data-testid="contactList-users-container">
            {logic.displayUsers}
          </div>
        ) : (
          <FlexWrapper>
            {logic.searchPattern ? (
              <Text content={logic.t("NoContactFound")} weight="semibold" align="center" styles={{ paddingLeft: "2rem" }} />
            ) : (
              <>
                <Text content={logic.t("NoContact")} weight="semibold" align="center" styles={{ paddingLeft: "2rem" }} />
                <Button
                  data-testid="contactList-add-contact"
                  primary
                  style={primaryStyle}
                  content={logic.t("AddContact")}
                  icon={<ParticipantAddIcon />}
                  onClick={logic.createContact}
                  styles={{ marginLeft: "2rem" }}
                  disabled={!logic.userPerm.perm.add}
                />
              </>
            )}
          </FlexWrapper>
        )}
      </Flex>
    </>
  );
});

export default ContactList;
