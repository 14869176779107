import React from "react";
import { QUERY_KEY_USERS } from "../../const/queryKey";
import { useUsersCache } from "../../hooks/useQuery/useUsersCache";
import { queryClient } from "../../index";
import { useDispatch, useSelector } from "react-redux";
import { setPagination } from "../../redux/reducer/PaginationReducer";
import { IAppDispatch, ReduxStoreState } from "../../redux/store";
import { useMsTeamsSelector } from "front";

export const useAlphabetPicker = () => {
  // Services
  const pagination = useSelector((s: ReduxStoreState) => s.pagination);
  const dispatch = useDispatch<IAppDispatch>();
  const appId = useSelector((s: ReduxStoreState) => s.appConfiguration.appId);
  const { queryUsers } = useUsersCache();

  const handleFilter = (letter: string) => {
    const firstLetter = letter === pagination.firstLetter ? "" : letter;
    dispatch(setPagination({ ...pagination, skip: 0, currentPage: 1, firstLetter }));
    queryClient.invalidateQueries([QUERY_KEY_USERS, appId]);
  };

  const shouldBeDisabled = (letter: string): boolean => {
    return !queryUsers.data?.contacts.some((u) => u.name[0]?.toUpperCase() === letter);
  };

  return { handleFilter, shouldBeDisabled, letter: pagination.firstLetter };
};
