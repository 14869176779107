import React from "react";
import { Flex } from "@fluentui/react-northstar";
import Pagination from "../../components/Pagination/Pagination";
import UserCard from "../../components/UserCard/UserCard";
import { IContact } from "../../interfaces/IContact/IContact";
import { useDisplayByCard } from "./DisplayByCard.logic";

const DisplayByCard = (props: { users: IContact[] }) => {
  const logic = useDisplayByCard();
  return (
    <Flex gap="gap.medium" column>
      <Pagination page={logic.pagination.currentPage} take={logic.pagination.take} total={logic.total ?? 0} changePage={logic.changePage} />
      <Flex fill hAlign="start" vAlign="center" wrap={true} styles={{ height: "max-content", gap: "1rem" }} data-testid="displayByCard-user-container">
        {props.users.map((item, i) => (
          <UserCard key={item.id} {...item} idx={i} />
        ))}
      </Flex>
      <Pagination page={logic.pagination.currentPage} take={logic.pagination.take} total={logic.total ?? 0} changePage={logic.changePage} />
    </Flex>
  );
};

export default DisplayByCard;
