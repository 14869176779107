import { setModalWithData, useTranslate } from "front";
import { useParticipantsCache } from "../../hooks/useQuery/useParticipantsCache";
import { IDialogs } from "../../interfaces/IDialog/IDialogs";
import { IGroup } from "../../interfaces/IGroup/IGroup";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { IAppDispatch } from "../../redux/store";
import { translations } from "../../translations";

export const useRenderPanel = (props: { group: IGroup }) => {
  const { queryParticipants } = useParticipantsCache(props.group.id);
  const dispatchCtx = useDispatch<IAppDispatch>();
  const t = useTranslate(translations);

  const handleRemoveFromGroup = (id: string) => {
    dispatchCtx(setModalWithData({ isOpen: IDialogs.REMOVE_FROM_GROUP, data: { contactId: id, groupId: props.group.id } }));
  };

  const sortedGroups = useMemo(() => {
    if (!queryParticipants.data) return [];
    return [...queryParticipants.data].sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
  }, [queryParticipants.data]);

  return {
    participants: sortedGroups,
    isLoading: queryParticipants.isLoading,
    handleRemoveFromGroup,
    t,
  };
};
