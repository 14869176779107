import { AxiosConfig, guidSchema, ReturnValue, tryCatch } from "front";
import { IContact } from "interfaces/IContact/IContact";
import { IContactSummary } from "interfaces/IContact/IContactSummary";
import { ISearchQuery } from "interfaces/IContact/ISearchQuery";
import { z } from "zod";
import { contactSchema } from "../../schemas/Contact.schema";

const _createContact = z
  .function()
  .args(z.object({ contactListId: guidSchema, contact: contactSchema.omit({ id: true, createdAt: true, updatedAt: true }) }))
  .returns(
    z.promise(
      z.object({
        state: z.boolean(),
        error: z.string().optional(),
        variables: z.object({ contactListId: guidSchema, contact: contactSchema.omit({ id: true, createdAt: true, updatedAt: true }) }),
        result: contactSchema,
      })
    )
  )
  .implement(async (data) => {
    const req = AxiosConfig.instance.post(`contact-lists/${data.contactListId}/contacts`, data.contact);
    AxiosConfig.updateHeadersToTrack("");
    const res = await req;
    return { state: true, variables: data, result: res.data };
  });

const editContactAction = async (data: { contactListId: string; contactId: string; contact: IContact }): Promise<ReturnValue<void>> => {
  const req = AxiosConfig.instance.put(`contact-lists/${data.contactListId}/contacts/${data.contactId}`, data.contact);
  AxiosConfig.updateHeadersToTrack("");
  await req;
  return { state: true, variables: data };
};

const deleteContactAction = async (data: { id: string; isLonely: boolean }): Promise<ReturnValue<void>> => {
  const req = AxiosConfig.instance.delete(`contact-lists/${data.id}`);
  AxiosConfig.updateHeadersToTrack("");
  await req;
  return { state: true, variables: data };
};

const importContactAction = async (data: FormData): Promise<ReturnValue<void>> => {
  const req = AxiosConfig.instance.post(`contacts/imports`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
  AxiosConfig.updateHeadersToTrack("");
  await req;
  return { state: true, variables: data };
};

const exportContactAction = async (data: { contactListId: string; email: string; tenantId: string; groupId: string; channelId: string; contactListName: string }): Promise<ReturnValue<void>> => {
  const req = AxiosConfig.instance.post(`contacts/exports`, data);
  AxiosConfig.updateHeadersToTrack("");
  await req;
  return { state: true, variables: data };
};

const getContactImportTemplateAction = async (): Promise<ReturnValue<any>> => {
  const req = AxiosConfig.instance.get(`contacts/templates`, { responseType: "blob" });
  AxiosConfig.updateHeadersToTrack("");
  const res = await req;
  return { state: true, result: res.data };
};

const getAllContactsAction = async ({ queryKey }: { queryKey: any[] }): Promise<ReturnValue<IContact[]>> => {
  const contactListId = queryKey[1];
  const { skip, take, searchPattern, firstLetter } = queryKey[2];
  const tenantId = queryKey[3];
  const query = `skip=${skip}&take=${take}&searchPattern=${searchPattern}&firstLetter=${firstLetter}`;
  const req = AxiosConfig.instance.get(`contact-lists/${contactListId}/contacts/tenantId/${tenantId}/search?${query}`);
  AxiosConfig.updateHeadersToTrack("");
  const res = await req;
  return { state: true, variables: queryKey, result: res.data };
};

export const searchContact = async (contactListId: string, searchQuery: ISearchQuery, modeGroup: boolean = false, tenantId: string): Promise<ReturnValue<IContactSummary>> => {
  let skipTake = `Skip=${searchQuery.skip}&Take=${searchQuery.take}`;
  let groupId = `GroupId=${searchQuery.groupId}`;
  let mode = `ModeGroup=${modeGroup}`;

  let search = `firstLetter=${searchQuery.firstLetter}&searchPattern=${searchQuery.searchPattern}&${skipTake}&${groupId}&${mode}`;
  const req = AxiosConfig.instance.get(`contact-lists/${contactListId}/contacts/tenantId/${tenantId}/search?${search}`);
  AxiosConfig.updateHeadersToTrack("");
  const res = await req;
  return { state: true, variables: { contactListId, searchQuery, modeGroup, tenantId }, result: res.data };
};

export const getContact = async (contactListId: string, contactId: string): Promise<ReturnValue<IContact>> => {
  const req = AxiosConfig.instance.get(`contact-lists/${contactListId}/contacts/${contactId}`);
  AxiosConfig.updateHeadersToTrack("");
  const res = await req;
  return { state: true, variables: { contactListId, contactId }, result: res.data };
};

const searchAllContactsAction = async (params: { contactListId: string; search: string }): Promise<ReturnValue<any>> => {
  const req = AxiosConfig.instance.get(`contact-lists/${params.contactListId}/contacts/search/${params.search}`);
  AxiosConfig.updateHeadersToTrack("");
  const res = await req;
  return { state: true, variables: params, result: res.data };
};

export const createContact = async (data: { contactListId: string; contact: Omit<IContact, "id" | "createdAt" | "updatedAt"> }): Promise<ReturnValue<IContact>> => await _createContact(data);
export const editContact = tryCatch(editContactAction);
export const deleteContact = tryCatch(deleteContactAction);
export const importContact = tryCatch(importContactAction);
export const exportContact = tryCatch(exportContactAction);
export const getContactImportTemplate = tryCatch(getContactImportTemplateAction);
export const getAllContacts = tryCatch(getAllContactsAction);
export const searchAllContacts = tryCatch(searchAllContactsAction);
