import { jsx as _jsx } from "react/jsx-runtime";
import { Dialog, Flex, Text } from "@fluentui/react-northstar";
import { useCancelLogic } from "./CancelDialog.logic";
import { useMsTeamsSelector } from "../../../redux/reducers/MicrosoftTeamsReducer/MicrosoftTeamsReducer";
import { translations } from "../../../translations";
import { useTranslate } from "../../../hook/useTranslate";
export const CancelDialog = (props) => {
    const { isOnMobile } = useMsTeamsSelector("isOnMobile");
    const cancelLogic = useCancelLogic(props);
    const t = useTranslate(translations);
    const renderCancel = () => {
        return (_jsx(Flex, { fill: true, column: true, gap: "gap.medium", wrap: true, styles: {
                marginTop: "10px",
                overflowY: "scroll",
                overflowX: "hidden",
            }, children: _jsx(Text, { content: t("CancelTagLine") }) }));
    };
    return (_jsx(Dialog, { styles: { width: isOnMobile ? "95vw" : "500px" }, defaultOpen: true, closeOnOutsideClick: true, header: _jsx(Flex, { gap: "gap.small", vAlign: "center", children: _jsx(Text, { content: t("CancelModifications") }) }), confirmButton: t("Continue"), content: renderCancel(), cancelButton: t("Return"), onCancel: cancelLogic.closeDialog, onConfirm: cancelLogic.actionDialog }));
};
