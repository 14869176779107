import { useEffect, useState, useRef, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { setDefaultRight, setGlobalPermissions, usePermissionsSelector } from "../../redux/reducers/Permissions/PermissionsReducer";
import { useAppConfigurationSelector } from "../../redux/reducers/AppConfigurationReducer/AppConfigurationReducer";
import { useDispatch } from "react-redux";
import { translations } from "../../translations";
import { GlobalPermsString, PermsTable, Role } from "../../interfaces/Permissions";
import { CompareModule } from "../../modules/Compare.module";
import { createUserPerm, getDefaultPermissionsByProjectId, getUsersPermissionsByProjectId, patchDefaultPerm, patchUserPerm, postGlobalPerms } from "../../apis/Permissions/PermissionsApi";
import { getAdminCenterData } from "../../apis/AdminCenter/adminCenterApi";
import { useConfigurationSelector } from "../../redux/reducers/ConfigurationReducer/ConfigurationReducer";
import { useMsTeamsSelector } from "../../redux/reducers/MicrosoftTeamsReducer/MicrosoftTeamsReducer";
import { openModal, useModalSelector } from "../../redux/reducers/ModalReducer/ModalReducer";
import { Modal } from "../../interfaces/Modal";
import ReactHtmlParser from "react-html-parser";
import * as microsoftTeams from "@microsoft/teams-js";
import { usePremiumSelector } from "../../redux/reducers/PremiumReducer/PremiumReducer";
import { useTranslate } from "../../hook/useTranslate";
import { buildDefaultRight, buildRight } from "./Permissions.utils";
import { configPerms } from "../../const/configPerms";
import { ObjectModule } from "../../modules/Object.module";
export const useLogic = () => {
    const t = useTranslate(translations);
    const { appPathName, appId, appOwner, appName, app, from } = useAppConfigurationSelector("appPathName", "appId", "appOwner", "appName", "app", "from");
    const { defaultRight, globalPermissions } = usePermissionsSelector("defaultRight", "globalPermissions");
    const { isOpen } = useModalSelector("isOpen");
    const { data } = useConfigurationSelector("data");
    const { tenantId, userId } = useMsTeamsSelector("tenantId", "userId");
    const { userPerm } = usePermissionsSelector("userPerm");
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const updatedUserPermissions = useRef([]);
    const [currentPage, setCurrentPage] = useState(1);
    const initialUsersPermissions = useRef(undefined);
    const [totalPage, setTotalPage] = useState();
    const [displayedUsers, setDisplayedUsers] = useState();
    const [searchValue, setSearchValue] = useState("");
    const [loading, setLoading] = useState(true);
    const [dropdownPlaceholderText, setDropdownPlaceholderText] = useState("");
    const [dropdownKey, setDropdownKey] = useState(0);
    const [searchTimer, setSearchTimer] = useState(null);
    const { isPremium, isPlatinum } = usePremiumSelector("isPremium", "isPlatinum");
    const [state, setState] = useState({
        appOwner: appOwner,
        defaultRight: buildDefaultRight(from, { ...defaultRight }),
        globalPermissions: { isEveryone: globalPermissions.isEveryone, isCreator: globalPermissions.isCreator, isCustom: globalPermissions.isCustom },
        updatedUserPermissions: updatedUserPermissions.current,
    });
    const [bannerState, setBannerState] = useState({
        isSuccessBannerVisible: false,
    });
    const originalState = useRef({ ...state });
    const globalPermissionsItems = [
        {
            key: GlobalPermsString.isEveryone,
            header: t("Permissions:EveryoneDropdown", { taskName: t(from + ":taskName") }),
            defaultRight: buildRight(from, true),
        },
        {
            key: GlobalPermsString.isCreator,
            header: t("Permissions:CreatorDropdown", { appTitle: appName, taskName: t(from + ":taskName") }),
            defaultRight: buildRight(from, false),
        },
        {
            key: GlobalPermsString.isCustom,
            header: t("Permissions:CustomDropdown"),
            defaultRight: buildRight(from, true),
        },
    ];
    const roleItems = [
        {
            key: Role.creator,
            header: t("Permissions:Owner"),
            role: Role.creator,
        },
        {
            key: Role.member,
            header: t("Permissions:Member"),
            role: Role.member,
        },
    ];
    const openPremium = useCallback(() => {
        dispatch(openModal(Modal.PREMIUM));
    }, []);
    const getUsersDataByAdminCenter = async (pageUsersPermissions) => {
        if (!pageUsersPermissions)
            return;
        const updatedUsers = [];
        for (const user of pageUsersPermissions.users) {
            const updatedUser = { ...user };
            if (user.id != userId && !process.env.REACT_APP_ISPLATINUM) {
                const acdata = await getAdminCenterData(tenantId, user.id, data.adminCenterAppId, data.adminCenterBaseUrl);
                updatedUser.isPlatinum = acdata.isUserPlatinum;
                updatedUser.isPremium = acdata.isUserPremium;
            }
            else {
                updatedUser.isPlatinum = isPlatinum;
                updatedUser.isPremium = isPremium;
            }
            updatedUsers.push(updatedUser);
        }
        setInitialUsersPermissions(updatedUsers);
        setDisplayedUsers((prev) => ({ ...prev, total: pageUsersPermissions.total, users: updatedUsers }));
    };
    useEffect(() => {
        const fetchUserAsync = async () => {
            const pageUsersPermissions = await getUsersPermissionsByProjectId(appId, currentPage - 1, searchValue, 20);
            setTotalPage(Math.ceil(pageUsersPermissions.total / 20));
            getUsersDataByAdminCenter(pageUsersPermissions);
        };
        fetchUserAsync();
    }, [appId, currentPage, searchValue, isPlatinum, isPremium, userId]);
    useEffect(() => {
        getDropdownPlaceholderText();
    }, [state.globalPermissions]);
    useEffect(() => {
        if (initialUsersPermissions.size === 0 && displayedUsers) {
            for (const user of displayedUsers.users) {
                initialUsersPermissions.set(user.id, user);
            }
        }
    }, [displayedUsers]);
    useEffect(() => {
        const fetchDefaultPermAsync = async () => {
            const defaultPerm = await getDefaultPermissionsByProjectId(appId);
            if (defaultPerm) {
                originalState.current = {
                    ...state,
                    defaultRight: defaultPerm,
                };
                setState((prevState) => ({
                    ...prevState,
                    defaultRight: defaultPerm,
                }));
            }
        };
        fetchDefaultPermAsync();
    }, []);
    const getDropdownPlaceholderText = () => {
        globalPermissionsItems.forEach((g) => {
            if (state.globalPermissions[g.key]) {
                setDropdownPlaceholderText(g.header);
                setDropdownKey((prevKey) => prevKey + 1);
            }
        });
    };
    const setInitialUsersPermissions = (users) => {
        const deepUsersClone = users ? JSON.parse(JSON.stringify(users)) : JSON.parse(JSON.stringify(displayedUsers.users));
        initialUsersPermissions.current = deepUsersClone;
        setLoading(false);
    };
    const addMembers = (users) => {
        setDisplayedUsers((prev) => {
            const uniqueUsers = users.filter((newUser) => !prev.users.some((existingUser) => newUser.id === existingUser.id));
            return {
                ...prev,
                total: prev.total + uniqueUsers.length,
                users: [...prev.users, ...uniqueUsers],
            };
        });
        const uniqueInitialUsers = users.filter((newUser) => !initialUsersPermissions.current.some((existingUser) => newUser.id === existingUser.id));
        initialUsersPermissions.current = [...initialUsersPermissions.current, ...uniqueInitialUsers];
    };
    const returnToApp = () => {
        if (!appPathName)
            return;
        navigate(`/${appPathName}/` + appId);
    };
    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };
    const searchOnChange = (data) => {
        if (searchTimer) {
            clearTimeout(searchTimer);
        }
        const timer = setTimeout(() => {
            setSearchValue(data?.value ?? "");
        }, 500);
        setSearchTimer(timer);
    };
    const handleSave = async () => {
        const deepUsersClone = JSON.parse(JSON.stringify(displayedUsers.users));
        initialUsersPermissions.current = deepUsersClone;
        setBannerState((prevState) => ({
            ...prevState,
            isSuccessBannerVisible: true,
        }));
        setTimeout(() => {
            setBannerState((prevState) => ({
                ...prevState,
                isSuccessBannerVisible: false,
            }));
        }, 5000);
        const updatedDefaultRight = buildDefaultRight(from, { ...state.defaultRight });
        const updatedGlobalPermissions = {};
        globalPermissionsItems.forEach((g) => {
            updatedGlobalPermissions[g.key] = state.globalPermissions[g.key];
        });
        originalState.current = {
            ...state,
            defaultRight: updatedDefaultRight,
            globalPermissions: updatedGlobalPermissions,
            updatedUserPermissions: [],
        };
        setState((prevState) => ({
            ...prevState,
            defaultRight: updatedDefaultRight,
            globalPermissions: updatedGlobalPermissions,
            updatedUserPermissions: [],
        }));
        if (updatedDefaultRight !== originalState.defaultRight) {
            await patchDefaultPerm(appId, updatedDefaultRight);
            dispatch(setDefaultRight(updatedDefaultRight));
        }
        if (updatedGlobalPermissions !== originalState.globalPermissions) {
            await postGlobalPerms(appId, updatedGlobalPermissions);
            dispatch(setGlobalPermissions(updatedGlobalPermissions));
        }
        updatedUserPermissions.current.forEach((user) => {
            if (configPerms[from].some((c) => c == PermsTable.AllowShareSynchro)) {
                if (!user.perm.allowShareSynchro && user.synchros) {
                    user.synchros = user.synchros.map((s) => {
                        s.sharing = false;
                        return s;
                    });
                }
            }
            patchUserPerm(appId, user);
        });
    };
    const handleCancelConfirmed = async () => {
        setState(originalState.current);
        const resetUsersPermissionsDeepClone = JSON.parse(JSON.stringify(initialUsersPermissions.current));
        setDisplayedUsers((prev) => ({ ...prev, users: resetUsersPermissionsDeepClone }));
        setCurrentPage(1);
        setSearchValue("");
    };
    const handleDefaultCheckboxChange = (props) => {
        setState((prevState) => ({
            ...prevState,
            defaultRight: {
                ...prevState.defaultRight,
                [props]: !prevState.defaultRight[props],
            },
        }));
        setDisplayedUsers((prev) => {
            prev.users.forEach((u) => {
                if (u.role === Role.creator)
                    return;
                u.perm[props] = !state.defaultRight[props];
            });
            return { ...prev };
        });
    };
    const handlePermissionChange = (selectedPermission) => {
        const handlingGlobalPermissions = {};
        let newDefaultRight;
        globalPermissionsItems.forEach((g) => {
            handlingGlobalPermissions[g.key] = selectedPermission == GlobalPermsString[g.key];
            if (selectedPermission === GlobalPermsString[g.key] && g.defaultRight) {
                newDefaultRight = g.defaultRight;
                return;
            }
            else if (selectedPermission === GlobalPermsString[g.key] && !g.defaultRight)
                newDefaultRight = buildDefaultRight(from, state.defaultRight);
        });
        setState((prevState) => ({
            ...prevState,
            globalPermissions: handlingGlobalPermissions,
            defaultRight: newDefaultRight,
        }));
        setDisplayedUsers((prev) => {
            prev.users.forEach((u) => {
                if (u.role === Role.creator)
                    return;
                if (!u.perm)
                    u.perm = {};
                configPerms[from].forEach((c) => {
                    u.perm[c] = newDefaultRight[c];
                });
            });
            return { ...prev };
        });
    };
    const handleRoleChange = (index, selectedRole) => {
        const updatedUsers = { ...displayedUsers };
        const existingUserIndex = updatedUserPermissions.current.findIndex((user) => user.id === updatedUsers.users[index].id);
        updatedUsers.users[index].role = selectedRole.key;
        Object.keys(updatedUsers.users[index].perm).forEach((key) => {
            updatedUsers.users[index].perm[key] = true;
        });
        setDisplayedUsers(updatedUsers);
        if (existingUserIndex !== -1)
            updatedUserPermissions.current[existingUserIndex] = updatedUsers.users[index];
        else
            updatedUserPermissions.current.push(updatedUsers.users[index]);
    };
    const compareState = useMemo(() => {
        if (!displayedUsers || loading)
            return true;
        return CompareModule.areObjectsEqual(state, originalState.current) && CompareModule.areObjectsEqual(displayedUsers.users, initialUsersPermissions.current);
    }, [state, originalState, displayedUsers, initialUsersPermissions, loading]);
    const isCurrentUserNotCreator = () => {
        return userPerm.role !== Role.creator;
    };
    const changeUserPerm = useCallback((index, perm) => {
        const updatedUsers = { ...displayedUsers };
        const existingUserIndex = updatedUserPermissions.current.findIndex((user) => user.id === updatedUsers.users[index].id);
        updatedUsers.users[index].perm[perm] = !updatedUsers.users[index].perm[perm];
        setDisplayedUsers(updatedUsers);
        if (existingUserIndex !== -1)
            updatedUserPermissions.current[existingUserIndex] = updatedUsers.users[index];
        else
            updatedUserPermissions.current.push(updatedUsers.users[index]);
    }, [displayedUsers]);
    const handleDisplayRole = (role) => {
        if (role === Role.creator)
            return t("Permissions:Owner");
        else if (role === Role.member)
            return t("Permissions:Member");
    };
    const showTooltip = (user) => {
        if (!user.isPlatinum)
            return t("Permissions:MemberIsNotPlatinum");
    };
    const showLicenseTooltip = (user) => {
        if (!user.isPlatinum)
            return t("Permissions:AssignLicense");
    };
    const showRoleTooltip = (role) => {
        if (role === Role.creator)
            return t("Permissions:CreatorRole");
    };
    const handleDisplayLicense = (user) => {
        if (user.isPlatinum)
            return t("Permissions:Platinum");
        else if (user.isPremium)
            return ReactHtmlParser(t("Permissions:Premium"));
        else
            return ReactHtmlParser(t("Permissions:Free"));
    };
    const toggleAddMemberDialog = async () => {
        try {
            const selectedOwner = await microsoftTeams.people.selectPeople({
                singleSelect: false,
                title: "",
                openOrgWideSearchInChatOrChannel: true,
            });
            const users = [];
            for (const user of selectedOwner) {
                const acdata = await getAdminCenterData(tenantId, user.objectId, data.adminCenterAppId, data.adminCenterBaseUrl);
                const userPerm = {
                    id: user.objectId,
                    name: user.displayName,
                    upn: user.email,
                    role: Role.member,
                    perm: ObjectModule.deepClone(state.defaultRight),
                    isPlatinum: (process.env.REACT_APP_ISPLATINUM ?? acdata.isUserPlatinum),
                    isPremium: (process.env.REACT_APP_ISPREMIUM ?? acdata.isUserPremium),
                };
                try {
                    await createUserPerm(appId, userPerm);
                    users.push(userPerm);
                }
                catch (e) {
                    console.error("error");
                    //erreur serveur on ajoute pas dans la table
                }
            }
            addMembers(users);
        }
        catch (e) {
            console.error("no member added");
        }
    };
    const toggleCancelDialog = () => {
        if (!isOpen)
            dispatch(openModal(Modal.PERMISSIONS_CANCEL_UNSAVE_CHANGE));
    };
    const toggleManageRoleDialog = () => {
        if (!isOpen)
            dispatch(openModal(Modal.PERMISSIONS_ROLE_CHANGE));
    };
    const disableCheckbox = useMemo(() => {
        if (!isPlatinum)
            return true;
        if (!state.globalPermissions.isCustom)
            return true;
        if (isCurrentUserNotCreator())
            return true;
        return false;
    }, [isPlatinum, !state.globalPermissions.isCustom, isCurrentUserNotCreator]);
    const calculateMinWitdthTable = useMemo(() => {
        return 250 + configPerms[from].length * 100 + "px";
    }, [configPerms, from]);
    const openAccordionDefault = useMemo(() => {
        const defaultIndex = [0];
        if (state.globalPermissions.isCustom)
            defaultIndex.push(1);
        return defaultIndex;
    }, [state.globalPermissions]);
    return {
        state,
        originalState,
        bannerState,
        globalPermissionsItems,
        compareState,
        currentPage,
        totalPage,
        isOpen,
        displayedUsers,
        loading,
        dropdownPlaceholderText,
        dropdownKey,
        disableCheckbox,
        roleItems,
        toggleAddMemberDialog,
        toggleCancelDialog,
        isCurrentUserNotCreator,
        returnToApp,
        searchOnChange,
        getDropdownPlaceholderText,
        handleSave,
        handleCancelConfirmed,
        handlePermissionChange,
        handlePageChange,
        addMembers,
        handleDisplayRole,
        handleDisplayLicense,
        showTooltip,
        showLicenseTooltip,
        showRoleTooltip,
        changeUserPerm,
        handleDefaultCheckboxChange,
        handleRoleChange,
        toggleManageRoleDialog,
        isPremium,
        isPlatinum,
        userPerm,
        appName,
        app,
        from,
        calculateMinWitdthTable,
        openPremium,
        appId,
        openAccordionDefault
    };
};
