import { Button, ChevronStartIcon, Flex, Text, UserFriendsIcon } from "@fluentui/react-northstar";
import React, { useMemo } from "react";
import { useGroupAdministrationTop } from "./GroupAdministrationTop.logic";
import "./GroupAdministrationTop.scss";
import { GROUP_ADMINISTRATION_TOP_BACK_BUTTON } from "../../test/testId";

const GroupAdministrationTop = () => {
  const logic = useGroupAdministrationTop();

  const leftContent = useMemo(
    () => (
      <Flex hAlign="center" vAlign="center" gap="gap.small">
        <Button icon={<ChevronStartIcon />} iconOnly onClick={logic.handleGoBack} data-testid={GROUP_ADMINISTRATION_TOP_BACK_BUTTON} />
        <Text content={logic.t("GroupsAdmin")} weight="bold" />
      </Flex>
    ),
    []
  );

  const rightContent = useMemo(
    () => (
      <Flex hAlign="center" vAlign="center" gap="gap.small" className="groupAdministrationTop-right-container">
        {!logic.isOnMobile && <Button content={logic.t("Close")} onClick={logic.handleGoBack} />}
        <Button icon={<UserFriendsIcon outline />} content={logic.t("CreateGroup")} primary onClick={logic.handleCreateGroup} disabled={!logic.userPerm.perm.add} />
      </Flex>
    ),
    [logic.isOnMobile, logic.userPerm.perm.add]
  );

  return (
    <Flex hAlign="center" vAlign="center" space="between" gap="gap.small" className="groupAdministrationTop-container">
      {leftContent}
      {rightContent}
    </Flex>
  );
};

export default GroupAdministrationTop;
