export enum ISchemaError {
  // String too long
  NO_CHANNEL_ID = "noChannelId",
  TENANT_ID_TOO_LONG = "tenantIdTooLong",
  CONTACT_NAME_TOO_LONG = "contactNameTooLong",
  JOB_TITLE_TOO_LONG = "jobTitmeTooLong",
  COMPANY_TOO_LONG = "companyTooLong",
  DEPARTMENT_TOO_LONG = "departmentTooLong",
  EMAIL_TOO_LONG = "emailTooLong",
  WEBSITE_TOO_LONG = "websiteTooLong",
  FACEBOOK_TOO_LONG = "facebookTooLong",
  X_TOO_LONG = "xTooLong",
  LINKEDIN_TOO_LONG = "linkedinTooLong",
  BUSINESS_PHONE_TOO_LONG = "businessPhoneTooLong",
  MOBILE_PHONE_TOO_LONG = "mobilePhoneTooLong",
  HOME_PHONE_TOO_LONG = "homePhoneTooLong",
  BUSINESS_FAX_TOO_LONG = "businessFaxTooLong",
  FULL_ADDRESS_TOO_LONG = "fullAddressTooLong",
  NOTES_TOO_LONG = "notesTooLong",
  NO_GROUP_NAME = "noGroupName",
  GROUP_NAME_TOO_LONG = "groupNameTooLong",

  // Empty string
  NO_TENANT_ID = "noTenantId",
  NO_CONTACT_NAME = "noContactName",

  // Invalid type
  INVALID_GUID = "invalidGuid",
  ID_INVALID_TYPE = "idInvalidType",
  GROUP_NAME_INVALID_TYPE = "groupNameInvalidType",
  IS_DELETED_INVALID_TYPE = "isDeletedInvalidType",
  CHANNEL_ID_INVALID_TYPE = "channelIdInvalidType",
  TENANT_ID_INVALID_TYPE = "tenantIdInvalidType",
  CREATED_BY_INVALID_TYPE = "createdByInvalidType",
  CREATED_AT_INVALID_TYPE = "createdAtInvalidType",
  UPDATED_BY_INVALID_TYPE = "updatedByInvalidType",
  UPDATED_AT_INVALID_TYPE = "updatedAtInvalidType",
  IS_PREMIUM_INVALID_TYPE = "isPremiumInvalidType",
  BUSINESS_PHONE_INVALID_TYPE = "businessPhoneInvalidType",
  MOBILE_INVALID_TYPE = "mobileInvalidType",
  HOME_INVALID_TYPE = "homeInvalidType",
  BUSINESS_FAX_INVALID_TYPE = "businessFaxInvalidType",
  EMAIL_INVALID_TYPE = "emailInvalid",
  WEBSITE_INVALID_TYPE = "websiteInvalid",
  FACEBOOK_INVALID_TYPE = "facebookInvalid",
  X_INVALID_TYPE = "xInvalid",
  LINKEDIN_INVALID_TYPE = "linkedinInvalid",
  CONTACT_NAME_INVALID_TYPE = "contactNameInvalidType",
  GROUP_ID_INVALID_TYPE = "groupIdInvalidType",
  PICTURE_INVALID_TYPE = "pictureInvalidType",
  JOB_TITLE_INVALID_TYPE = "jobTitleInvalidType",
  COMPANY_INVALID_TYPE = "companyInvalidType",
  DEPARTMENT_INVALID_TYPE = "departmentInvalidType",
  NOTES_INVALID_TYPE = "notesInvalidType",
  IS_DISABLED_INVALID_TYPE = "isDisabledInvalidType",
  FULL_ADDRESS_INVALID_TYPE = "fullAddressInvalidType",
  LAT_INVALID_TYPE = "latInvalidType",
  LNG_INVALID_TYPE = "lngInvalidType",

  // Invalid formats
  EMAIL_INVALID_FORMAT = "emailInvalidFormat",
  WEBSITE_INVALID_FORMAT = "websiteInvalidFormat",
  FACEBOOK_INVALID_FORMAT = "facebookInvalidFormat",
  X_INVALID_FORMAT = "xInvalidFormat",
  LINKEDIN_INVALID_FORMAT = "linkedinInvalidFormat",
  BUSINESS_PHONE_INVALID_FORMAT = "businessPhoneInvalidFormat",
  MOBILE_INVALID_FORMAT = "mobileInvalidFormat",
  HOME_INVALID_FORMAT = "homeInvalidFormat",
  BUSINESS_FAX_INVALID_FORMAT = "businessFaxInvalidFormat",
}
