import { setModalWithData, useAppConfigurationSelector, useMsTeamsSelector, usePermissionsSelector, useTranslate } from "front";
import { translations } from "../../translations";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { IAppDispatch } from "../../redux/store";
import { IDialogs } from "../../interfaces/IDialog/IDialogs";

export const useGroupAdministrationTop = () => {
  const t = useTranslate(translations);
  const dispatchCtx = useDispatch<IAppDispatch>();
  const navigate = useNavigate();
  const { appId } = useAppConfigurationSelector("appId");
  const { isOnMobile } = useMsTeamsSelector("isOnMobile");
  const { userPerm } = usePermissionsSelector("userPerm");

  const handleGoBack = useCallback(() => navigate(`/contact/${appId}`), []);
  const handleCreateGroup = useCallback(() => dispatchCtx(setModalWithData({ isOpen: IDialogs.GROUP, data: { isEditing: false, group: undefined } })), []);

  return { t, handleGoBack, handleCreateGroup, isOnMobile, userPerm };
};
