export enum IDisplayMode {
  CARD = "Card",
  LIST = "List",
  GROUP = "Group",
}

export enum IDisplayModeDTO {
  CARD,
  LIST,
  GROUP,
}
