import { queryClient } from "../../index";
import { IQueryCacheData } from "../../interfaces/ICache/IQueryCache";
import { useCallback } from "react";
import { useMutation } from "react-query";

export const useQueryCache = () => {
  const updateDataCache = useCallback(async (data: unknown, queryKey: unknown[]) => {
    queryClient.setQueryData(queryKey, data);
  }, []);

  const mutation = useMutation(async (data: IQueryCacheData) => updateDataCache(data.data, data.queryKey), {
    onError: () => onError(),
  });

  const onError = useCallback(() => {}, []);

  return { mutation, mutate: mutation.mutate };
};
